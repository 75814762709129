import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getBasicInfo = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const getDetailInfo = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const getColumnName = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;

  return axios({
    method: 'get',
    url,
  });
};

export default {
  getBasicInfo,
  getDetailInfo,
  getColumnName,
};
