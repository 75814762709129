import React, { useRef } from 'react';
import {
  Col,
  Form,
  FormItemProps,
  Row,
  Typography,
  AutoComplete,
  AutoCompleteProps,
} from 'antd';
import { useController, UseControllerProps } from 'react-hook-form';

type Props<T> = {
  autoCompleteProps?: AutoCompleteProps;
  controller: UseControllerProps<T>;
  errorCol?: 12 | 24;
  onBlur?: () => void;
  newRegex?: RegExp;
} & Omit<FormItemProps, 'children'>;

function AutoSuggestField<T extends Record<string, any>>(props: Props<T>) {
  const {
    autoCompleteProps,
    controller,
    errorCol = 24,
    label,
    onBlur,
    newRegex,
    ...rest
  } = props;
  const { fieldState, field } = useController<T>(controller);
  const { error } = fieldState;
  const inputRef = useRef<any>(null);
  const onChangeInput = (value) => {
    // handle max text length input suggest
    if (
      autoCompleteProps?.maxTagTextLength &&
      value &&
      value.length > autoCompleteProps?.maxTagTextLength
    ) {
      return;
    }
    if (newRegex) {
      if ((newRegex && newRegex.test(value)) || value === '') {
        field.onChange(value);
      } else {
        !field.value && field.onChange('');
      }
    } else {
      field.onChange(value);
    }
  };

  const handleClearInput = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <Form.Item
      colon={false}
      label={
        <Typography.Text
          style={{
            width: '100%',
          }}
        >
          {label}
        </Typography.Text>
      }
      labelAlign="left"
      labelCol={label ? { span: 24 } : undefined}
      wrapperCol={label ? { span: 24 } : undefined}
      validateStatus={error ? 'error' : ''}
      {...rest}
    >
      <Row gutter={4}>
        <Col span={errorCol}>
          <Row gutter={8}>
            <Col span={24}>
              <AutoComplete
                {...autoCompleteProps}
                {...field}
                ref={inputRef}
                onChange={onChangeInput}
                onBlur={() => {
                  onBlur && onBlur();
                }}
                onClear={handleClearInput}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={errorCol}>
          {error && (
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error"
              type="danger"
              ellipsis={{ tooltip: true }}
              style={{ lineHeight: errorCol === 24 ? '16px' : '42px' }}
            >
              {error.message}
            </Typography.Text>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
}

export default AutoSuggestField;
