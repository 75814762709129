import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import category from 'src/repositories/category';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { FieldPathValue } from 'react-hook-form';
import product from 'src/repositories/product';
import _ from 'lodash';
import { productFormat } from 'src/utils/product';

const apiKeyCategory = '/common/category/list';
const apiKeyProduct = '/product/list/by-category';

const useProductSearch = (
  watchCategory?: FieldPathValue<any, any>,
  watchShape?: FieldPathValue<any, any>,
) => {
  const params = useQueryUrl<SearchParams.Category>();

  const { data, ...rest } = useQuery<
    AxiosResponse<Response.Category>,
    AxiosError<any>
  >([apiKeyCategory, params], category.getProductCategory, {
    retry: 0,
    onError(err) {
      console.log(err.response);
    },
  });

  const categories = data?.data?.items;
  const fetchCategories = categories?.map((item) => {
    return {
      label: item.categoryName,
      value: item.categoryId,
    };
  });

  const dataCategories = _.orderBy(fetchCategories, ['value'], ['asc']);

  const subCategoryById = (id) => {
    return categories?.find((item) => {
      return item.categoryId === id;
    });
  };

  const subCategories = subCategoryById(watchCategory)?.childrenList;

  const dataSubCategories = subCategories?.map((item) => ({
    label: item.categoryName,
    value: item.categoryId,
  }));

  const queryProductList = useQuery<
    AxiosResponse<Response.ProductList>,
    AxiosError<any>
  >({
    queryKey: [
      apiKeyProduct,
      {
        hinshL3Cd: watchCategory,
        keijoL3Cd: watchShape,
      },
    ],
    queryFn: product.getProduct,
  });

  const dataProduct = queryProductList?.data?.data?.items.map((item) => ({
    ...item,
    productId: productFormat.mergeProductId(
      item.hakudoShhnCd,
      item.shhnCd,
      item.api1ShhnCd,
      item.api2ShhnCd,
    ),
  }));

  return {
    dataCategories,
    dataSubCategories,
    dataProduct,
    ...rest,
  };
};

export default useProductSearch;
