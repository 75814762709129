import React from 'react';
import { Row, Col, Layout, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Footer } from 'antd/lib/layout/layout';
import { ElfsightWidget } from 'react-elfsight-widget';

import Contact from 'src/assets/images/Contact.jpg';
import Email from 'src/assets/images/Email.jpg';
import usePdfMenu from 'src/queries/usePdfMenu';
import { PATH as PATH_PRICING } from 'src/pages/pricing';
import { PATH as PATH_QUOTATION } from 'src/pages/quotation';
import { PATH as PATH_ACCOUNT } from 'src/pages/account';
import { CONTACT_INFO } from 'src/constants/app';
import PluginQrCode from './pluginQrCode';
import { PATH as FAQ } from 'src/pages/faq';
import usePlugin from 'src/queries/usePlugin';
import useSiteInfo from 'src/queries/useSiteInfo';

type Props = {
  hasContact?: boolean;
  hasMenu?: boolean;
};

const AppFooter: React.FC<Props> = ({ hasMenu = true, hasContact = true }) => {
  const { t } = useTranslation();
  const { queryPlugin } = usePlugin();
  const { pdfFiles } = usePdfMenu(hasMenu);
  const { queryCompanyInfo } = useSiteInfo(hasContact);
  const companyInfo = queryCompanyInfo?.data?.data?.item;

  const columnEnableFaq = queryPlugin?.data?.data?.item?.faq ? 5 : 6;

  const pdf02 = pdfFiles?.find((item) => item.kbnmCd === '02')?.fileDir;

  return (
    <Footer className="footer">
      {hasContact && (
        <Layout className="layout-container">
          <div className="container-lg">
            <div className="contact-title">
              <p className="contact-title-text">{t('footer.contact.title')}</p>
            </div>
            <Row className="footer-contact" align="middle" justify="center">
              <Col xs={24} sm={24} md={12}>
                <Link to={'/#'}>
                  <div
                    className="footer-contact-item footer-contact-item-phone"
                    style={{ backgroundImage: `url(${Contact})` }}
                  >
                    <Row className="footer-contact-item-phone-content">
                      <span className="text-phone">{companyInfo?.telno}</span>
                      <span className="text-date">
                        {t('footer.contact.date')}
                        {companyInfo?.eigyouJikanFrom &&
                          companyInfo?.eigyouJikanTo && (
                            <span className="text-time">
                              {`${companyInfo?.eigyouJikanFrom} - ${companyInfo?.eigyouJikanTo}`}
                            </span>
                          )}
                      </span>
                      <span className="text-des">
                        {t('footer.contact.des')}
                      </span>
                    </Row>
                  </div>
                </Link>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <a
                  href={`mailto:${CONTACT_INFO.MAIL}`}
                  className="footer-contact-item footer-contact-item-email"
                  style={{ backgroundImage: `url(${Email})` }}
                >
                  <p className="text-mail">
                    {t('footer.contact.mail')}
                    <Typography.Text className="text-link">
                      {t('footer.contact.link')}
                    </Typography.Text>
                  </p>
                </a>
              </Col>
            </Row>
          </div>
        </Layout>
      )}
      {hasMenu && (
        <Layout className="footer-top">
          <Row
            className="container-lg footer-menu"
            align="middle"
            justify="center"
          >
            <Col
              className="footer-menu-item"
              xs={24}
              sm={12}
              md={columnEnableFaq}
            >
              <Link to={PATH_PRICING}>
                {t('footer.footer_top.footer_menu_1')}
              </Link>
            </Col>
            <Col
              className="footer-menu-item"
              xs={24}
              sm={12}
              md={columnEnableFaq}
            >
              <Link to={PATH_QUOTATION}>
                {t('footer.footer_top.footer_menu_2')}
              </Link>
            </Col>
            <Col
              className="footer-menu-item"
              xs={24}
              sm={12}
              md={columnEnableFaq}
            >
              <a target={'_blank'} rel="noreferrer" href={pdf02 || '/#'}>
                {t('footer.footer_top.footer_menu_3')}
              </a>
            </Col>
            <Col
              className="footer-menu-item"
              xs={24}
              sm={12}
              md={columnEnableFaq}
            >
              <Link to={PATH_ACCOUNT}>
                {t('footer.footer_top.footer_menu_4')}
              </Link>
            </Col>
            {queryPlugin?.data?.data?.item?.faq && (
              <Col className="footer-menu-item" xs={24} sm={12} md={4}>
                <Link to={FAQ}>{t('footer.footer_top.faq')}</Link>
              </Col>
            )}
          </Row>
        </Layout>
      )}
      <PluginQrCode />

      {queryPlugin?.data?.data?.item?.chatBot && (
        <ElfsightWidget widgetID={queryPlugin?.data?.data?.item?.chatBot} />
      )}
      <p className="footer-bottom">{t('footer.footer_bottom')}</p>
    </Footer>
  );
};

export default AppFooter;
