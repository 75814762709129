import { Breadcrumb, Divider, Row } from 'antd';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import _ from 'lodash';

import IconHome from 'src/components/icon/IconHome';
import routeConfig from 'src/routes';
import { IRouter } from 'src/interfaces/route';
import LocalStorage from 'src/utils/LocalStorage';
import { I18n } from 'src/libs/i18n';

const AppBreadcrumb: React.FC = () => {
  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  var siteInfo: any = LocalStorage.initSiteInfo;

  const routeMap = {};
  const getRouteMap = (routeTrees: IRouter[]) => {
    _.forEach(routeTrees, function (item: IRouter) {
      if (item.title) {
        let path = item.path!;
        const origin = location.pathname.split('/').slice(0, -1).join('/');
        path = path.replace(/:origin/g, origin);
        routeMap[path] = item.title;
      }
      if (item.children) {
        getRouteMap(item.children);
      }
    });
  };
  getRouteMap(routeConfig);

  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    return (
      <Breadcrumb.Item key={url}>
        <Divider className="mg-vertical-0 mg-horizontal-13" type="vertical" />
        <Link to={url} className="ml-0 p-0">
          {I18n.t(routeMap[url])}
        </Link>
      </Breadcrumb.Item>
    );
  });

  const breadcrumbItems = [
    <Breadcrumb.Item key="home">
      <Link to="/" className="ml-0 p-0">
        <IconHome />
      </Link>
      <Divider className="mg-vertical-0 mg-horizontal-13" type="vertical" />
      <Link to="/" className="ml-0 p-0 ">
        {siteInfo?.rmSiteKihon?.netSbsName}
      </Link>
    </Breadcrumb.Item>,
  ].concat(extraBreadcrumbItems);

  return (
    <Row className="mt-4 mb-10" align="middle">
      <Breadcrumb>{breadcrumbItems}</Breadcrumb>
    </Row>
  );
};

export default AppBreadcrumb;
