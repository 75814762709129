import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, TableProps } from 'antd';
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';

import useProductDetails from 'src/queries/useProductDetails';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { getColumnNameMulti } from 'src/utils/helper';
import LocalStorage from 'src/utils/LocalStorage';
import { PATH as PATH_PRICING } from 'src/pages/pricing';
import { PATH as PATH_PRICING_TRIAL } from 'src/pages/pricingTrial';

type Props = {
  isTrialMode?: boolean;
};
const useTable = ({ isTrialMode }: Props) => {
  const { t } = useTranslation();
  const query = useQueryUrl();

  const { getDetailInfo, getColumnName } = useProductDetails();

  const detailInfoData = getDetailInfo?.data?.data?.items;
  const columnNameData = getColumnName?.data?.data?.items;

  const [renderItems, setRenderItem] = useState<any>([]);
  const [emptyItems, setEmptyItems] = useState<any>([]);
  const [listItems, setListItems] = useState<any>([]);

  useEffect(() => {
    if (detailInfoData) {
      const isValueNullOrEmpty = (data, key) =>
        data.every((obj) => {
          const value = obj[key];
          return (
            value === null || (typeof value === 'string' && value.trim() === '')
          );
        });

      const keys = Object.keys(detailInfoData[0]);
      const nullKeys = keys.filter((key) =>
        isValueNullOrEmpty(detailInfoData, key),
      );

      setEmptyItems(nullKeys);

      const list = detailInfoData.map((obj) => {
        let newObj = { ...obj };
        nullKeys.forEach((key) => delete newObj[key]);
        return newObj;
      });

      setListItems(list);
    } else {
      let emptyColumns: string[] = [];
      for (let i = 1; i <= 50; i++) {
        const item = 'c' + i;
        emptyColumns.push(item);
      }
      setEmptyItems(emptyColumns);
    }
  }, [detailInfoData?.length]);

  useEffect(() => {
    if (columnNameData) {
      const list = columnNameData
        ?.filter((item) => {
          return !emptyItems?.includes(item?.colKey);
        })
        ?.map((item) => ({
          title: getColumnNameMulti(item, t('product_page.item'), item?.colKey),
          dataIndex: item?.colKey,
          key: item?.colKey,
          width: 100,
          render: (record) => <Row>{record}</Row>,
        }));
      setRenderItem(list);
    }
  }, [columnNameData?.length, LocalStorage.lang, emptyItems]);

  const columns = [
    {
      title: `${t('product_page.size')}1`,
      width: 150,
      render: (text) => (
        <Row>
          {text?.existSnpo1 ? (
            <Link
              to={isTrialMode ? PATH_PRICING_TRIAL : PATH_PRICING}
              state={{
                productDetails: {
                  shhnCd: query?.shhnCd,
                  hakudoShhnCd: query?.hakudoShhnCd,
                  api1ShhnCd: query?.api1ShhnCd,
                  api2ShhnCd: query?.api2ShhnCd,
                  snpo1: text?.snpo1,
                },
              }}
              className="snpo1_link"
            >
              {text?.snpo1}
            </Link>
          ) : (
            text?.snpo1
          )}
        </Row>
      ),
    },
    {
      title:
        query?.hinshL3Cd !== '07'
          ? t('product_page.dimensions')
          : t('product_page.product_info_2'),
      width: 150,
      render: (text) => (
        <Row>{query?.hinshL3Cd !== '07' ? text?.bozSnpo : text?.seihnJoho}</Row>
      ),
    },
    ...renderItems,
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    dataSource: listItems,
    loading: {
      spinning: getDetailInfo.isFetching,
      indicator: <LoadingOutlined />,
    },
    rowKey: 'snpo1',
    className: 'custom_table',
    scroll: { x: 'max-content' },
    size: 'middle',
    locale: {
      emptyText: t('product_page.empty_data_message'),
    },
    pagination: false,
  };

  return {
    tableProps,
    detailInfoData,
  };
};

export default useTable;
