import {
  CheckCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FilePdfOutlined,
} from '@ant-design/icons';
import { Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Info } from 'src/components/icon';

type Props = {
  handleOrder: () => void;
  handleDelete: () => void;
  handleExportPDF: () => void;
  handleExportCSV: () => void;
  errors?: string[];
  disabledButton: boolean;
};

const QuotationAction: React.FC<Props> = ({
  handleDelete,
  handleOrder,
  handleExportPDF,
  handleExportCSV,
  errors,
  disabledButton,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row className="all">
        <Col xs={9} md={5} xl={5} className="all_checkbox">
          <Typography.Text className="all_action_note">
            {t('quotation_page.select_all')}
          </Typography.Text>
        </Col>
        <Col xs={15} md={19} xl={19} className="all_action">
          <Typography.Text className="all_action_note_last">
            {t('quotation_page.checked_items')}
          </Typography.Text>
          <Button
            type="primary"
            icon={<CheckCircleOutlined />}
            className="all_action_button order_desktop"
            onClick={handleOrder}
          >
            {t('quotation_page.order_now')}
          </Button>
          <Button
            type="primary"
            icon={<CheckCircleOutlined />}
            className="all_action_button order_mobile"
            onClick={handleOrder}
          >
            {t('quotation_page.order')}
          </Button>
          <Button
            type="primary"
            icon={<FilePdfOutlined />}
            className="all_action_button"
            onClick={handleExportPDF}
            disabled={disabledButton}
          >
            {t('quotation_page.pdf_output')}
          </Button>
          <Button
            type="primary"
            className="all_action_button"
            onClick={handleExportCSV}
            disabled={disabledButton}
          >
            {t('quotation_page.csv_output')}
          </Button>
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            className="all_action_button"
            onClick={handleDelete}
          >
            {t('quotation_page.delete')}
          </Button>
        </Col>
      </Row>
      <Row justify="end" className="note">
        {errors &&
          errors.map((value, index) => (
            <Col span={24} className="note_error" key={index}>
              <Typography.Text
                className="ant-form-item-explain ant-form-item-explain-error"
                type="danger"
                ellipsis={{ tooltip: true }}
              >
                <Info />
                {value}
              </Typography.Text>
            </Col>
          ))}
        <Col span={24} className="note_sub">
          <ExclamationCircleOutlined />
          <Typography.Text>{t('quotation_page.note')}</Typography.Text>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default QuotationAction;
