import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import history from 'src/libs/history';
import { PATH as HOME_PATH } from 'src/pages/home';
import policy from 'src/repositories/policy';

export const CUSTOMER_INFO_PATH = '/common/current-account-info';
export const AGREE_PATH = '/initialization-info-login/policy-check';

const usePolicy = () => {
  const getCustomerInfo = useQuery<
    AxiosResponse<Response.Me>,
    AxiosError<Response.Error>
  >({
    queryKey: [CUSTOMER_INFO_PATH],
    queryFn: policy.getCustomerInfo,
  });

  const agreePolicy = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>
  >({
    mutationKey: [AGREE_PATH],
    mutationFn: (args) => policy.updatePolicyCheck(AGREE_PATH),
    retry: 0,
    async onSuccess(res) {
      history.push(HOME_PATH);
    },
    onError(err) {
      console.log(err);
    },
  });

  return { agreePolicy, getCustomerInfo };
};

export default usePolicy;
