import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import account from 'src/repositories/account';
import { FormValue } from 'src/containers/Account/useForm';
import { UseFormSetError } from 'react-hook-form/dist/types/form';
import { I18n, t } from 'src/libs/i18n';

type UseAccountSetting = {
  setError?: UseFormSetError<FormValue>;
};

const useAccountSetting = ({ setError }: UseAccountSetting) => {
  const apiPath = '/account-setting';
  const queryClient = useQueryClient();

  const queryAccount = useQuery<
    AxiosResponse<Response.AccountSetting>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPath],
    queryFn: account.getAccountSetting,
  });

  const updateAccountMutation = useMutation<
    AxiosResponse<Response.AccountSetting>,
    AxiosError<Response.Error>,
    Payload.UpdateAccountSetting
  >({
    mutationKey: [apiPath],
    mutationFn: (args) => {
      return account.updateAccountSetting(apiPath, args);
    },
    onSuccess: () => {
      queryClient.invalidateQueries([apiPath]);
    },
    onError: (error) => {
      const errorData = error.response?.data;
      const errorCode = errorData?.code;
      // @ts-ignore
      setError(errorData.field, {
        message: I18n.t(`message.${errorCode}`, {
          param1: t('account.delivery_name'),
        }),
      });
    },
  });

  return { queryAccount, updateAccountMutation };
};

export default useAccountSetting;
