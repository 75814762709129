import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Popover, Radio, Divider, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { UseFormReturn } from 'react-hook-form';

import { FormValue } from '../useForm';
import { MACHINING_CODE_CONDITION } from 'src/constants/quotation/optionSelect';
import useProductMachining from 'src/queries/useProductMachining';
import Modal from 'src/components/custom/Modal';
import { ChamferModal } from 'src/components/common';
import { SCREENS } from 'src/constants/screens';
import { getProductName } from 'src/utils/helper';

type Props = {
  product: string;
  machiningMethod: string;
  setMachiningMethod: (e) => void;
  formInfo: UseFormReturn<FormValue, any>;
  dataReEstimate?: Response.EstimatePriceDetail['item'];
  isFetchingMachinings: number;
  dataProductDimension?: Response.ProductDimension['item'];
  resetInputData: () => void;
};

const MachiningMethodList: React.FC<Props> = ({
  product,
  machiningMethod,
  setMachiningMethod,
  formInfo,
  dataReEstimate,
  isFetchingMachinings,
  dataProductDimension,
  resetInputData,
}) => {
  const [chamferMethod, setChamferMethod] =
    useState<Response.ChamferItem | null>();
  const [visibleChamferPopup, setVisibleChamferPopup] = useState(false);
  const { t } = useTranslation();
  const { machiningsData: machinings } = useProductMachining(product);
  const { setValue, getValues } = formInfo;
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    // auto fill data machining when ReEstimate from OrderHistory page or back from ProductDetails Page
    if (
      (dataReEstimate?.setdnKakoCd || dataProductDimension?.setdnKakoCd) &&
      !isFilled
    ) {
      if (dataReEstimate?.setdnKakoCd) {
        setMachiningMethod(dataReEstimate?.setdnKakoCd);
        setMachiningItem(dataReEstimate?.setdnKakoCd);
      }

      if (dataProductDimension?.setdnKakoCd) {
        setMachiningMethod(dataProductDimension?.setdnKakoCd);
        setMachiningItem(dataProductDimension?.setdnKakoCd);
      }
      if (dataReEstimate?.fraiseKakoPtnm) {
        onSelectChamferMethod({
          fraiseKakoPtnMeis: dataReEstimate?.fraiseKakoPtnm,
          fraiseKakoPtn: dataReEstimate?.fraiseKakoPtn,
          setdnKakoCd: dataReEstimate?.setdnKakoCd,
        });
      }
      if (isFetchingMachinings === 0) {
        setIsFilled(true);
      } else {
        setIsFilled(false);
      }
      return;
    }
    // set default machining method equal first value
    const value = _.get(machinings.methods, '[0].value');
    setMachiningMethod(value);
    setMachiningItem(value);
  }, [
    _.get(machinings.methods, '[0].value'),
    isFetchingMachinings,
    machinings.methods?.length,
  ]);

  // set machining value when choose chamfer method
  useEffect(() => {
    if (chamferMethod?.setdnKakoCd) {
      const currentMethod = machinings.methods?.find(
        (item) => item.value === chamferMethod?.setdnKakoCd,
      );
      if (currentMethod) {
        setMachiningMethod(currentMethod && currentMethod.value);
      } else {
        setMachiningMethod('');
        setChamferMethod(null);
        setValue('chamferItem', {});
      }
    }
  }, [chamferMethod?.setdnKakoCd]);

  // resset value input sizes when value machining method changed.
  useEffect(() => {
    resetInputData();
    // recheck visible popup chamfer
    if (machiningMethod !== '') {
      checkVisibleChamferPopup(machiningMethod);
    }
  }, [machiningMethod, product]);

  const _onChangeMachining = (e) => {
    const { value } = e.target;
    setMachiningMethod(value);
    setMachiningItem(value);
    // reset chamfer method value
    setChamferMethod(null);
    checkVisibleChamferPopup(value);
  };

  const checkVisibleChamferPopup = (value) => {
    const selectedMachiningMethod =
      machinings && machinings?.methods?.find((item) => item.value === value);
    if (
      selectedMachiningMethod &&
      MACHINING_CODE_CONDITION.includes(selectedMachiningMethod.value)
    ) {
      setVisibleChamferPopup(true);
    } else {
      setVisibleChamferPopup(false);
    }
  };

  // set Machining Item by id
  const setMachiningItem = (value) => {
    const machiningItem = machinings?.methods?.find(
      (item) => item.value == value,
    );
    setValue('machiningItem', machiningItem);
  };

  // select chamfer method and set item chamfer to form
  const onSelectChamferMethod = (value) => {
    setChamferMethod(value);
    setValue('chamferItem', value || {});
  };

  return (
    <Row className="machining_row">
      <Col xs={24} sm={24} md={4} xl={3} className="form_typo">
        <Typography.Text className="form_label_required">
          {t('pricing_page.machining_method_field')}
        </Typography.Text>
      </Col>
      <Col xs={24} sm={24} md={20} xl={21} className="machining_col">
        <Radio.Group
          className="w-full machining_checkbox machining_col"
          onChange={_onChangeMachining}
          value={machiningMethod}
        >
          {machinings?.methods?.map((item, index) => {
            return (
              <Popover
                placement="bottomLeft"
                key={index}
                content={item?.description}
                trigger="hover"
              >
                <Radio className="mr-0 mb-24" value={item.value}>
                  {item.label}
                </Radio>
              </Popover>
            );
          })}
        </Radio.Group>
      </Col>
      {visibleChamferPopup && (
        <Col xs={24} md={20} xl={21} className="machining_handle machining_col">
          <Divider className="mg-vertical-10" />
          <Modal
            modalProps={{ width: 840 }}
            title={t('chamfer_modal.title')}
            content={({ onClose }) => (
              <ChamferModal
                screenId={SCREENS.R2004}
                onSelect={onSelectChamferMethod}
                productName={getProductName(getValues('productItem'), true)}
                processingMethod={getValues('machiningItem')?.label}
                onClose={onClose}
              />
            )}
          >
            <span>
              {chamferMethod && (
                <span className="chamfer_method">
                  ({chamferMethod?.fraiseKakoPtnMeis}){' '}
                </span>
              )}
              <Button className="machining_handle_button" type="primary">
                {t('pricing_page.machining_method_button')}
              </Button>
            </span>
          </Modal>
        </Col>
      )}
    </Row>
  );
};

export default MachiningMethodList;
