import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';

import history from 'src/libs/history';
import auth from 'src/repositories/auth';
import LocalStorage from 'src/utils/LocalStorage';
import { PATH as HOME_PATH } from 'src/pages/home';
import { PATH as POLICY_PATH } from 'src/pages/policy';
import { useTranslation } from 'react-i18next';
import { IS_CHECK_POLICY, STATUS_CODE } from 'src/constants/app';

export const apiKey = '/auth' as const;

const useSignIn = (setError: any, dataInit: any) => {
  const { t } = useTranslation();
  const client = useQueryClient();

  return useMutation<
    AxiosResponse<Response.SignInData>,
    AxiosError,
    Payload.Credentials
  >({
    mutationKey: [apiKey],
    mutationFn: (args) => auth.login(apiKey, args),
    retry: 0,
    async onSuccess(res) {
      const token = res.data.accessToken;
      const meRes = await client.fetchQuery<AxiosResponse<Response.Me>>(
        ['/common/current-account-info', token],
        auth.me,
      );

      LocalStorage.saveAuthInfo(meRes.data.item);
      LocalStorage.setToken(token);
      LocalStorage.setInitSiteInfo(dataInit);

      history.push(
        meRes?.data?.item?.policyCheck === IS_CHECK_POLICY.TRUE
          ? HOME_PATH
          : POLICY_PATH,
      );
    },
    onError(err) {
      const errorCode = _.get(err, 'response.data.code');
      const paramCount = _.get(err, 'response.data.params[0]'); // number of retries
      switch (errorCode) {
        case STATUS_CODE.EMAIL_NOT_EXIST:
          return setError('email', {
            type: 'custom',
            message: t('message.E000032', { param1: t('label.email') }),
          });
        case STATUS_CODE.WRONG_PASSWORD:
          return setError('password', {
            type: 'custom',
            message: t('message.E000037', { 0: paramCount }),
          });
        case STATUS_CODE.WRONG_PASSWORD_LOCKED:
          return setError('password', {
            type: 'custom',
            message: t('message.E000038'),
          });
        case STATUS_CODE.USER_LOCKED:
          return setError('password', {
            type: 'custom',
            message: t('message.E000039'),
          });
      }
    },
  });
};

export default useSignIn;
