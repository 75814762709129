import { t } from 'i18next';
import React from 'react';

import QuotationContainer from 'src/containers/Quotation';
import { withRemountOnHistoryPush } from 'src/hocs/withRemountOnHistoryPush';
import useTitle from 'src/hooks/useTitle';

const QuotationPage: React.FC = () => {
  useTitle(t('quotation_page.page_title'));
  return <QuotationContainer />;
};

export const PATH = '/quotation' as const;
export default withRemountOnHistoryPush(QuotationPage);
