import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { API_KEY } from 'src/constants/apiKey';

import quotation from 'src/repositories/quotation';

type UseManufacturingImport = {
  handleSuccess: (
    res: AxiosResponse<Response.ManufacturingSize2AndSize3, any>,
    args: Payload.ManufacturingImport,
  ) => void;
};

const useManufacturingImport = ({ handleSuccess }: UseManufacturingImport) => {
  const getManufacturingImportMutation = useMutation<
    AxiosResponse<Response.ManufacturingSize2AndSize3>,
    AxiosError<Response.Error>,
    Payload.ManufacturingImport
  >({
    mutationKey: [API_KEY.MANUFACTURING_SIZES_Z0],
    mutationFn: (args) => {
      return quotation.getManufacturingImport(
        API_KEY.MANUFACTURING_SIZES_2_AND_SIZE_3,
        args,
      );
    },
    onSuccess: (res, args) => handleSuccess(res, args),
    onError: (error) => {
      console.log(error.response);
    },
  });

  return { getManufacturingImportMutation };
};

export default useManufacturingImport;
