import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import initLogin from 'src/repositories/initLogin';

const apiKey = '/initialization-info-login';

const useInitLogin = () => {
  const { data, ...rest } = useQuery<
    AxiosResponse<Response.SiteInitLogin>,
    AxiosError<any>
  >([apiKey], initLogin.getInitLogin, {
    retry: 0,
    onError(err) {
      console.log(err.response);
    },
  });
  return {
    data: data?.data?.item,
    ...rest,
  };
};

export default useInitLogin;
