import { Button, Col, Form, Row, Typography } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Card, Breadcrumb } from 'src/components/custom';
import { DestinationModal, RowDetail } from 'src/components/common';
import { NumberOnlyField } from 'src/components/form';
import useForm, { FormValue } from './useForm';
import useAccountSetting from 'src/queries/useAccountSetting';
import LoadingPage from 'src/components/applications/LoadingPage';
import Modal from 'src/components/custom/Modal';
import history from 'src/libs/history';
import { PATH } from 'src/pages/auth';
import { SCREENS } from 'src/constants/screens';

const Account: React.FC = () => {
  const { t } = useTranslation();
  const { control, getValues, setError, clearErrors, setValue } = useForm();
  const { queryAccount, updateAccountMutation } = useAccountSetting({
    setError,
  });
  const accountSetting = queryAccount.data?.data?.item;

  const onChooseDestination = (destination: Response.DestinationItem) => {
    clearErrors('otokesakiCd');
    setValue('otokesakiCd', destination?.rmOtokesaki?.otokesakiCd);
    updateAccountMutation.mutate({
      otokesakiCd: destination?.rmOtokesaki?.otokesakiCd,
    });
  };

  useEffect(() => {
    if (!accountSetting?.otokesakiCd) {
      setValue('otokesakiCd', '');
    } else {
      setValue('otokesakiCd', accountSetting?.otokesakiCd);
    }
  }, [accountSetting?.otokesakiCd]);

  return (
    <React.Fragment>
      {queryAccount.isLoading ? (
        <LoadingPage />
      ) : (
        <div className="mb-30 mt-30 account_setting">
          <Breadcrumb />
          <Card title={t('account.account_setting')}>
            <Row>
              <Typography.Text className="account_setting_text_red">
                {t('account.note_1')}
              </Typography.Text>
            </Row>
            <Row>
              <Typography.Text className="account_setting_text_red">
                {t('account.note_2')}
              </Typography.Text>
            </Row>
            <div className="p-20 mg-vertical-18 account_setting_block_infor">
              <RowDetail
                title={t('account.name_person')}
                value={accountSetting?.smei}
                layout={{
                  titleCol: { xs: 8, sm: 6, md: 5, lg: 4, xl: 3 },
                  valueCol: { xs: 16, sm: 18, md: 19, lg: 20, xl: 21 },
                }}
                rowClassName="account_setting_block_infor_row"
              />
              <RowDetail
                title={t('account.delivery_name')}
                value={
                  <Row>
                    <Col xs={12} xl={8}>
                      <Form layout="vertical" className="delivery">
                        <NumberOnlyField<FormValue>
                          controller={{
                            control,
                            name: 'otokesakiCd',
                            defaultValue: accountSetting?.otokesakiCd,
                          }}
                          inputProps={{
                            onBlur: async (event) => {
                              if (getValues().otokesakiCd) {
                                clearErrors('otokesakiCd');
                                updateAccountMutation.mutate({
                                  otokesakiCd: getValues().otokesakiCd,
                                });
                              }
                            },
                            maxLength: 3,
                          }}
                        />
                      </Form>
                    </Col>
                    <Col xs={12} xl={4} className="destination_action">
                      <Modal
                        modalProps={{ width: 840 }}
                        title={t('pricing_page.destination_modal.title')}
                        content={({ onClose }) => (
                          <DestinationModal
                            screenId={SCREENS.R2001}
                            onClose={onClose}
                            onSelect={onChooseDestination}
                          />
                        )}
                      >
                        <Button type="primary">
                          {t('account.button_selection')}
                        </Button>
                      </Modal>
                    </Col>
                    <Col xs={24} xl={9} className="mt-10">
                      <Typography.Text className="account_setting_text_name">
                        {accountSetting?.otokesakiNm}
                      </Typography.Text>
                    </Col>
                  </Row>
                }
                layout={{
                  titleCol: { xs: 8, sm: 6, md: 5, lg: 4, xl: 3 },
                  valueCol: { xs: 16, sm: 18, md: 19, lg: 20, xl: 21 },
                }}
                rowClassName="mb-16 mt-12 account_setting_block_infor_row"
                titleClassName="mt-12"
              />
              <RowDetail
                title={t('account.delivery_address')}
                value={accountSetting?.otodokesakiJusho}
                layout={{
                  titleCol: { xs: 8, sm: 6, md: 5, lg: 4, xl: 3 },
                  valueCol: { xs: 16, sm: 18, md: 19, lg: 20, xl: 21 },
                }}
                rowClassName="account_setting_block_infor_row"
              />
              <RowDetail
                title={t('account.email_reset_pass')}
                value={
                  <React.Fragment>
                    <Typography.Text className="account_setting_text_red account_setting_text_name">
                      {accountSetting?.email}
                    </Typography.Text>
                    <Button
                      className="mt-10"
                      type="primary"
                      onClick={() => history.push(PATH.FORGOT_PASSWORD)}
                    >
                      {t('account.button_reset')}
                    </Button>
                  </React.Fragment>
                }
                layout={{
                  titleCol: { xs: 8, sm: 6, md: 5, lg: 4, xl: 3 },
                  valueCol: { xs: 16, sm: 18, md: 19, lg: 20, xl: 21 },
                }}
                rowClassName="account_setting_block_infor_row"
                titleClassName="account_setting_label_container mt-20"
              />
            </div>
          </Card>
        </div>
      )}
    </React.Fragment>
  );
};

export default Account;
