import { Col } from 'antd';
import React from 'react';

import { DatePickerField } from 'src/components/form';
import useForm from './useForm';

const Demo: React.FC = () => {
  const { control } = useForm();

  return (
    <div className="mb-30 mt-30">
      <Col xs={5}>
        <DatePickerField controller={{ control, name: 'date' }} />
      </Col>
    </div>
  );
};

export default Demo;
