import React from 'react';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';

import { InputField } from 'src/components/form';
import useForm, { FormValue } from './useForm';
import history from 'src/libs/history';
import useClickOutiside from 'src/hooks/useClickOutiside';
import { PATH } from 'src/pages/category';
import { trim } from 'lodash';

type Props = {
  toggleSearchMobile?: (data: boolean) => void;
};

const SearchMobile: React.FC<Props> = ({ toggleSearchMobile }) => {
  const { control, handleSubmit } = useForm();
  const { t } = useTranslation();
  const onSearch = (val) => {
    history.push({
      pathname: PATH,
      search: qs.stringify({ hanyomRan: trim(val.keyword) }),
    });
  };

  const searchRef = useClickOutiside(() => {
    toggleSearchMobile && toggleSearchMobile(false);
  });

  return (
    <div className={'search_mobile'} ref={searchRef}>
      <Form onFinish={handleSubmit((val) => onSearch(val))}>
        <InputField<FormValue>
          controller={{ control, name: 'keyword' }}
          inputProps={{
            placeholder: t('top_nav.search_placeholder'),
          }}
          required
        />
      </Form>
    </div>
  );
};

export default SearchMobile;
