// @ts-nocheck
import React from 'react';
import { ConfigProvider } from 'antd';
import { useRoutes } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ja_JP from 'antd/es/locale/ja_JP';
import en_JP from 'antd/es/locale/en_US';
import th_JP from 'antd/es/locale/th_TH';
import zh_JP from 'antd/es/locale/zh_CN';
import tw_JP from 'antd/es/locale/zh_TW';
import vi_JP from 'antd/es/locale/vi_VN';
import ko_JP from 'antd/es/locale/ko_KR';
import AppProvider from 'src/store';
import routeConfig from 'src/routes';

import 'src/styles/index.less';

function App() {
  const routes = useRoutes(routeConfig);
  const { i18n } = useTranslation();

  const locateOptions = {
    ja: ja_JP,
    en: en_JP,
    vi: vi_JP,
    th: th_JP,
    zh: zh_JP,
    tw: tw_JP,
    ko: ko_JP,
  };

  (async () => {
    switch (i18n.language) {
      case 'ja':
        import('moment/locale/ja');
        break;
      case 'en':
        import('moment/locale/en-au');
        break;
      case 'vi':
        import('moment/locale/vi');
        break;
      case 'th':
        import('moment/locale/th');
        break;
      case 'zh':
        import('moment/locale/zh-cn');
        break;
      case 'tw':
        import('moment/locale/zh-tw');
        break;
      case 'ko':
        import('moment/locale/ko');
        break;
      default:
        import('moment/locale/ja');
    }
  })();

  return (
    <ConfigProvider locale={locateOptions[i18n.language]} key={i18n.language}>
      <AppProvider>{routes}</AppProvider>
    </ConfigProvider>
  );
}

export default App;
