import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import plugin from 'src/repositories/plugin';

const usePlugin = () => {
  const apiPath = '/plugin';

  const queryPlugin = useQuery<
    AxiosResponse<Response.PluginResponse>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPath],
    queryFn: plugin.getPlugin,
  });

  return { queryPlugin };
};

export default usePlugin;
