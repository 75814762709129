import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOutlined } from '@ant-design/icons';
import { TableProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { CaretRightOutlined } from '@ant-design/icons';

import ExpandedRowRender from './ExpandedRowRender';
import useTableCommon from 'src/hooks/useTable';
import ColumnRender from './ColumnRender';

const useTable = (dataSource, queryTaxes) => {
  const { t } = useTranslation();
  const { rowSelection, ...rest } = useTableCommon({
    hasSelect: false,
  });

  const columns: ColumnsType = [
    {
      align: 'left',
      width: '100%',
      render: (record) => <ColumnRender record={record} />,
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    expandable: {
      expandedRowRender: (record) => (
        <ExpandedRowRender item={record} queryTaxes={queryTaxes} />
      ),
      defaultExpandAllRows: true,
      expandIcon: ({ expanded, onExpand, record }) => (
        <CaretRightOutlined
          rotate={expanded ? 90 : 180}
          onClick={(e) => onExpand(record, e)}
        />
      ),
    },
    dataSource: [dataSource],
    loading: {
      spinning: false,
      indicator: <LoadingOutlined />,
    },
    rowKey: (record) => record.webMitrNo,
    showHeader: false,
    size: 'middle',
    pagination: false,
    className: 'custom_table',
    scroll: { x: 1100 },
    rowSelection: rowSelection,
    locale: {
      emptyText: t('message.E000024'),
    },
  };

  return {
    tableProps,
    ...rest,
  };
};

export default useTable;
