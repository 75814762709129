import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import category from 'src/repositories/category';

const apiKey = '/common/search-product';

const useProductVariety = (product) => {
  // check category by name product
  const { data, ...rest } = useQuery<
    AxiosResponse<Response.productVariety>,
    AxiosError<any>
  >([apiKey, { search: product }], category.getProductVariety, {
    retry: 0,
    enabled: !!product,
    onError(err) {
      console.log(err.response);
    },
  });
  return {
    productVariety: data?.data?.item,
    ...rest,
  };
};

export default useProductVariety;
