import { Button, Col, Form, Row, Typography } from 'antd';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InputField } from 'src/components/form';
import useFormNonUser, { FormNonUserValue } from '../useFormNonUser';
import LocalStorage from 'src/utils/LocalStorage';
import useRegisterGuest from 'src/queries/useRegisterGuest';

type ICustomerProfileProps = {
  estimatePriceData: Response.EstimatePrice['item'];
};

export default function CustomerProfile({
  estimatePriceData,
}: ICustomerProfileProps) {
  const { t } = useTranslation();
  const { form } = useFormNonUser();
  const { control, handleSubmit } = form;
  const [guest, setGuest] = React.useState<any>();

  const { registerGuest, downloadPDF } = useRegisterGuest(setGuest);

  const handleCreateGuest = (val: FormNonUserValue) => {
    setGuest(undefined);
    if (!val.mail && !val.smei) return;
    const dataSubmit = { ...val, webMitrNo: estimatePriceData.webMitrNo };
    if (val.mail === '') delete dataSubmit.mail;
    if (val.smei === '') delete dataSubmit.smei;
    registerGuest.mutate(dataSubmit);
  };

  const handleDowloadPDF = () => {
    if (guest) {
      const param = { seqnos: guest?.item, gengoCd: LocalStorage.lang };
      downloadPDF.mutate(param);
    }
  };

  return (
    <Form onFinish={handleSubmit(handleCreateGuest)}>
      <Row>
        <Col xs={24} md={24} xl={24} className="options_size">
          <Row gutter={10}>
            <Col xs={24} sm={12} md={9} xl={4} className="options_size_input">
              <Typography.Text className="form_label_no_required">
                {t('pricing_page.non_user.customer_name')}
              </Typography.Text>
              <InputField<FormNonUserValue>
                controller={{ control, name: 'smei' }}
                inputProps={{
                  placeholder: t('pricing_page.non_user.customer_name'),
                  minLength: 1,
                  maxLength: 90,
                }}
                labelCol={{ xs: 24, sm: 24, md: 4, xl: 3 }}
                className="input_tag"
              />
            </Col>
            <Col xs={24} sm={12} md={9} xl={7} className="options_size_input">
              <Typography.Text className="form_label_no_required">
                {t('label.email')}
              </Typography.Text>
              <InputField<FormNonUserValue>
                controller={{ control, name: 'mail' }}
                inputProps={{
                  placeholder: t('label.email'),
                  minLength: 1,
                  maxLength: 80,
                }}
                labelCol={{ xs: 24, sm: 24, md: 4, xl: 3 }}
                className="input_tag"
              />
            </Col>
            <Col xs={24} sm={16} md={3} className="options_size_input">
              <Typography.Text className="form_label_no_required">
                &nbsp;
              </Typography.Text>
              <Button
                className="w-full"
                type="primary"
                htmlType="submit"
                loading={registerGuest.isLoading}
              >
                {t('pricing_page.non_user.register')}
              </Button>
            </Col>
            <Col xs={24} sm={16} md={3}>
              <Typography.Text className="form_label_no_required">
                &nbsp;
              </Typography.Text>
              <Button
                className=" w-full"
                type="primary"
                disabled={!guest}
                onClick={handleDowloadPDF}
                loading={downloadPDF.isLoading}
              >
                {t('quotation_page.pdf_output')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={5} xl={18}>
              <Typography.Text className="non_user_message">
                {t('pricing_page.non_user.message')}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}
