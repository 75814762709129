import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';

import chamfer from '../repositories/chamfer';

const useQueryChamfer = (params) => {
  const apiPath = '/chamferings';

  const { data, ...rest } = useQuery<
    AxiosResponse<Response.ChamferList>,
    AxiosError<any>
  >([apiPath, params], chamfer.getChamfer, {
    retry: 0,
    onError(err) {
      console.log(err.response);
    },
  });

  return {
    ...rest,
    chamfers: data?.data?.items,
  };
};

export default useQueryChamfer;
