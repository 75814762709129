import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import history from 'src/libs/history';
import auth from 'src/repositories/auth';
import { PATH as HOME_PATH } from 'src/pages/home';
import LocalStorage from 'src/utils/LocalStorage';

export const apiKey = '/re-password/new-password' as const;

const useResetPass = () => {
  return useMutation<
    AxiosResponse<Response.ResetPass>,
    AxiosError,
    Payload.ResetPass
  >({
    mutationKey: [apiKey],
    mutationFn: (args) => auth.resetPass(apiKey, args),
    retry: 0,
    async onSuccess(res) {
      await LocalStorage.removeInfo();
      history.push(HOME_PATH);
    },
    onError(err) {
      console.log(err);
    },
  });
};

export default useResetPass;
