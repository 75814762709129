import React, { useEffect } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import { SelectField } from 'src/components/form';
import { FormValue } from '../useForm';
import useIndustryAndRegion from 'src/queries/useIndustryAndRegion';

type Props = {
  formInfo: any;
};

type SelectType = 'industry' | 'region';

const IndustryAndRegion: React.FC<Props> = ({ formInfo }) => {
  const { control, setValue, watch } = formInfo;
  const { t } = useTranslation();

  const { dataIndustry, dataInfo, dataRegion, updateIndustryOrRegion } =
    useIndustryAndRegion();

  useEffect(() => {
    setValue('industry', dataInfo?.gyoushuId);
    setValue('region', dataInfo?.shozaichiId);
  }, [dataInfo]);

  const handleSelect = (value, type: SelectType) => {
    if (dataInfo?.seqno) {
      updateIndustryOrRegion.mutate({
        seqno: dataInfo?.seqno,
        gyoushuId: type === 'industry' ? value : watch('industry'),
        shozaichiId: type === 'region' ? value : watch('region'),
      });
    }
  };

  const handleClear = (type: SelectType) => {
    if (dataInfo?.seqno) {
      updateIndustryOrRegion.mutate({
        seqno: dataInfo?.seqno,
        gyoushuId: type === 'industry' ? null : watch('industry'),
        shozaichiId: type === 'region' ? null : watch('region'),
      });
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={24} md={24} xl={24}>
          <SelectField<FormValue>
            controller={{
              name: 'region',
              control,
            }}
            className="input_tag"
            selectProps={{
              placeholder: t('pricing_page.region'),
              allowClear: true,
              options: dataRegion,
              onSelect: (value) => handleSelect(value, 'region'),
              onClear: () => handleClear('region'),
            }}
            label={t('pricing_page.region')}
            labelCol={{ xs: 24, sm: 24, md: 4, xl: 3 }}
            wrapperCol={{ xs: 24, sm: 24, md: 20, xl: 8 }}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24} xl={24}>
          <SelectField<FormValue>
            controller={{
              name: 'industry',
              control,
            }}
            className="input_tag radio_mt"
            selectProps={{
              placeholder: t('pricing_page.industry_classification'),
              allowClear: true,
              options: dataIndustry,
              onSelect: (value) => handleSelect(value, 'industry'),
              onClear: () => handleClear('industry'),
            }}
            label={t('pricing_page.industry_classification')}
            labelCol={{ xs: 24, sm: 24, md: 4, xl: 3 }}
            wrapperCol={{ xs: 24, sm: 24, md: 20, xl: 8 }}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default IndustryAndRegion;
