import React from 'react';
import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  InputField,
  RadioGroupField,
  HalfWidthInputField,
} from 'src/components/form';
import {
  MILLSHEET_OPTIONS,
  ROLL_EYES_OPTIONS,
} from 'src/constants/quotation/optionSelect';
import { FormValue } from '../useForm';

type Props = {
  visibleRollEyes: any;
  formInfo: any;
  isProductFinished: boolean;
};

const InputOptional: React.FC<Props> = ({
  visibleRollEyes,
  formInfo,
  isProductFinished,
}) => {
  const { control } = formInfo;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      {!isProductFinished && visibleRollEyes && (
        <Row align="middle">
          <Col xs={24} sm={24} md={5} lg={4} xl={3}>
            <Typography.Text className="form_label_no_required radio_title">
              {t('pricing_page.roll_eyes')}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={24} md={19} lg={20} xl={21}>
            <RadioGroupField
              radioGroupProps={{
                options: ROLL_EYES_OPTIONS(t),
                defaultValue: ROLL_EYES_OPTIONS(t)[0].value,
              }}
              controller={{
                name: 'roll_eyes',
                control,
              }}
              className="input_tag radio_mt"
            />
          </Col>
        </Row>
      )}
      {!isProductFinished && (
        <Row align="middle">
          <Col xs={24} sm={24} md={5} lg={4} xl={3}>
            <Typography.Text className="form_label_no_required radio_title">
              {t('pricing_page.mill_sheet')}
            </Typography.Text>
          </Col>
          <Col xs={24} sm={24} md={19} lg={20} xl={21}>
            <RadioGroupField
              radioGroupProps={{
                options: MILLSHEET_OPTIONS(t),
              }}
              controller={{
                name: 'mill_sheets',
                control,
                defaultValue: MILLSHEET_OPTIONS(t)[0].value,
              }}
              className="input_tag radio_mt"
            />
          </Col>
        </Row>
      )}

      <Row>
        <Col xs={24} md={24} xl={24}>
          <HalfWidthInputField<FormValue>
            controller={{ control, name: 'order_number' }}
            inputProps={{
              placeholder: t('pricing_page.order_number'),
              minLength: 1,
              maxLength: 30,
            }}
            label={t('pricing_page.order_number')}
            labelCol={{ xs: 24, sm: 24, md: 4, xl: 3 }}
            wrapperCol={{ xs: 24, sm: 24, md: 20, xl: 8 }}
            className="input_tag"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={24} xl={24}>
          <InputField<FormValue>
            controller={{ control, name: 'order_notes' }}
            inputProps={{
              placeholder: t('pricing_page.order_note'),
              minLength: 1,
              maxLength: 80,
            }}
            label={t('pricing_page.order_note')}
            labelCol={{ xs: 24, sm: 24, md: 4, xl: 3 }}
            wrapperCol={{ xs: 24, sm: 24, md: 20, xl: 8 }}
            className="input_tag"
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default InputOptional;
