import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import notification from 'src/repositories/notification';

const apiKey = '/top-screen/notifications';

const useNotification = () => {
  const { data, ...rest } = useQuery<
    AxiosResponse<Response.Notification>,
    AxiosError<any>
  >([apiKey], notification.getListNotification, {
    retry: 0,
    onError(err) {
      console.log(err.response);
    },
  });
  return {
    notifications: data?.data?.items,
    ...rest,
  };
};

export default useNotification;
