import React, { useEffect } from 'react';
import { Col, Row, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

import WebMitrDetail from 'src/components/common/WebMitrDetail';
import WeightUnitPrice from 'src/components/common/WebMitrDetail/WeightUnitPrice';
import { DatePickerField, InputField } from 'src/components/form';
import { BACK_ORDER_PRODUCT } from 'src/containers/Order/useForm';
import { useController } from 'react-hook-form';
import {
  checkNoukiDisplay,
  getDeliveryDateExternal,
  validateFormat,
} from 'src/utils/helper';
import { DISPLAY_OF_TOTAL_AMOUNT, SIMPLE_DATE_FORMAT } from 'src/constants/app';
import _ from 'lodash';
import { TaxDetail } from 'src/components/common';

type OrderRowDetailProps = {
  item: Response.WebMitrEntity;
  control: any;
  index: number;
  data?: Response.WebMitrListResponse;
  taxType?: string;
};

// Date of week
const SUNDAY = 0;
const SATURDAY = 6;
const ENTER = 'Enter';

const OrderRowDetail: React.FC<OrderRowDetailProps> = (props) => {
  const { item, control, index, data, taxType } = props;
  const { t } = useTranslation();
  const disabledDate = (date: Dayjs) => {
    return (
      date.isBefore(item.noki) ||
      date.isBefore(new Date()) ||
      date.day() === SATURDAY ||
      date.day() === SUNDAY
    );
  };

  const { field } = useController({
    control,
    name: `deliveryDates.${index}.value`,
  });

  useEffect(() => {
    field.onChange(item.noki?.slice(0, 10));
  }, []);

  const handleDateChange = (e: React.SyntheticEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    const noki = item.noki?.slice(0, 10);
    if (value && !validateFormat(value, SIMPLE_DATE_FORMAT)) {
      setTimeout(() => {
        field.onChange(noki);
      });
    } else if (dayjs(value).isBefore(dayjs(noki))) {
      setTimeout(() => {
        field.onChange(noki);
      });
    } else {
      field.onChange(value);
    }
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleDateChange(e);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === ENTER) {
      handleDateChange(e);
    }
  };

  const showPriceIncludingTax =
    taxType === DISPLAY_OF_TOTAL_AMOUNT.TAX_INCLUDED;

  return (
    <React.Fragment>
      <div className="item">
        <Row justify="end">
          <Col xs={24} md={24}>
            <div className="item_data">
              <WebMitrDetail item={item} />
              <Row className="mb-10 item_data_order">
                <Col
                  xs={24}
                  md={showPriceIncludingTax ? 8 : 12}
                  className="item_data_order_delivery"
                >
                  <Row className="delivery">
                    <Col xs={8} md={showPriceIncludingTax ? 12 : 8}>
                      <Typography.Title className="delivery-date" level={5}>
                        {t('order_page.delivery_date') + ':'}
                      </Typography.Title>
                    </Col>
                    {item.zaitokKbn === BACK_ORDER_PRODUCT ? (
                      checkNoukiDisplay(item?.noukiDisplay) ? (
                        <div className="shipping-method">
                          <Typography.Text>
                            {t('order_page.delivery_method.surface', {
                              0: getDeliveryDateExternal(item?.noukiDisplay)
                                ?.byShip,
                            })}
                          </Typography.Text>
                          <Typography.Text>
                            {t('order_page.delivery_method.flight', {
                              0: getDeliveryDateExternal(item?.noukiDisplay)
                                ?.byAirplane,
                            })}
                          </Typography.Text>
                        </div>
                      ) : (
                        <Typography.Text>
                          {item?.noukiDisplay
                            ? dayjs(item?.noukiDisplay).format(
                                SIMPLE_DATE_FORMAT,
                              )
                            : ''}
                        </Typography.Text>
                      )
                    ) : (
                      <Col xs={16} sm={19} md={18}>
                        <DatePickerField
                          datePickerProps={{
                            disabledDate,
                            showToday: false,
                            format: SIMPLE_DATE_FORMAT,
                          }}
                          controller={{
                            control,
                            name: `deliveryDates.${index}.value`,
                            defaultValue: item.noki?.slice(0, 10),
                          }}
                          onBlur={handleBlur}
                          onKeyDown={handleKeyDown}
                        />
                        <InputField
                          className="web-mitr-no"
                          controller={{
                            control,
                            name: `deliveryDates.${index}.key`,
                            defaultValue: item.webMitrNo,
                          }}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
                <WeightUnitPrice
                  item={item}
                  currentCurrency={data?.currencyUnit}
                  screen="R1006"
                  showPriceIncludingTax={showPriceIncludingTax}
                />
              </Row>
              {_.size(item?.mitsumoriTaxResList) > 0 && (
                <TaxDetail item={item} />
              )}
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default OrderRowDetail;
