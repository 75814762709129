import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { API_KEY } from 'src/constants/apiKey';

import quotation from 'src/repositories/quotation';

type UseTolerance = {
  handleSuccess: (res: AxiosResponse<Response.Tolerance, any>) => void;
};

const useTolerance = ({ handleSuccess }: UseTolerance) => {
  const getToleranceMutaion = useMutation<
    AxiosResponse<Response.Tolerance>,
    AxiosError<Response.Error>,
    Payload.Tolerance
  >({
    mutationKey: [API_KEY.TOLERANCE],
    mutationFn: (args) => {
      return quotation.getTolerance(API_KEY.TOLERANCE, args);
    },
    onSuccess: (res) => handleSuccess(res),
    onError: (error) => {
      console.log(error.response);
    },
  });

  return { getToleranceMutaion };
};

export default useTolerance;
