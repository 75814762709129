import { AxiosResponse } from 'axios';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import { FormValue } from 'src/containers/Pricing/useForm';
import useConvertSizeMutation from 'src/queries/useConvertSizeMutation';
import { getSizesById, isImportedProductOnly } from 'src/utils/helper';

type UseConvertSizeHook = {
  product: string;
  manufacturingSizes;
  setValue: UseFormSetValue<FormValue>;
  getValues: UseFormGetValues<FormValue>;
  isFetching: boolean;
  isFilled: boolean;
  estimatePriceData;
  handleGetManufacturingImportMutation: (hasSize2?: boolean) => void;
};

type CheckConvertSizeParam = {
  sizeType?: string;
  isSetValue: boolean;
  data?: string;
};

const useConvertSize = (props: UseConvertSizeHook) => {
  const {
    product,
    manufacturingSizes,
    setValue,
    getValues,
    isFetching,
    isFilled,
    estimatePriceData,
    handleGetManufacturingImportMutation,
  } = props;

  const [conversionSize1, setConversionSize1] = useState<string>();
  const [conversionSize2, setConversionSize2] = useState<string>();
  const [conversionSize3, setConversionSize3] = useState<string>();
  const conversionSize1MultipleRef = useRef<string[]>([]);

  const handleSuccessConvertSize = (
    res: AxiosResponse<Response.ConvertSize, any>,
    arg: Payload.ConvertSize,
  ) => {
    const { sizeType, index } = arg;
    const splitProductThicknessSize =
      getValues('product_thickness')?.split('x');
    if (
      sizeType === 'product_thickness' &&
      _.size(splitProductThicknessSize) > 1
    ) {
      //Replace the original size with the converted size corresponding to the index
      conversionSize1MultipleRef.current =
        conversionSize1MultipleRef.current.map((value, i) =>
          i === index ? res?.data?.item : value,
        );
    } else {
      checkConditionConvertionSize({
        sizeType: arg?.sizeType,
        isSetValue: true,
        data: res?.data?.item,
      });
    }
  };

  const { convertSizeMutation } = useConvertSizeMutation({
    handleSuccess: handleSuccessConvertSize,
  });
  const checkConditionConvertionSize = (param: CheckConvertSizeParam) => {
    const { sizeType, isSetValue, data } = param;
    let value;
    switch (sizeType) {
      case 'product_thickness':
        if (isSetValue) {
          setConversionSize1(data);
          setValue('converted_product_thickness', data);
        }
        value = manufacturingSizes?.unitSize1;
        break;
      case 'product_width':
        if (isSetValue) {
          setConversionSize2(data);
          setValue('converted_product_width', data);
        }
        value = manufacturingSizes?.unitSize2;
        break;
      case 'product_length':
        if (isSetValue) {
          setConversionSize3(data);
          setValue('converted_product_length', data);
        }
        value = manufacturingSizes?.unitSize3;
        break;
      default:
        value = undefined;
        break;
    }
    return value !== undefined ? value : '';
  };

  const handleConvertSize = (
    inputType: 'product_thickness' | 'product_width' | 'product_length',
  ) => {
    if (
      isImportedProductOnly({ product: product, checkZ0: false }) &&
      manufacturingSizes?.sizeMustConvert === true &&
      manufacturingSizes?.originSize !==
        checkConditionConvertionSize({
          sizeType: inputType,
          isSetValue: false,
          data: undefined,
        })
    ) {
      const inputTypeValue = getValues(inputType);
      let inputValue;

      if (inputType === 'product_thickness') {
        const sizesById = getSizesById(
          manufacturingSizes?.sizes?.find(
            (item) => item.value === inputTypeValue,
          )?.key,
        );
        inputValue = sizesById || inputTypeValue;
      } else {
        inputValue = inputTypeValue;
      }

      if (inputValue) {
        if (
          inputType === 'product_thickness' &&
          _.size(inputTypeValue.split('x')) > 1
        ) {
          const unitArray = manufacturingSizes?.unitSize1?.split('x');
          const size1Array = inputValue?.split('x');
          const checkHasOtherUnit = unitArray?.some(
            (item) => item !== manufacturingSizes?.originSize,
          );
          if (checkHasOtherUnit) {
            conversionSize1MultipleRef.current = size1Array;
            size1Array?.map((value, index) => {
              convertSizeMutation.mutate({
                originUnit: manufacturingSizes?.originSize,
                unitBeforeConvert: unitArray?.[index],
                unitAfterConvert: manufacturingSizes?.originSize,
                convertValue: value,
                index: index,
                sizeType: inputType,
              });
            });
          }
        } else {
          convertSizeMutation.mutate({
            originUnit: manufacturingSizes?.originSize,
            unitBeforeConvert: checkConditionConvertionSize({
              sizeType: inputType,
              isSetValue: false,
              data: undefined,
            }),
            unitAfterConvert: manufacturingSizes?.originSize,
            convertValue: inputValue,
            sizeType: inputType,
          });
        }
      } else {
        checkConditionConvertionSize({
          sizeType: inputType,
          isSetValue: true,
          data: undefined,
        });
        if (inputType === 'product_thickness') {
          conversionSize1MultipleRef.current = [];
        }
      }
    }
  };

  useEffect(() => {
    if (_.size(conversionSize1MultipleRef.current) > 0) {
      setValue(
        'converted_product_thickness',
        conversionSize1MultipleRef.current.join('x'),
      );
    }
  }, [conversionSize1MultipleRef.current]);

  useEffect(() => {
    if (isFetching) {
      conversionSize1MultipleRef.current = [];
      setConversionSize1(undefined);
      setConversionSize2(undefined);
      setConversionSize3(undefined);
    }
  }, [isFetching]);

  useEffect(() => {
    if (!isFilled && !estimatePriceData?.hasUpdateFinishingSize) {
      //auto call api get size 2 or 3 when size converted
      if (conversionSize2) {
        manufacturingSizes?.size2Label &&
          handleGetManufacturingImportMutation(true);
      } else {
        _.size(conversionSize1MultipleRef.current) > 0 &&
          manufacturingSizes?.size1Label &&
          handleGetManufacturingImportMutation();
      }
    }
  }, [conversionSize1MultipleRef.current, conversionSize2]);

  return {
    handleConvertSize,
    checkConditionConvertionSize,
    conversionSize1,
    conversionSize2,
    conversionSize3,
    setConversionSize1,
    setConversionSize2,
    setConversionSize3,
    conversionSize1MultipleRef,
  };
};

export default useConvertSize;
