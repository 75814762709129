/**
 * merge productCd for value display checkbox
 * @param hakudoShhnCd: product imported id
 * @param shhnCd: product local id
 * @param api1ShhnCd: product api1 id
 * @param api2ShhnCd: product api2 id
 * @returns mergeId
 */
function mergeProductId(
  hakudoShhnCd: string,
  shhnCd: string,
  api1ShhnCd: string,
  api2ShhnCd: string,
) {
  return `${hakudoShhnCd || 'null'}-${shhnCd || 'null'}-${
    api1ShhnCd || 'null'
  }-${api2ShhnCd || 'null'}`;
}

/**
 * split mergeId to hakudoShhnCd and shhnCd to request other api
 * @param mergeId: string constant two id of product
 * @returns two id of product {hakudoShhnCd, shhnCd}
 */
function splitProductId(mergeId: string) {
  const producIds = mergeId && mergeId?.split('-');
  return {
    hakudoShhnCd: producIds && producIds[0] !== 'null' ? producIds[0] : null,
    shhnCd: producIds && producIds[1] !== 'null' ? producIds[1] : null,
    api1ShhnCd: producIds && producIds[2] !== 'null' ? producIds[2] : null,
    api2ShhnCd: producIds && producIds[3] !== 'null' ? producIds[3] : null,
  };
}

export const productFormat = {
  mergeProductId,
  splitProductId,
};
