export const SCREENS = {
  R1001: 'ログインページ',
  R1002: 'トップページ',
  R1003: 'お試し見積り',
  R1004: '見積入力',
  R1005: '見積・発注履歴',
  R1006: '注文内容登録',
  R1009: 'アカウント設定',
  R1012: 'カテゴリー',
  R1010: '確認コード入力',
  R1011: 'パスワード再設定',
  R2001: 'お届け先検索ポップアップ',
  R2004: '面取り加工ポップアップ',
  R1013: '利用規約',
  R1014: '商品詳細',
};
