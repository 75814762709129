import React from 'react';

import { PATH } from 'src/pages/pricingTrial';
import RestrictLayout from 'src/components/applications/RestrictLayout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';
import { SCREENS } from 'src/constants/screens';

const PricingTrialRoute: IRouter[] = [
  {
    element: <RestrictLayout title={'pricing_page.page_trial_title'} />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/pricingTrial')),
        title: 'pricing_page.page_trial_title',
        screenId: SCREENS.R1003,
      },
    ],
  },
];

export default PricingTrialRoute;
