import React from 'react';
import {
  Col,
  Form,
  FormItemProps,
  Input,
  InputProps,
  Row,
  Typography,
} from 'antd';
import { useController, UseControllerProps } from 'react-hook-form';
import { REGEX } from 'src/constants/app';

type Props<T> = {
  inputProps?: InputProps;
  controller: UseControllerProps<T>;
  errorCol?: 12 | 24;
  isOnlyInteger?: boolean;
  onBlur?: () => void;
  newRegex?: RegExp;
} & Omit<FormItemProps, 'children'>;

function NumberOnlyField<T extends Record<string, any>>(props: Props<T>) {
  const {
    inputProps,
    controller,
    errorCol = 24,
    label,
    isOnlyInteger,
    onBlur,
    newRegex,
    ...rest
  } = props;
  const { fieldState, field } = useController<T>(controller);
  const { error } = fieldState;

  const regex = isOnlyInteger
    ? REGEX.REGEX_ONLY_INTEGER_NUMBER
    : REGEX.REGEX_ONLY_NUMBER;

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;

    // fix maxLength not work with japanese half-width characters
    if (inputProps?.maxLength && value.length > inputProps?.maxLength) {
      return;
    }

    const checkRegex = () => {
      return newRegex
        ? regex.test(value) && newRegex.test(value)
        : regex.test(value);
    };

    if ((!isNaN(Number(value)) && checkRegex()) || value === '') {
      field.onChange(value);
    } else {
      !field.value && field.onChange('');
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (!regex.test(e.clipboardData.getData('Text'))) {
      e.preventDefault();
    }
  };

  return (
    <Form.Item
      colon={false}
      label={
        <Typography.Text
          style={{
            width: '100%',
          }}
        >
          {label}
        </Typography.Text>
      }
      labelAlign="left"
      labelCol={label ? { span: 24 } : undefined}
      wrapperCol={label ? { span: 24 } : undefined}
      validateStatus={error ? 'error' : ''}
      {...rest}
    >
      <Row gutter={4}>
        <Col span={errorCol}>
          <Row gutter={8}>
            <Col span={24}>
              <Input
                {...field}
                {...inputProps}
                onChange={onChangeInput}
                onPaste={onPaste}
                onBlur={() => onBlur && onBlur()}
                style={{ width: '100%' }}
              />
            </Col>
          </Row>
        </Col>
        <Col span={errorCol}>
          {error && (
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error"
              type="danger"
              ellipsis={{ tooltip: true }}
              style={{ lineHeight: errorCol === 24 ? '16px' : '42px' }}
            >
              {error.message}
            </Typography.Text>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
}

export default NumberOnlyField;
