import React from 'react';
import { WebMitrDetailProps } from '../index';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { formatCountOnUnit, formatPrice } from 'src/utils/helper';
import { NumberFormatter } from 'src/utils/number';
import classNames from 'classnames';

const WeightUnitPrice: React.FC<WebMitrDetailProps> = (
  props: WebMitrDetailProps,
) => {
  const {
    item,
    type,
    screen = 'R1005',
    currentCurrency,
    showPriceIncludingTax,
  } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col xs={24} md={4} className="item_data_order_weight">
        <Row className="back-order-product">
          <Col xs={8} md={24}>
            <Typography.Title level={5}>
              {t('quotation_page.number_and_weight')}
            </Typography.Title>
          </Col>
          <Col xs={16} md={24}>
            <Typography.Text>
              {formatCountOnUnit(
                item?.inzu,
                item?.inzuTani,
                item?.jury,
                item?.juryTani,
                item?.henkanJury,
              )}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={4} className="item_data_order_unit">
        <Row className="back-order-product">
          <Col xs={8} md={24}>
            <Typography.Title level={5}>
              {t('quotation_page.unit_price_excluding_tax')}
            </Typography.Title>
          </Col>
          <Col xs={16} md={24} className="price">
            <Typography.Text>
              {formatPrice(
                NumberFormatter.format(item.tanka),
                screen === 'R1006' &&
                  currentCurrency &&
                  currentCurrency !== item.tankaTani.split('/')[0]
                  ? `(${currentCurrency})/${item.tankaTani.split('/')[1]}`
                  : item.tankaTani,
              )}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col
        xs={24}
        md={4}
        className={classNames(
          'item_data_order_total ',
          {
            'item_expired_total border-right-expired': type === 'expired',
          },
          { 'border-right': showPriceIncludingTax },
        )}
      >
        <Row className="back-order-product">
          <Col xs={8} md={24}>
            <Typography.Title level={5}>
              {t('quotation_page.total_tax_excluded')}
            </Typography.Title>
          </Col>
          <Col xs={16} md={24}>
            <Typography.Text>
              {formatPrice(
                NumberFormatter.format(item.sogaku),
                screen === 'R1006' &&
                  currentCurrency &&
                  currentCurrency !== item.sogakuTani
                  ? `(${currentCurrency})`
                  : `(${item.sogakuTani})`,
              )}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      {showPriceIncludingTax && (
        <Col
          xs={24}
          md={4}
          className={classNames('item_data_order_total', {
            item_expired_total: type === 'expired',
          })}
        >
          <Row className="back-order-product">
            <Col xs={8} md={24}>
              <Typography.Title level={5}>
                {t('quotation_page.total_tax_included')}
              </Typography.Title>
            </Col>
            <Col xs={16} md={24}>
              <Typography.Text>
                {formatPrice(
                  NumberFormatter.format(item.mitrGaku),
                  screen === 'R1006' &&
                    currentCurrency &&
                    currentCurrency !== item.sogakuTani
                    ? `(${currentCurrency})`
                    : `(${item.sogakuTani})`,
                )}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      )}
    </React.Fragment>
  );
};
export default WeightUnitPrice;
