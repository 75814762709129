import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';

import category from 'src/repositories/category';
import quotation from 'src/repositories/quotation';

const apiKey = '/common/category/list';
const apiProductKey = '/product/list/by-category';

const useProductCategory = (handleSuccess?) => {
  const { data, ...rest } = useQuery<
    AxiosResponse<Response.Category>,
    AxiosError<any>
  >([apiKey], category.getProductCategory, {
    retry: 0,
    onError(err) {
      console.log(err.response);
    },
  });

  const getProductList = useMutation<
    AxiosResponse<Response.ProductList>,
    AxiosError<Response.Error>,
    Payload.ProductList
  >({
    mutationKey: [apiProductKey],
    mutationFn: (args) => {
      return quotation.getProductList(apiProductKey, args);
    },
    onSuccess: (res, args) => handleSuccess && handleSuccess(res, args),
    onError(err) {
      console.log(err.response);
    },
  });

  const dataCategories = data?.data?.items;
  const categories = _.orderBy(dataCategories, ['categoryId'], ['asc']);

  return {
    categories: categories,
    getProductList,
    ...rest,
  };
};

export default useProductCategory;
