import React from 'react';

import useTitle from 'src/hooks/useTitle';
import PricingContainer from 'src/containers/Pricing';
import { t } from 'src/libs/i18n';

const PricingPage: React.FC = () => {
  useTitle(t('pricing_page.page_title'));
  return <PricingContainer isTrialMode={false} />;
};

export const PATH = '/pricing' as const;
export default PricingPage;
