import React, { useEffect, useState } from 'react';
import { Col, Row, Table, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';

import useTable from './useTable';
import ErrorMessage from '../ErrorMessage';

type Props = {
  estimatePriceData: Response.EstimatePrice['item'];
  queryTaxes?: Response.TaxesResponse['items'];
};

const CalculateResultTable: React.FC<Props> = ({
  estimatePriceData,
  queryTaxes,
}) => {
  const { t } = useTranslation();
  const [errorLists, setErrorLists] = useState<string[]>([]);
  const { tableProps } = useTable(estimatePriceData, queryTaxes);

  useEffect(() => {
    let errors: string[] = [];
    if (estimatePriceData?.totalMoneyExceedAllowed) {
      errors.push(t('message.E000063'));
    }
    setErrorLists(errors);
  }, [estimatePriceData]);

  return (
    <React.Fragment>
      <div className="content_pricing_table_title">
        {estimatePriceData?.hasUpdateFinishingSize && (
          <Typography.Title level={5} className="note">
            {t('pricing_page.notes.text4')}
          </Typography.Title>
        )}
        <Typography.Title level={1} className="mt-20 card_title">
          {t('pricing_page.estimate_results.title')}
        </Typography.Title>
        <Divider className="mt-5 mb-0 card_divider" />
      </div>
      <Row>
        <Col span={24}>
          <Table {...tableProps} className="content_pricing_table" />
        </Col>
      </Row>
      {errorLists?.map((error, index) => (
        <ErrorMessage key={index} content={error} />
      ))}
    </React.Fragment>
  );
};

export default CalculateResultTable;
