import {
  CheckCircleOutlined,
  DeleteOutlined,
  HeartFilled,
  HeartOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  type: 'unexpired' | 'expired' | 'ordered';
  item: Response.QuotationEntity;
  handleReaction: () => void;
  handleDelete: () => void;
  handleRequote: () => void;
  handleOrder: () => void;
};

const ItemAction: React.FC<Props> = ({
  type,
  item,
  handleReaction,
  handleDelete,
  handleRequote,
  handleOrder,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Tooltip placement="top" title={t('quotation_page.tooltips.order')}>
        <Button
          type="primary"
          icon={<CheckCircleOutlined />}
          className="item_button"
          disabled={type !== 'unexpired'}
          onClick={handleOrder}
        />
      </Tooltip>
      <Tooltip
        placement="top"
        title={t('quotation_page.tooltips.re_quotation')}
      >
        <Button
          type="primary"
          icon={<SyncOutlined />}
          className="item_button"
          onClick={handleRequote}
        />
      </Tooltip>
      {type !== 'ordered' && (
        <Tooltip placement="top" title={t('quotation_page.tooltips.favorite')}>
          <Button
            type="primary"
            icon={
              item.okinihairyFlg === '1' ? <HeartFilled /> : <HeartOutlined />
            }
            className="item_button"
            onClick={handleReaction}
          />
        </Tooltip>
      )}
      <Tooltip placement="top" title={t('quotation_page.tooltips.delete')}>
        <Button
          type="primary"
          icon={<DeleteOutlined />}
          className="item_button"
          onClick={handleDelete}
        />
      </Tooltip>
    </React.Fragment>
  );
};

export default ItemAction;
