import React from 'react';
import { ElfsightWidget } from 'react-elfsight-widget';

import usePlugin from 'src/queries/usePlugin';

const Faq: React.FC = () => {
  const { queryPlugin } = usePlugin();

  return (
    <div>
      {queryPlugin?.data?.data?.item?.faq && (
        <div className="faq_plugin">
          <ElfsightWidget widgetID={queryPlugin?.data?.data?.item?.faq} />
        </div>
      )}
    </div>
  );
};

export default Faq;
