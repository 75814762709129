import React from 'react';
import color from 'src/styles/themes/color';

type Props = {
  w?: number;
  h?: number;
  fill?: string;
  className?: string;
  onClick?: () => void;
};

const IconSearch: React.FC<Props> = ({
  w = 18,
  h = 18,
  fill = color.grey_3,
  className,
  onClick,
}) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M11.625 9C13.5 9 15 10.5 15 12.375C15 13.035 14.8125 13.6575 14.4825 14.175L16.7925 16.5L15.75 17.5425L13.41 15.24C12.8925 15.5625 12.2775 15.75 11.625 15.75C9.75001 15.75 8.25001 14.25 8.25001 12.375C8.25001 10.5 9.75001 9 11.625 9ZM11.625 10.5C11.1277 10.5 10.6508 10.6975 10.2992 11.0492C9.94755 11.4008 9.75001 11.8777 9.75001 12.375C9.75001 12.8723 9.94755 13.3492 10.2992 13.7008C10.6508 14.0525 11.1277 14.25 11.625 14.25C12.1223 14.25 12.5992 14.0525 12.9508 13.7008C13.3025 13.3492 13.5 12.8723 13.5 12.375C13.5 11.8777 13.3025 11.4008 12.9508 11.0492C12.5992 10.6975 12.1223 10.5 11.625 10.5ZM5.25001 11.25V12.75H6.75001C6.85501 13.9125 7.35001 14.955 8.10751 15.75H3.75001C3.35218 15.75 2.25 15.75 2.25 15.75C2.25 15.75 2.25001 14.6478 2.25001 14.25V2.25C2.25001 2.25 2.91751 2.25 3.75001 2.25H14.25C14.6478 2.25 15.75 2.25 15.75 2.25C15.75 2.25 15.75 3.35218 15.75 3.75V9.7725C15.3108 9.07573 14.702 8.50178 13.9805 8.10434C13.2591 7.7069 12.4487 7.49897 11.625 7.5C10.6725 7.5 9.78001 7.7775 9.03001 8.25H5.25001V9.75H7.50001C7.23001 10.2 7.00501 10.71 6.87751 11.25H5.25001ZM12.75 6.75V5.25H5.25001V6.75H12.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconSearch;
