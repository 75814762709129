import { useEffect, useRef } from 'react';

const useClickOutiside = (handleClick: any) => {
  const targetRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event) => {
    // return when event node is toggle button
    if (
      event.target.nodeName === 'BUTTON' ||
      event.target.nodeName === 'path' ||
      event.target.nodeName === 'svg' ||
      event.target.className === 'ant-select-item-option-content'
    ) {
      return;
    }
    if (targetRef.current && !targetRef.current.contains(event.target)) {
      targetRef && handleClick();
      return event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return targetRef;
};

export default useClickOutiside;
