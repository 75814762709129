import React from 'react';
import { ElfsightWidget } from 'react-elfsight-widget';
import { QrcodeOutlined } from '@ant-design/icons';
import usePlugin from 'src/queries/usePlugin';
import classNames from 'classnames';

const PluginQrCode = () => {
  const { queryPlugin } = usePlugin();
  const dataPlugin = queryPlugin?.data?.data?.item;

  return (
    <div>
      {dataPlugin?.qrCode && (
        <div className="plugin">
          <div className="qr-code">
            <input type="checkbox" id="check" />
            <label
              className={classNames('qr-btn', {
                disableChatBox: !dataPlugin?.chatBot,
              })}
              htmlFor="check"
            >
              <QrcodeOutlined />
            </label>
            <div className="wrapper">
              <div className="qr-code-form">
                <ElfsightWidget widgetID={dataPlugin?.qrCode ?? ''} />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PluginQrCode;
