import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import destination from 'src/repositories/destination';

const useDestination = ({ page, offset, search }: SearchParams.Destination) => {
  const apiPath = '/des-search-pop-up';
  const queryClient = useQueryClient();

  const queryDestinationList = useQuery<
    AxiosResponse<Response.Destination>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPath, { page, offset, search }],
    queryFn: destination.getDestination,
    onSuccess: () => {
      setTimeout(() => {
        queryClient.removeQueries({
          queryKey: [apiPath, { page, offset, search }],
        });
      });
    },
  });

  return { queryDestinationList };
};

export default useDestination;
