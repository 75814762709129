import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import productDetails from 'src/repositories/productDetails';
import { checkAllPropertiesNull } from 'src/utils/helper';

const BASIC_INFO_PATH = '/price/finished-product/information';

const useProductInformation = (
  params,
  isProductFinished,
  setEstimateErrors,
  setStockError,
) => {
  const areAllPropertiesNull = checkAllPropertiesNull(params);

  const { data, error } = useQuery<
    AxiosResponse<Response.ProductFinishedInfo>,
    AxiosError<Response.Error>
  >({
    queryKey: [BASIC_INFO_PATH, params],
    enabled: !!isProductFinished && !areAllPropertiesNull,
    queryFn: productDetails.getBasicInfo,
    onSuccess() {
      setEstimateErrors([]);
      setStockError('');
    },
  });

  if (error) {
    return { productsInfor: [] };
  }
  return { productsInfor: data?.data?.items ?? [] };
};

export default useProductInformation;
