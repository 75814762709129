import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { Button, Col, Divider, Form, Row, Table, Typography } from 'antd';

import { Breadcrumb, Card } from 'src/components/custom';
import { InputField } from 'src/components/form';
import useForm, { FormValue } from './useForm';
import useTable from './useTable';
import { NumberFormatter } from 'src/utils/number';
import history from 'src/libs/history';
import useOrder, { getKey } from 'src/queries/useOrder';
import GetWebMitrResponse = Response.GetWebMitrResponse;
import { Info } from 'src/components/icon';
import { DISPLAY_OF_TOTAL_AMOUNT, STATUS_CODE } from 'src/constants/app';

const OrderContainer: React.FC = () => {
  const [successMessage, setSuccessMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const location = useLocation();
  const state = location?.state as any;
  const webMitrNo: string[] =
    state?.items?.map((item: any) => item.webMitrNo) || [];
  const { t } = useTranslation();

  const { formInfo } = useForm();
  const { control, handleSubmit } = formInfo;
  const { tableProps } = useTable(control, webMitrNo);
  const client = useQueryClient();
  const data = client.getQueryData<AxiosResponse<GetWebMitrResponse>>([
    getKey(webMitrNo),
  ]);
  const selectedItems = data?.data?.item.items || [];

  const handleRequestOrderSuccess = () => {
    setErrorMessage('');
    setSuccessMessage(t('order_page.order_completion'));
  };

  const handleRequestOrderFailed = (error: AxiosError<Response.Error>) => {
    // Reset success message
    setSuccessMessage('');

    const code = error?.response?.data.code;
    if (code) {
      // @ts-ignore
      const message = t(`message.${code}`);
      if (message !== `message.${code}`) {
        setErrorMessage(message as string);
      }
    }
  };
  const { requestOrder } = useOrder(
    webMitrNo,
    handleRequestOrderSuccess,
    handleRequestOrderFailed,
  );

  const numberOfRecord = data?.data?.item.items?.length || 0;
  let totalAmount = 0;
  let totalAmountInCludedTax = 0;
  data?.data?.item.items?.forEach(
    (item) => (
      (totalAmount += item.sogaku), (totalAmountInCludedTax += item.mitrGaku)
    ),
  );
  const maxAmountAllow = data?.data?.item.jogenKingaku;
  const hasError = !!maxAmountAllow && maxAmountAllow < totalAmount;
  const messageCode = data?.data?.item?.messageCode;

  const showPriceIncludingTax =
    data?.data.item.kingakuHyoji === DISPLAY_OF_TOTAL_AMOUNT.TAX_INCLUDED;

  const request = (form: FormValue) => {
    if (hasError || !numberOfRecord) {
      return;
    }
    requestOrder.mutate({
      renraku: form.contact,
      tanto: form.orderPersonName,
      webMitrNo,
      deliveries: form.deliveryDates
        ?.filter((item) => !!item.key)
        .map((item) => ({
          webMitrNo: item.key,
          kiboNoki: item.value,
        })),
    });
  };

  return (
    <div className="min-h-100 mb-30 mt-30 order-page">
      <Breadcrumb />
      <Card title={t('order_page.title')}>
        <Row>
          <Col xs={24} sm={24} md={12} lg={16}>
            <InputField<FormValue>
              controller={{ control, name: 'contact' }}
              inputProps={{
                placeholder: t('order_page.contact'),
                maxLength: 80,
              }}
              label={t('order_page.contact')}
              labelCol={{ xs: 24, sm: 24, md: 4 }}
              wrapperCol={{ xs: 24, sm: 24, md: 16 }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <Row>
              <Col xs={24} md={6} lg={8} className="item-label">
                <Typography.Title level={5}>
                  {t('order_page.order_person_name')}
                </Typography.Title>
              </Col>
              <Col xs={20} sm={21} md={15} lg={13}>
                <InputField<FormValue>
                  controller={{ control, name: 'orderPersonName' }}
                  inputProps={{
                    maxLength: 30,
                    placeholder: t('order_page.order_person_name'),
                  }}
                />
              </Col>
              <Col xs={4} sm={3} md={3} lg={3} className="item-label">
                <Typography.Title level={5} style={{ marginLeft: '0.5em' }}>
                  {t('order_page.person_suffix')}
                </Typography.Title>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      <Row>
        <Col span={24}>
          <Table {...tableProps} className="quotation_table" />
        </Col>
      </Row>

      {messageCode === STATUS_CODE.CURRENCY_TRANSFER && (
        <Typography.Title level={5} className="noti">
          {t('message.W000002')}
        </Typography.Title>
      )}

      <Card title="" className="mt-16">
        <Form onFinish={handleSubmit((val) => request(val))}>
          <Row className="mt-16 mb-16">
            <Col
              xs={24}
              sm={24}
              md={8}
              className="d-flex align-center margin-sm"
            >
              <Row className="d-flex-1">
                <Typography.Title level={3} className="sm-flex-1">
                  {t('order_page.total_record')}
                </Typography.Title>
                <Typography.Title className="mt-0 highlight" level={3}>
                  {numberOfRecord}&nbsp;
                </Typography.Title>
                <Typography.Title className="mt-0" level={3}>
                  {t('order_page.point')}
                </Typography.Title>
              </Row>
            </Col>
            <Divider className="divider" />
            <Col
              xs={24}
              sm={24}
              md={8}
              className="d-flex align-center margin-sm"
            >
              <Row className="d-flex-1">
                <Typography.Title level={3} className="sm-flex-1">
                  {t('order_page.total_price_exclude_tax')}
                </Typography.Title>
                <Typography.Title className="mt-0 highlight" level={3}>
                  {data?.data?.item.currencyUnit
                    ? `${NumberFormatter.format(totalAmount)}(${
                        data?.data?.item.currencyUnit
                      })`
                    : `${NumberFormatter.format(totalAmount)}`}
                </Typography.Title>
              </Row>
            </Col>
            {showPriceIncludingTax && (
              <Col
                xs={24}
                sm={24}
                md={8}
                className="d-flex align-center margin-sm"
              >
                <Row className="d-flex-1">
                  <Typography.Title level={3} className="sm-flex-1">
                    {t('order_page.total_price_include_tax')}
                  </Typography.Title>
                  <Typography.Title className="mt-0 highlight" level={3}>
                    {data?.data?.item.currencyUnit
                      ? `${NumberFormatter.format(totalAmountInCludedTax)}(${
                          data?.data?.item.currencyUnit
                        })`
                      : `${NumberFormatter.format(totalAmountInCludedTax)}`}
                  </Typography.Title>
                </Row>
              </Col>
            )}
            <Divider className="divider" />
            <Col
              xs={24}
              sm={24}
              md={showPriceIncludingTax ? 24 : 8}
              className="margin-sm"
            >
              <Row
                justify={showPriceIncludingTax ? 'end' : 'space-around'}
                gutter={showPriceIncludingTax ? 20 : 0}
                className={showPriceIncludingTax ? 'included_tax' : ''}
              >
                <Col xs={10} md={showPriceIncludingTax ? 5 : 10}>
                  <Button
                    type="default"
                    className="btn-back w-full"
                    onClick={() =>
                      history.push(`/quotation${state?.search || ''}`, {
                        selectedItems,
                      })
                    }
                  >
                    {t('order_page.button.back')}
                  </Button>
                </Col>
                <Col xs={10} md={showPriceIncludingTax ? 5 : 10}>
                  <Button
                    type="primary"
                    className="w-full"
                    htmlType="submit"
                    loading={requestOrder.isLoading}
                    disabled={!webMitrNo.length}
                  >
                    {t('order_page.button.order')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Card>
      {hasError && (
        <Typography.Text
          className="ant-form-item-explain ant-form-item-explain-error"
          type="danger"
          ellipsis={{ tooltip: true }}
        >
          <Info />
          {t('message.E000065')}
        </Typography.Text>
      )}
      {errorMessage && (
        <Typography.Text
          className="ant-form-item-explain ant-form-item-explain-error"
          type="danger"
          ellipsis={{ tooltip: true }}
        >
          <Info />
          {errorMessage}
        </Typography.Text>
      )}
      {successMessage && (
        <Typography.Text
          className="ant-form-item-explain ant-form-item-explain-error"
          type="danger"
          ellipsis={{ tooltip: true }}
        >
          {successMessage}
        </Typography.Text>
      )}
      <div className="notes">
        <Typography.Title level={5}>{t('order_page.notes.1')}</Typography.Title>
      </div>
    </div>
  );
};

export default OrderContainer;
