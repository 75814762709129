import { Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import OrderRowDetail from './OrderRowDetail';
import { BACK_ORDER_PRODUCT, FormValue } from '../useForm';
import WebMitrEntity = Response.WebMitrEntity;
import { getProductName } from 'src/utils/helper';

type OrderRowProps<T extends Response.WebMitrEntity> = {
  item: T;
  control: FormValue;
  index: number;
  data?: Response.WebMitrListResponse;
  taxType?: string;
};

const OrderRow: React.FC<OrderRowProps<WebMitrEntity>> = (
  props: OrderRowProps<WebMitrEntity>,
) => {
  const { item, control, index, data, taxType } = props;
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="row_info">
        <Typography.Text className="row_name">
          {getProductName(item?.productName, false, item?.hakudoShhnm)}
        </Typography.Text>
        <div>
          <Typography.Text className="row_code">
            {t('order_page.product_code', { code: item.hakudoShhnCd })}
          </Typography.Text>
          {item.zaitokKbn === BACK_ORDER_PRODUCT && (
            <Typography.Text className="row_sub">
              {t('order_page.back_order_product')}
            </Typography.Text>
          )}
        </div>
      </div>
      <OrderRowDetail
        item={item}
        control={control}
        index={index}
        data={data}
        taxType={taxType}
      />
    </React.Fragment>
  );
};

export default OrderRow;
