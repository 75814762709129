import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'src/libs/axios';

const createGuest = (url, data: Payload.TGuest) => {
  return axios({
    method: 'put',
    url,
    data: {
      mail: data.mail,
      smei: data.smei,
      webMitrNo: data.webMitrNo,
    },
  });
};

const dowloadPDFQoutation = (url, data: Payload.TQuotationPDF) => {
  return axios({
    method: 'post',
    url,
    data: {
      gengoCd: data.gengoCd,
      seqnos: data.seqnos,
    },
    responseType: 'blob',
  });
};

const getIndustry = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;

  return axios({
    method: 'get',
    url,
  });
};

const getRegion = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;

  return axios({
    method: 'get',
    url,
  });
};

const getInfo = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;

  return axios({
    method: 'get',
    url,
  });
};

const updateIndustryOrRegion = (url, data: Payload.UpdateIndustryOrRegion) => {
  return axios({
    method: 'put',
    url,
    data: {
      seqno: data.seqno,
      gyoushuId: data?.gyoushuId,
      shozaichiId: data?.shozaichiId,
    },
  });
};

export default {
  createGuest,
  dowloadPDFQoutation,
  getIndustry,
  getRegion,
  getInfo,
  updateIndustryOrRegion,
};
