import React from 'react';
import { BackTop } from 'antd';
import { CaretUpOutlined } from '@ant-design/icons';

const BackToTop: React.FC = () => {
  return (
    <BackTop className="back_top">
      <div className="back_top_content">
        <CaretUpOutlined />
      </div>
    </BackTop>
  );
};

export default BackToTop;
