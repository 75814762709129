import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm as useHookForm } from 'react-hook-form';

import { t } from 'src/libs/i18n';

interface KeyValuePair<K, V> {
  key: K;
  value: V;
}
export type FormValue = {
  contact: string;
  orderPersonName: string;
  deliveryDates: KeyValuePair<string, string>[];
};

export const BACK_ORDER_PRODUCT = '2';

const useForm = () => {
  const schema = Yup.object().shape({
    contact: Yup.string(),
    orderPersonName: Yup.string(),
    deliveryDates: Yup.array(
      Yup.object({
        key: Yup.string(),
        value: Yup.string().when('key', (key, field) =>
          key
            ? field.required(
                t('message.E000007', {
                  0: t('order_page.delivery_date'),
                }),
              )
            : field,
        ),
      }),
    ),
  });
  const formInfo = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  return { formInfo };
};

export default useForm;
