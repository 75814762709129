import { Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  type: 'unexpired' | 'expired' | 'ordered';
  name: string;
  code?: string;
  sub?: boolean;
};

const ItemInfo: React.FC<Props> = ({ type, name, code, sub }) => {
  const { t } = useTranslation();
  return (
    <Row className="row item-info">
      <Col xs={0} md={1} className="item_divider-vertical">
        <Divider type="vertical" />
      </Col>
      <Col>
        <Col span={24}>
          <Typography.Text className="item_label_name">{name}</Typography.Text>
        </Col>
        {type !== 'expired' && (
          <Col span={24}>
            <Typography.Text className="item_label_code">
              {t('quotation_page.product_code', {
                code: code,
              })}
            </Typography.Text>
            <Typography.Text className="item_label_sub">
              {sub && t('quotation_page.in_stock_now')}
            </Typography.Text>
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default ItemInfo;
