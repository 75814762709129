import React from 'react';

import AccountContainer from 'src/containers/Account';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const AccountPage: React.FC = () => {
  useTitle(t('account.page_title'));
  return <AccountContainer />;
};

export const PATH = '/account' as const;
export default AccountPage;
