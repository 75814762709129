import React from 'react';
import Slider from 'react-slick';
import { Typography } from 'antd';
import _ from 'lodash';

import useSlides from 'src/queries/useSlides';
import { getProductName, handleRedirectProductDetail } from 'src/utils/helper';
import NoImage from 'src/assets/images/no-image.jpg';
import { PATH_2 } from 'src/pages/productDetails';

const ProductSlides: React.FC = () => {
  const { getSlides } = useSlides();

  const data = getSlides?.data?.data?.items;

  const settings = {
    infinite: data && _.size(data) > 4,
    speed: 400,
    slidesToShow: 4,
    swipeToSlide: true,
  };

  return (
    <div className="product_slides">
      {_.size(data) > 0 && (
        <Slider {...settings}>
          {data?.map((item, index) => (
            <div key={index} className="slide">
              <img
                onClick={() =>
                  handleRedirectProductDetail(item, item?.hinshL3Cd, PATH_2)
                }
                src={item?.shhnImageDir || item?.hakudoShhnImageDir || NoImage}
              />
              <Typography.Text
                onClick={() =>
                  handleRedirectProductDetail(item, item?.hinshL3Cd, PATH_2)
                }
              >
                {getProductName(item, false, item?.shhnm) ||
                  getProductName(item, true, item?.hakudoShhnm)}
              </Typography.Text>
            </div>
          ))}
        </Slider>
      )}
    </div>
  );
};

export default ProductSlides;
