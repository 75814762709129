const PRODUCTS = '/product/list/by-category';
const MACHINING_METHOD = '/price/manufacturing/methods';
const MANUFACTURING_SIZES = '/price/manufacturing/sizes';
const MANUFACTURING_SIZES_Z0 = '/price/manufacturing/sizes/z0';
const MANUFACTURING_SIZES_2_AND_SIZE_3 = '/price/manufacturing/sizes/import';
const ESTIMATE_PRICE = '/price/estimate';
const ESTIMATE_PRICE_GUEST = '/price/guest/estimate';
const ESTIMATE_DETAIL = '/price';
const CONVERT_SIZE = '/price/manufacturing/sizes/convert';
const TAXES = '/price/taxes';
const PRODUCT_DETAIL = '/product-detail/init-data';
const TOLERANCE = 'price/tolerance';

export const API_KEY = {
  PRODUCTS,
  MACHINING_METHOD,
  MANUFACTURING_SIZES,
  ESTIMATE_PRICE,
  ESTIMATE_PRICE_GUEST,
  ESTIMATE_DETAIL,
  MANUFACTURING_SIZES_Z0,
  MANUFACTURING_SIZES_2_AND_SIZE_3,
  CONVERT_SIZE,
  TAXES,
  PRODUCT_DETAIL,
  TOLERANCE,
};
