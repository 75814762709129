import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import useQueryUrl from 'src/hooks/useQueryUrl';
import productDetails from 'src/repositories/productDetails';

const BASIC_INFO_PATH = '/product-detail/basic-info';
const DETAIL_INFO_PATH = '/product-detail/detail-info';
const COLUMN_NAME_MULTI_LANG_PATH = '/product-detail/column-name-multi-lang';

const useProductDetails = () => {
  const query = useQueryUrl();

  const params = {
    hinshL3Cd: query?.hinshL3Cd,
    shhnCd: query?.shhnCd,
    hakudoShhnCd: query?.hakudoShhnCd,
    api1ShhnCd: query?.api1ShhnCd,
    api2ShhnCd: query?.api2ShhnCd,
  };

  const getBasicInfo = useQuery<
    AxiosResponse<Response.ProductBasicInfo>,
    AxiosError<Response.Error>
  >({
    queryKey: [BASIC_INFO_PATH, params],
    queryFn: productDetails.getBasicInfo,
  });

  const getDetailInfo = useQuery<
    AxiosResponse<Response.ProductDetailInfo>,
    AxiosError<Response.Error>
  >({
    queryKey: [DETAIL_INFO_PATH, params],
    queryFn: productDetails.getDetailInfo,
  });

  const getColumnName = useQuery<
    AxiosResponse<Response.ColumnName>,
    AxiosError<Response.Error>
  >({
    queryKey: [COLUMN_NAME_MULTI_LANG_PATH],
    queryFn: productDetails.getColumnName,
  });

  return { getBasicInfo, getDetailInfo, getColumnName };
};

export default useProductDetails;
