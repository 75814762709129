import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { Dispatch, SetStateAction } from 'react';
import { UseFormSetError } from 'react-hook-form';

import useQueryUrl from 'src/hooks/useQueryUrl';
import { PER, STATUS_CODE, STATUS_FIELD } from 'src/constants/app';
import quotation from 'src/repositories/quotation';
import { FormValue } from 'src/containers/Quotation/Search/useForm';
import { createFileFromString } from 'src/utils/helper';

const apiPath = '/order-histories';
const pdfPath = '/order-histories/pdf';
const csvPath = '/order-histories/csv';
const orderStatusPath = '/order-histories/order-status';

type UseQuotation = {
  setError: UseFormSetError<FormValue>;
  setRowKeysSelected?: Dispatch<SetStateAction<React.Key[]>>;
  setErrors?: React.Dispatch<React.SetStateAction<string[]>>;
};

const useQuotation = ({
  setError,
  setRowKeysSelected,
  setErrors,
}: UseQuotation) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const query = useQueryUrl<SearchParams.Quotation>();
  const params = {
    webMitrTrokubStart: query.registration_date_from?.concat(' 00:00:00'),
    webMitrTrokubEnd: query.registration_date_to?.concat(' 23:59:59'),
    webMitrNoFrom:
      query.quotation_number_from && 'W'.concat(query.quotation_number_from),
    webMitrNoTo:
      query.quotation_number_to && 'W'.concat(query.quotation_number_to),
    kokyakum: query.delivery_name,
    orderType: query.express_condition,
    tantoMei: query.person_in_charge,
    kyaksChmnNo: query.order_number,
    page: query.page || 1,
    offset: query.offset || PER.DEFAULT,
    shiagsnpoHyojiMojirets: query.finished_dimensions,
    hakudoShhnm: query.product,
    sortColumn: 'webMitrNo',
    sortType: query.sort_condition || '0',
    sttCd: query.status,
  };

  const queryQuotation = useQuery<
    AxiosResponse<Response.Quotation>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPath, params],
    queryFn: quotation.getQuotation,
    retry: 0,
    onError(err) {
      const errorCode = _.get(err, 'response.data.code');
      const errorField = _.get(err, 'response.data.field');
      switch (true) {
        case errorCode === STATUS_CODE.WRONG_ORDER_NUMBER:
          return setError('order_number', {
            type: 'custom',
            message: t('message.E000024'),
          });
        case errorCode === STATUS_CODE.WRONG_INPUT_MODAL &&
          errorField === STATUS_FIELD.PRODUCT_NAME:
          return setError('product', {
            type: 'custom',
            message: t('message.E000007', {
              0: t('quotation_page.product_name'),
            }),
          });
        case errorCode === STATUS_CODE.WRONG_INPUT_MODAL &&
          errorField === STATUS_FIELD.DELIVERY_NAME:
          return setError('delivery_name', {
            type: 'custom',
            message: t('message.E000007', {
              0: t('quotation_page.delivery_name'),
            }),
          });
      }
    },
  });

  const reactionHistory = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>
  >({
    mutationKey: [apiPath],
    mutationFn: (args) => {
      return quotation.reactionHistory(`${apiPath}/${args}`);
    },
    onSuccess: () => {
      queryClient.removeQueries([apiPath]);
      setRowKeysSelected?.([]);
    },
    onError: (error) => {
      console.log(error.response);
    },
  });

  const deleteHistory = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.DeleteHistory
  >({
    mutationKey: [apiPath],
    mutationFn: (args) => {
      return quotation.deleteHistory(apiPath, args);
    },
    onSuccess: (data) => {
      queryClient.removeQueries([apiPath]);
      setRowKeysSelected?.([]);
      if (data?.data?.length > 0) {
        setErrors?.([
          t('message.E000089', {
            0: t('quotation_page.ordered_item'),
          }),
        ]);
      }
    },
    onError: (error) => {
      console.log(error.response);
    },
  });

  const exportPDF = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.ExportPDF
  >({
    mutationKey: [pdfPath],
    mutationFn: (args) => {
      return quotation.exportPDF(pdfPath, args);
    },
    onSuccess: (data) => {
      createFileFromString(data.data, 'Quotation.pdf');
      setRowKeysSelected?.([]);
    },
    onError: (error) => {
      console.log(error.response);
    },
  });

  const exportCSV = useMutation<
    AxiosResponse<any>,
    AxiosError<Response.Error>,
    Payload.ExportCSV
  >({
    mutationKey: [csvPath],
    mutationFn: (args) => {
      return quotation.exportCSV(csvPath, args);
    },
    onSuccess: (data) => {
      createFileFromString(data.data, 'Quotation.csv');
      setRowKeysSelected?.([]);
    },
    onError: (error) => {
      console.log(error.response);
    },
  });

  const queryOrderStatus = useQuery<
    AxiosResponse<Response.OrderStatus>,
    AxiosError<Response.Error>
  >({
    queryKey: [orderStatusPath],
    queryFn: quotation.getOrderStatus,
    retry: 0,
    onError(error) {
      console.log(error.response);
    },
  });

  const dataOrderStatus = queryOrderStatus?.data?.data?.items?.map((item) => ({
    label: item?.sttm,
    value: item?.sttCd,
  }));

  return {
    queryQuotation,
    reactionHistory,
    deleteHistory,
    exportPDF,
    exportCSV,
    dataOrderStatus,
  };
};

export default useQuotation;
