import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'src/libs/i18n';

export type FormValue = {
  otokesakiCd: string;
};

const useForm = () => {
  const schema = Yup.object().shape({
    otokesakiCd: Yup.string().required(
      t('message.E000031', { param1: t('account.delivery_name') }),
    ),
  });

  const formInfo = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'onChange',
  });
  return { ...formInfo };
};

export default useForm;
