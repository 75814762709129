import React, { useEffect, useState } from 'react';

import RestrictLayout from 'src/components/applications/RestrictLayout';
import LocalStorage from 'src/utils/LocalStorage';
import Layout from 'src/components/layout';

type Props = {
  requireNotAuth?: boolean;
  title?: string;
};

const CommonLayout: React.FC<Props> = ({ requireNotAuth, title }) => {
  const [accessToken, setAccessToken] = useState(LocalStorage.accessToken);
  useEffect(() => {
    setAccessToken(LocalStorage.accessToken);
  }, [LocalStorage.accessToken]);
  return accessToken ? (
    <Layout />
  ) : (
    <RestrictLayout title={title} requireNotAuth={requireNotAuth} />
  );
};

export default CommonLayout;
