import React from 'react';
import { Menu, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import logout from 'src/utils/logout';
import LangSelect from 'src/components/layout/TopNav/LangSelect';
import { PATH as PATH_ACCOUNT } from 'src/pages/account';
import useMenu from 'src/hooks/useMenu';
import useClickOutiside from 'src/hooks/useClickOutiside';

type Props = {
  toggleMenuMobile?: (data: boolean) => void;
};

const AppMenuMobile: React.FC<Props> = ({ toggleMenuMobile }) => {
  const { t } = useTranslation();
  const { menuItems, getSelectedKey } = useMenu('mobile');

  const menuRef = useClickOutiside(() => {
    toggleMenuMobile && toggleMenuMobile(false);
  });

  return (
    <div className={'menu_mobile'} ref={menuRef}>
      <div className={'top-menu'}>
        <Menu
          style={{ width: 256 }}
          defaultSelectedKeys={getSelectedKey()}
          mode="inline"
          items={menuItems}
          onClick={() => toggleMenuMobile && toggleMenuMobile(false)}
        />
      </div>
      <div className="lang-select-mobile">
        <LangSelect />
      </div>
      <div className={'menu-mobile-account'}>
        <div
          className={'account-menu-container'}
          onClick={() => toggleMenuMobile && toggleMenuMobile(false)}
        >
          <Row align="middle" justify="center">
            <Col span={12}>
              <Link to={PATH_ACCOUNT}>
                <div className="account-menu-item">
                  <UserOutlined />
                  <p className="account-menu-item-name">
                    {t('menu_home.account')}
                  </p>
                </div>
              </Link>
            </Col>
            <Col span={12}>
              <div className="account-menu-item" onClick={() => logout()}>
                <LogoutOutlined />
                <p className="account-menu-item-name">
                  {t('menu_home.logout')}
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AppMenuMobile;
