import React from 'react';

import HomeContainer from 'src/containers/Home';
import { t } from 'src/libs/i18n';
import useTitle from 'src/hooks/useTitle';

const HomePage: React.FC = () => {
  useTitle(t('top_page.page_title'));
  return <HomeContainer />;
};

export const PATH = '/' as const;
export default HomePage;
