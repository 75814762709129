import React from 'react';

import CategorySearchContainer from 'src/containers/Category';
import { withRemountOnHistoryPush } from 'src/hocs/withRemountOnHistoryPush';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const CategorySearch: React.FC = () => {
  useTitle(t('category.product_category'));
  return <CategorySearchContainer />;
};

export const PATH = '/category' as const;
export default withRemountOnHistoryPush(CategorySearch);
