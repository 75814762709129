import { Col, Row, Typography } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import classNames from 'classnames';
import dayjs from 'dayjs';
import _ from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { TaxDetail } from 'src/components/common';
import WebMitrDetail from 'src/components/common/WebMitrDetail';
import WeightUnitPrice from 'src/components/common/WebMitrDetail/WeightUnitPrice';
import { DISPLAY_OF_TOTAL_AMOUNT, SIMPLE_DATE_FORMAT } from 'src/constants/app';
import { ProductType } from 'src/enums/quotation';
import {
  checkBackOrderProductAndNoukiDisplay,
  getDeliveryDateExternal,
} from 'src/utils/helper';

type Props = {
  type: 'unexpired' | 'expired' | 'ordered';
  onChange?: (e: CheckboxChangeEvent) => void;
  item: Response.QuotationEntity;
};

const ItemDetail: React.FC<Props> = (props) => {
  const { type, item } = props;
  const { t } = useTranslation();

  const showPriceIncludingTax =
    item.kingakuHyoji === DISPLAY_OF_TOTAL_AMOUNT.TAX_INCLUDED;

  return (
    <div className={type === 'expired' ? 'item item_expired' : 'item'}>
      <Row justify="end">
        <Col xs={24} md={24}>
          <div className="item_data">
            <WebMitrDetail item={item} type={type} />
            <Row className="mb-10 item_data_order">
              <Col
                xs={24}
                md={showPriceIncludingTax ? 8 : 12}
                className="item_data_order_delivery"
              >
                <Row>
                  <Col xs={8} md={showPriceIncludingTax ? 12 : 10}>
                    <Typography.Title level={5}>
                      {t('quotation_page.delivery_dates')}
                    </Typography.Title>
                  </Col>
                  <Col
                    xs={16}
                    sm={19}
                    md={checkBackOrderProductAndNoukiDisplay(item) ? 24 : 12}
                    xl={
                      checkBackOrderProductAndNoukiDisplay(item)
                        ? showPriceIncludingTax
                          ? 24
                          : 12
                        : 12
                    }
                    className="item_data_order_date"
                  >
                    <Typography.Text
                      className={classNames('date_to_deliver', {
                        item_expired_date: type === 'expired',
                      })}
                    >
                      {checkBackOrderProductAndNoukiDisplay(item)
                        ? t('quotation_page.date_1', {
                            0: getDeliveryDateExternal(item?.noukiDisplay)
                              ?.byShip,
                          })
                        : item?.noki?.slice(0, 10) ||
                          (item?.noukiDisplay
                            ? dayjs(item?.noukiDisplay).format(
                                SIMPLE_DATE_FORMAT,
                              )
                            : '')}
                    </Typography.Text>
                    {checkBackOrderProductAndNoukiDisplay(item) && (
                      <Typography.Text
                        className={`date_to_deliver ${
                          type === 'expired' && 'item_expired_date'
                        }`}
                      >
                        {t('quotation_page.date_2', {
                          0: getDeliveryDateExternal(item?.noukiDisplay)
                            ?.byAirplane,
                        })}
                      </Typography.Text>
                    )}
                  </Col>
                  {item?.zaitokKbn !== ProductType.BACK_ORDER_PRODUCT && (
                    <Col span={24}>
                      <Typography.Text className="item_data_order_note">
                        {`(${item.webMitrTrokub?.slice(0, 10)} ${
                          item?.eigyouJikanTo ? item?.eigyouJikanTo : ''
                        } ${t('quotation_page.when_ordering_by')})`}
                      </Typography.Text>
                    </Col>
                  )}
                </Row>
              </Col>
              <WeightUnitPrice
                item={item}
                type={type}
                showPriceIncludingTax={showPriceIncludingTax}
              />
            </Row>
            {_.size(item?.mitsumoriTaxResList) > 0 && <TaxDetail item={item} />}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ItemDetail;
