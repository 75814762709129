import { Col, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { RowDetail } from 'src/components/common';

type Props = {
  record: Response.EstimatePrice['item'];
};

const ColumnRender: React.FC<Props> = ({ record }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row className="header_row">
        <Col span={16}>
          <Row className="mb-10 table_item_row">
            <Col span={4} className="col_with_border">
              <Typography.Title level={5}>
                {t('pricing_page.estimate_results.estimate_number')}
              </Typography.Title>
            </Col>
            <Col span={4} className="col_with_border">
              <Typography.Text>{record?.webMitrNo}</Typography.Text>
            </Col>
            <Col span={4} className="col_with_border">
              <Typography.Title level={5}>
                {t('pricing_page.estimate_results.registration_date')}
              </Typography.Title>
            </Col>
            <Col span={4} className="col_with_border">
              <Typography.Text>{record?.webMitrTrokub}</Typography.Text>
            </Col>
            <Col span={4} className="col_with_border">
              <Typography.Title level={5}>
                {t('pricing_page.estimate_results.expiration_date')}
              </Typography.Title>
            </Col>
            <Col span={4} className="col_with_border">
              <Typography.Text>{record?.mitrYukoKigenb}</Typography.Text>
            </Col>
          </Row>
        </Col>
        <Col span={8}>
          <RowDetail
            title={t('pricing_page.estimate_results.product_number')}
            value={record?.inzu}
            layout={{
              titleCol: { span: 9 },
              valueCol: { span: 15 },
            }}
            rowClassName="table_item_row row_fit"
            titleClassName="col_with_border col_border_left_phone"
            valueClassName="col_with_border"
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ColumnRender;
