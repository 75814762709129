import { AxiosError, AxiosResponse } from 'axios';
import { FieldPathValue } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import useQueryUrl from 'src/hooks/useQueryUrl';
import category from 'src/repositories/category';

const apiCategoryKey = '/common/category/list';
const apiProductKey = '/product/list/by-category';

const useCategorySearch = (watch?: FieldPathValue<any, any>) => {
  const params = useQueryUrl<SearchParams.Category>();

  const { data, ...rest } = useQuery<
    AxiosResponse<Response.Category>,
    AxiosError<any>
  >([apiCategoryKey, params], category.getVariety, {
    retry: 0,
    onError(err) {
      console.log(err.response);
    },
  });

  const paramsSearch = useQueryUrl<SearchParams.CategorySearch>();

  const queryProductList = useQuery<
    AxiosResponse<Response.ProductList>,
    AxiosError<any>
  >([apiProductKey, paramsSearch], category.getProductList, {
    retry: 0,
    onError(err) {
      console.log(err.response);
    },
  });

  const categories = data?.data?.items;
  const fetchCategories = categories?.map((item) => {
    return {
      label: item.categoryName,
      value: item.categoryId,
    };
  });

  const dataCategories = _.orderBy(fetchCategories, ['value'], ['asc']);

  const subCategoryById = (id) => {
    return categories?.find((item) => {
      return item.categoryId === id;
    });
  };

  const subCategories = subCategoryById(watch)?.childrenList;
  const dataSubCategories = subCategories?.map((item) => ({
    label: item.categoryName,
    value: item.categoryId,
  }));

  return {
    categories,
    dataCategories,
    dataSubCategories,
    ...rest,
    queryProductList,
  };
};

export default useCategorySearch;
