import React from 'react';
import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { UnorderedListOutlined } from '@ant-design/icons';
import qs from 'query-string';

import SectionTitle from 'src/components/layout/SectionTitle';
import useProductCategory from 'src/queries/useProductCategory';
import history from 'src/libs/history';
import { PATH } from 'src/pages/category';
import { SHAPE_TYPE } from 'src/constants/quotation/optionSelect';
import { RECOMMEND_SEARCH_KEYWORD } from 'src/constants/app';

const CategoryList: React.FC = () => {
  const { t } = useTranslation();
  const { categories } = useProductCategory();

  const onSelectCategory = (category) => {
    const shape = categories
      ?.find((item) => item === category)
      ?.childrenList.map((item) => {
        return item?.categoryId;
      });

    history.push({
      pathname: PATH,
      search: qs.stringify({
        hinshL3Cd: category?.categoryId,
        keijoL3Cd: shape?.[0] || SHAPE_TYPE.plate,
      }),
    });
  };

  const onSelectKeyword = (keyword) => {
    history.push({
      pathname: PATH,
      search: qs.stringify({ hanyomRan: keyword }),
    });
  };

  return (
    <React.Fragment>
      <SectionTitle
        title={t('top_page.products.title')}
        icon={<UnorderedListOutlined />}
      />
      <Row className="product_section">
        <Col span={24} className="product_section_item">
          <Typography.Text className="products_title">
            {t('top_page.products.products_name')}
          </Typography.Text>
          <div className="products">
            {categories?.map((item) => (
              <span
                onClick={() => onSelectCategory(item)}
                key={item.categoryId}
              >
                {item.categoryName}
              </span>
            ))}
          </div>
        </Col>
        <Col span={24} className="product_section_item">
          <Typography.Text className="products_title">
            {t('top_page.recommended_search')}
          </Typography.Text>
          <div className="products">
            {RECOMMEND_SEARCH_KEYWORD.map((item, index) => (
              <span onClick={() => onSelectKeyword(item)} key={index}>
                {item}
              </span>
            ))}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CategoryList;
