import { Button, Col, Divider, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';
import _ from 'lodash';

import { Card } from 'src/components/custom';
import { InputField, RadioGroupField } from 'src/components/form';
import useCategorySearch from 'src/queries/useProductCategorySearch';
import useProductVariety from 'src/queries/useProductVariety';
import useForm, { FormValue } from './useForm';
import history from 'src/libs/history';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { checkIsProductFinished } from 'src/utils/helper';

const Search = () => {
  const { t } = useTranslation();
  const { control, getValues, setValue, watch } = useForm();
  const { dataCategories, dataSubCategories } = useCategorySearch(
    watch('variety'),
  );

  const query = useQueryUrl();
  const { hinshL3Cd, keijoL3Cd, hanyomRan, search } = query;
  const findLabel = dataCategories.find((item) => item.label === hanyomRan);
  const findSubLabel = dataSubCategories?.find(
    (item) => item.label === hanyomRan,
  );

  const [keyword, setKeyword] = useState();

  const { productVariety } = useProductVariety(keyword);

  useEffect(() => {
    if (dataSubCategories) {
      // fill shape search when hanyomRan matching or check default first value
      if (findSubLabel?.label) {
        setValue('shape', findSubLabel?.value);
      } else {
        const findSubCategory = dataSubCategories?.find(
          (item) => item.value === keijoL3Cd,
        );
        if (findSubCategory && dataSubCategories?.includes(findSubCategory)) {
          setValue('shape', findSubCategory.value);
        } else {
          setValue('shape', _.get(dataSubCategories, '[0].value'));
        }
      }
    }
  }, [findSubLabel?.label, _.get(dataSubCategories, '[0].value'), keijoL3Cd]);

  useEffect(() => {
    // fill variety search when hanyomRan matching or check default first value
    if (findLabel?.label) {
      setValue('variety', findLabel?.value);
    } else {
      setValue('variety', hinshL3Cd || _.get(dataCategories, '[0].value'));
    }
  }, [findLabel?.label, _.get(dataCategories, '[0].value')]);

  useEffect(() => {
    // set keyword value when not matching with variety and shape
    if (!findLabel?.label && !findSubLabel?.label) {
      setValue('hanyomRan', hanyomRan);
      // set keyword to call api get variety and shape
      setKeyword(hanyomRan);
    } else {
      setValue('hanyomRan', '');
      // auto search product when matching with variety and shape
      _onSearch();
    }
  }, [findLabel?.label, findSubLabel?.label, hanyomRan]);

  useEffect(() => {
    // auto fill variety and shape of product name
    if (hanyomRan && !search && productVariety?.hinshL3Cd) {
      setValue('variety', productVariety?.hinshL3Cd);
      setValue('shape', productVariety?.keijoL3Cd);
      _onSearch();
    }
  }, [productVariety]);

  const _onSearch = () => {
    history.push({
      search: qs.stringify(
        {
          hinshL3Cd: getValues().variety,
          keijoL3Cd: getValues().shape,
          hanyomRan: getValues().hanyomRan,
          search: true,
        },
        {
          skipNull: true,
          skipEmptyString: true,
        },
      ),
    });
  };

  return (
    <Card title={t('category.product_category')}>
      <Row className="mb-20 category_block_infor_row">
        <Col xs={4} sm={6} md={5} lg={4} xl={2}>
          <Typography.Text className="category_label">
            {t('category.variety')}
          </Typography.Text>
        </Col>
        <Col xs={20} sm={18} md={19} lg={20} xl={22}>
          <RadioGroupField
            className="radio_mt"
            radioGroupProps={{
              options: dataCategories,
            }}
            controller={{
              name: 'variety',
              control,
              defaultValue: hinshL3Cd,
            }}
          />
        </Col>
      </Row>
      <Row align="middle" className="mb-20 category_block_infor_row">
        <Col xs={4} sm={6} md={5} lg={4} xl={2}>
          <Typography.Text className="category_label label_shape">
            {checkIsProductFinished(watch('variety'))
              ? t('pricing_page.category_field.type')
              : t('category.shape')}
          </Typography.Text>
        </Col>
        <Col xs={20} sm={18} md={19} lg={20} xl={22}>
          <RadioGroupField
            className="category_shape radio_mt"
            radioGroupProps={{
              options: dataSubCategories,
            }}
            controller={{
              name: 'shape',
              control,
            }}
          />
        </Col>
      </Row>

      <Divider className="mg-vertical-20" />

      <Row className="category_block_infor_row">
        <Col span={24}>
          <InputField<FormValue>
            label={t('category.free_word')}
            labelCol={{ xs: 10, sm: 6, md: 5, lg: 4, xl: 2 }}
            wrapperCol={{ xs: 24, sm: 18, md: 19, lg: 20, xl: 14 }}
            controller={{
              control,
              name: 'hanyomRan',
              defaultValue: query?.hanyomRan,
            }}
            inputProps={{
              className: 'category_input',
              maxLength: 80,
            }}
          />
        </Col>
      </Row>
      <Divider className="mg-vertical-20" />
      <Row className="mb-10" align="middle" justify="center">
        <Button className="px-60" type="primary" onClick={_onSearch}>
          {t('category.button_search')}
        </Button>
      </Row>
    </Card>
  );
};

export default Search;
