import React from 'react';

import { PATH } from 'src/pages/category';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';
import { SCREENS } from 'src/constants/screens';

const CategorySearch: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/category')),
        title: 'category.product_category',
        screenId: SCREENS.R1012,
      },
    ],
  },
];

export default CategorySearch;
