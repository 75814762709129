import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { LoadingOutlined } from '@ant-design/icons';
import qs from 'query-string';
import { TableProps } from 'antd';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import useTableCommon from 'src/hooks/useTable';
import ItemLabel from './ItemLabel';
import ItemInfo from './ItemInfo';
import ItemAction from './ItemAction';
import ExpandedRowRender from './ExpandedRowRender';
import QuotationAction from './QuotationAction';
import useQuotation from 'src/queries/useQuotation';
import { ColumnsType } from 'antd/lib/table';
import history from 'src/libs/history';
import {
  checkExpired,
  checkOrdered,
  getProductName,
  getTypeQuotation,
} from 'src/utils/helper';
import { PATH as PATH_ORDER } from 'src/pages/order';
import { PATH as PATH_PRICING } from 'src/pages/pricing';
import { ProductType } from 'src/enums/quotation';
import LocalStorage from 'src/utils/LocalStorage';
import useForm from './Search/useForm';

import quotation from 'src/repositories/quotation';
import useQueryUrl from 'src/hooks/useQueryUrl';

const useTable = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { setError } = useForm();
  const state = location?.state as any;
  const client = useQueryClient();
  const query = useQueryUrl();

  const apiPathCurrentCurrencyUnit = '/currency/current-unit';
  const apiPathCurrencyTransfers = '/currency/transfers';

  const {
    pagination: paginationCommon,
    rowSelection,
    rowSelected,
    setRowKeysSelected,
    ...rest
  } = useTableCommon({
    hasSelect: true,
    displayTotal: false,
    maxPageSize: 500,
  });

  const [errors, setErrors] = useState<string[]>([]);
  const [seqnoSelected, setSeqnoSelected] = useState<number[]>([]);
  const [itemSelected, setItemSelected] = useState<Response.WebMitrEntity[]>(
    [],
  );
  const [checkStateOrder, setCheckStateOrder] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);

  const {
    queryQuotation,
    reactionHistory,
    deleteHistory,
    exportPDF,
    exportCSV,
  } = useQuotation({ setRowKeysSelected, setError, setErrors });

  const checkCurrencyTransfer = async (record) => {
    const currentCurrencyRes = await client.fetchQuery<
      AxiosResponse<Response.CurrentCurrencyUnit>
    >({
      queryKey: [apiPathCurrentCurrencyUnit],
      queryFn: quotation.getCurrentCurrencyUnit,
    });

    const currencyTransfersRes = await client.fetchQuery<
      AxiosResponse<Response.CurrencyTransfers>
    >({
      queryKey: [apiPathCurrencyTransfers],
      queryFn: quotation.getCurrencyTransfers,
    });

    const currentCurrency = currentCurrencyRes?.data?.item;
    const currencyTransfers = currencyTransfersRes?.data?.items;

    const currency = record.map((item) => item.sogakuTani);
    if (currentCurrency && currencyTransfers) {
      const currentCurrencyTransfers = currencyTransfers
        ?.filter((item) => item.kawaseKbCdTo === currentCurrency?.currentKawase)
        ?.map((currency) => currency.kawaseKbCdFromSymbol)
        ?.concat(currentCurrency.currentKawaseSymbol);
      return _.every(currency, (item) =>
        currentCurrencyTransfers?.includes(item),
      );
    }
  };

  const handleReaction = async (id) => {
    await reactionHistory.mutate(id);
  };

  const handleDelete = async (item) => {
    if (getTypeQuotation(item) === 'ordered') {
      setErrors([
        t('message.E000089', {
          0: t('quotation_page.ordered_item'),
        }),
      ]);
    } else {
      await deleteHistory.mutate({
        seqnos: [item?.seqno],
      });
    }
  };

  const handleDeleteRow = async () => {
    setErrors([]);
    if (rowSelected.length > 0) {
      const checkAllItemsAreOrder = itemSelected?.every(
        (item) => getTypeQuotation(item) === 'ordered',
      );
      if (checkAllItemsAreOrder) {
        setErrors([
          t('message.E000089', {
            0: t('quotation_page.ordered_item'),
          }),
        ]);
      } else {
        await deleteHistory.mutate({
          seqnos: rowSelected,
        });
      }
    } else {
      setErrors([
        t('message.E000021', {
          param: t('quotation_page.detail'),
        }),
      ]);
    }
  };

  const handleExportPDF = async () => {
    const newErrors: string[] = [];
    if (rowSelected.length > 0) {
      if (await checkCurrencyTransfer(itemSelected)) {
        await exportPDF.mutate({
          seqnos: rowSelected,
          gengoCd: LocalStorage.lang,
        });
      } else {
        newErrors.push(t('message.E000024'));
      }
    } else {
      newErrors.push(
        t('message.E000021', {
          param: t('quotation_page.detail'),
        }),
      );
    }
    setErrors(newErrors);
  };

  const handleExportCSV = async () => {
    const newErrors: string[] = [];
    if (rowSelected.length > 0) {
      await exportCSV.mutate({
        seqnos: rowSelected,
        direction: query.sort_condition || 'desc',
        gengoCd: LocalStorage.lang,
      });
    } else {
      newErrors.push(
        t('message.E000021', {
          param: t('quotation_page.detail'),
        }),
      );
    }
    setErrors(newErrors);
  };

  const handleRequote = (record) => {
    history.push({
      pathname: PATH_PRICING,
      search: qs.stringify({ webMitrNo: record.webMitrNo }),
    });
  };

  const handleOrder = async (record) => {
    const newErrors: string[] = [];
    if (await checkCurrencyTransfer([record])) {
      history.push(PATH_ORDER, {
        items: [record],
        search: location.search,
      });
    } else {
      newErrors.push(t('message.E000024'));
    }
    setErrors(newErrors);
  };

  useEffect(() => {
    if (state?.selectedItems && checkStateOrder === false) {
      // save state from order page
      const selectedSeqnos = state?.selectedItems.map((i) => i.seqno);
      setSeqnoSelected(selectedSeqnos);
      setRowKeysSelected(selectedSeqnos);
      setItemSelected(state?.selectedItems);
      setCheckStateOrder(true);
    } else {
      // save selected items when click checkbox
      setSeqnoSelected(rowSelected);
      if (
        queryQuotation.data?.data?.items &&
        queryQuotation.data?.data?.items.length > 0
      ) {
        if (rowSelected.length > 0) {
          let listItem: Response.WebMitrEntity[] = [];
          if (rowSelected.length > seqnoSelected.length) {
            listItem = queryQuotation.data?.data?.items.filter((item) =>
              rowSelected.includes(item.seqno),
            );
            const newItemSelected = listItem.filter(
              (item) => !seqnoSelected.includes(item.seqno),
            );
            setItemSelected([...itemSelected, ...newItemSelected]);
          } else {
            listItem = itemSelected.filter((item) =>
              rowSelected.includes(item.seqno),
            );
            setItemSelected(listItem);
          }
        } else {
          setItemSelected([]);
        }
      }
    }
  }, [state?.selectedItem, rowSelected.length]);

  useEffect(() => {
    //check if records have expired date
    const hasExpiredRecord = itemSelected?.some((obj) =>
      checkExpired(obj?.mitrYukoKigenb),
    );

    setDisabledButton(hasExpiredRecord);
  }, [itemSelected.length]);

  const handleOrderRow = async () => {
    const newErrors: string[] = [];
    if (rowSelected.length > 0) {
      const expiredItem = itemSelected?.find((item) => {
        return checkExpired(item.mitrYukoKigenb) && !checkOrdered(item);
      });
      const orderedItem = itemSelected?.find((item) => {
        return checkOrdered(item);
      });
      if (orderedItem) {
        newErrors.push(t('message.E000080'));
      }
      if (expiredItem) {
        newErrors.push(t('message.E000022'));
      }
      if (!(await checkCurrencyTransfer(itemSelected))) {
        newErrors.push(t('message.E000024'));
      }
      if (newErrors.length > 0) {
        setErrors(newErrors);
      } else {
        history.push(PATH_ORDER, {
          items: itemSelected,
          search: location.search,
        });
      }
    } else {
      setErrors([
        t('message.E000021', {
          param: t('quotation_page.detail'),
        }),
      ]);
    }
  };

  const columns: ColumnsType<Response.QuotationEntity> = [
    // row responsive mobile
    {
      responsive: ['xs'] as Breakpoint[],
      render: (record) => (
        <ItemLabel type={getTypeQuotation(record)} status={record?.sttm} />
      ),
    },
    // rows responsive desktop
    {
      responsive: ['sm'] as Breakpoint[],
      width: '18%',
      render: (record) => (
        <ItemLabel type={getTypeQuotation(record)} status={record?.sttm} />
      ),
    },
    {
      width: '54%',
      responsive: ['sm'] as Breakpoint[],
      render: (record) => (
        <ItemInfo
          type={getTypeQuotation(record)}
          name={getProductName(record?.productName, false, record?.hakudoShhnm)}
          code={record.hakudoShhnCd}
          sub={record.zaitokKbn === ProductType.BACK_ORDER_PRODUCT}
        />
      ),
    },
    {
      align: 'right' as const,
      width: '30%',
      responsive: ['sm'] as Breakpoint[],
      render: (record) => (
        <ItemAction
          type={getTypeQuotation(record)}
          item={record}
          handleReaction={() => handleReaction(record.seqno)}
          handleDelete={() => handleDelete(record)}
          handleRequote={() => handleRequote(record)}
          handleOrder={() => handleOrder(record)}
        />
      ),
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    expandable: {
      expandedRowKeys: queryQuotation.data?.data?.items.map(
        (item) => item.seqno,
      ),
      expandedRowRender: (record) => (
        <ExpandedRowRender
          type={getTypeQuotation(record)}
          item={record}
          handleReaction={() => handleReaction(record.seqno)}
          handleDelete={() => handleDelete(record.seqno)}
          handleRequote={() => handleRequote(record)}
          handleOrder={() => handleOrder(record)}
        />
      ),
      defaultExpandAllRows: true,
    },
    dataSource: queryQuotation.data?.data?.items,
    loading: {
      spinning: queryQuotation.isLoading || exportPDF.isLoading,
      indicator: <LoadingOutlined />,
    },
    rowKey: 'seqno',
    showHeader: true,
    size: 'middle',
    rowClassName: (record) =>
      !checkOrdered(record) && checkExpired(record.mitrYukoKigenb)
        ? 'table-row-expired'
        : 'table-row',
    pagination: {
      ...paginationCommon,
      total: queryQuotation?.data?.data?.total,
      current: queryQuotation?.data?.data?.page,
      pageSize: queryQuotation?.data?.data?.offset,
      showSizeChanger: false,
    },
    className: 'custom_table',
    rowSelection: rowSelection,
    locale: {
      emptyText: t('message.E000024'),
    },
    title: () => (
      <QuotationAction
        handleDelete={handleDeleteRow}
        handleOrder={handleOrderRow}
        handleExportPDF={handleExportPDF}
        handleExportCSV={handleExportCSV}
        errors={errors}
        disabledButton={disabledButton}
      />
    ),
  };

  return {
    tableProps,
    ...rest,
  };
};

export default useTable;
