import React, { useState } from 'react';
import { Layout, Button } from 'antd';
import { SearchOutlined, MenuOutlined } from '@ant-design/icons';

import TopSearch from 'src/components/layout/TopNav/TopSearch';
import LangSelect from 'src/components/layout/TopNav/LangSelect';
import AppMenuMobile from 'src/components/layout/MenuMobile';
import SearchMobile from 'src/components/layout/SearchMobile';
import history from 'src/libs/history';
import useSiteInfo from 'src/queries/useSiteInfo';

const TopNav: React.FC = () => {
  const [toggleSearch, setToggleSearchMobile] = useState(false);
  const [toggleMenu, setToggleMenuMobile] = useState(false);
  const { queryCompanyInfo } = useSiteInfo(true);

  const companyInfo = queryCompanyInfo?.data?.data?.item;
  const headerColor = process.env.REACT_APP_HEADER_COLOR;

  const handleLogoClick = () => {
    history.push('/');
  };

  return (
    <Layout className={'top-nav'} style={{ backgroundColor: headerColor }}>
      <Layout
        className={'container-lg'}
        style={{ backgroundColor: headerColor }}
      >
        <Layout.Header style={{ backgroundColor: headerColor }}>
          {companyInfo?.ecsiteLogoDir && (
            <img
              src={companyInfo?.ecsiteLogoDir}
              alt="logo"
              className="object-contain logo-header"
              onClick={handleLogoClick}
            />
          )}
          <TopSearch />
          <div className="lang-select-topnav">
            <LangSelect />
          </div>
          {/** toggle search and menu mobile*/}
          <div className="toggle-mobile">
            <Button
              type="primary"
              size="small"
              className="w-full toggle-mobile-button"
              onClick={() => {
                toggleMenu && setToggleMenuMobile(false);
                setToggleSearchMobile(!toggleSearch);
              }}
            >
              <SearchOutlined />
            </Button>
            <Button
              type="primary"
              size="small"
              className="w-full toggle-mobile-button"
              onClick={() => {
                toggleSearch && setToggleSearchMobile(false);
                setToggleMenuMobile(!toggleMenu);
              }}
            >
              <MenuOutlined />
            </Button>
          </div>
          {toggleSearch && (
            <SearchMobile toggleSearchMobile={setToggleSearchMobile} />
          )}
          {toggleMenu && (
            <AppMenuMobile toggleMenuMobile={setToggleMenuMobile} />
          )}
        </Layout.Header>
      </Layout>
    </Layout>
  );
};

export default TopNav;
