import { RollEyesCondition } from 'src/interfaces/condition';
// condition [show, active] chamfer rolleyes select box
export const ROLL_EYES_CONDITION: RollEyesCondition = [
  {
    shapeType: '01',
    condition: [
      {
        varietyType: '01',
        condition: { show: true, active: true },
      },
      {
        varietyType: '02',
        condition: { show: true, active: true },
      },
      {
        varietyType: '03',
        condition: { show: true, active: false },
      },
      {
        varietyType: '04',
        condition: { show: true, active: true },
      },
      {
        varietyType: '05',
        condition: { show: true, active: true },
      },
      {
        varietyType: '06',
        condition: { show: true, active: true },
      },
      {
        varietyType: 'Others',
        condition: { show: true, active: true },
      },
    ],
  },
  {
    shapeType: '02',
    condition: [
      {
        varietyType: '',
        condition: { show: false, active: false },
      },
      {
        varietyType: 'Others',
        condition: { show: false, active: false },
      },
    ],
  },
  {
    shapeType: '03',
    condition: [
      {
        varietyType: '',
        condition: { show: false, active: false },
      },
      {
        varietyType: 'Others',
        condition: { show: false, active: false },
      },
    ],
  },
];
