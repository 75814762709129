import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { UseFormSetError } from 'react-hook-form';

import { FormValue } from 'src/containers/Pricing/useForm';
import quotation from 'src/repositories/quotation';
import { API_KEY } from 'src/constants/apiKey';
import { STATUS_CODE } from 'src/constants/app';

type UseEstimatePrice = {
  setError: UseFormSetError<FormValue>;
  labelError?: Response.ManufacturingSize['item'];
  isTrialMode?: boolean;
  estimateId?: string;
  shhnCd?: string;
  hakudoShhnCd?: string;
  api1ShhnCd?: string;
  api2ShhnCd?: string;
  snpo1?: string;
};

const useEstimatePrice = ({
  setError,
  labelError,
  isTrialMode,
  estimateId,
  shhnCd,
  hakudoShhnCd,
  api1ShhnCd,
  api2ShhnCd,
  snpo1,
}: UseEstimatePrice) => {
  const { t } = useTranslation();
  const apiKey = isTrialMode
    ? API_KEY.ESTIMATE_PRICE_GUEST
    : API_KEY.ESTIMATE_PRICE;

  // check error dynamic param size
  const checkErrorParams = (labelError, fieldError) => {
    if (labelError?.head.includes(fieldError)) {
      return setError('product_thickness', {
        type: 'custom',
        message: t('message.E000046', { 0: labelError?.head }),
      });
    }
    if (labelError?.size1Label.includes(fieldError)) {
      return setError('product_width', {
        type: 'custom',
        message: t('message.E000046', { 0: labelError?.size1Label }),
      });
    }
    if (labelError?.size2Label.includes(fieldError)) {
      return setError('product_length', {
        type: 'custom',
        message: t('message.E000046', { 0: labelError?.size2Label }),
      });
    }
  };

  const checkErrorParamsForDomestic = (labelError, error) => {
    // error?.field = "Dimension2";
    // error?.message = 'error.validation.Dimension2.messageError';
    const messageError = error?.message?.split(`${error?.field}.`)?.[1];
    if (labelError?.size1Label.includes(error?.field)) {
      return setError('product_width', {
        type: 'custom',
        message: messageError,
      });
    }
    if (labelError?.size2Label.includes(error?.field)) {
      return setError('product_length', {
        type: 'custom',
        message: messageError,
      });
    }
  };

  const estimatePrice = useMutation<
    AxiosResponse<Response.EstimatePrice>,
    AxiosError<Response.Error>,
    Payload.EstimatePrice
  >({
    mutationKey: [apiKey],
    mutationFn: (args) => quotation.postEstimatePrice(apiKey, args),
    onSuccess: () => {},
    onError: (err) => {
      const error = _.get(err, 'response.data');
      switch (error?.code) {
        case STATUS_CODE.WRONG_DESTINATION_CODE:
          // cannot find destination code
          window.scrollTo({ top: 300, behavior: 'smooth' });
          return setError('destination', {
            type: 'custom',
            message: t('message.E000007', {
              0: t(`pricing_page.user_destination_field`),
            }),
          });
        case STATUS_CODE.WRONG_FORMAT_SIZES:
          // wrong format size
          return setError('product_thickness', {
            type: 'custom',
            message: t('message.E000012', { 0: labelError?.head }),
          });
        case STATUS_CODE.OUT_OF_MACHINING_SIZE_RANGE:
          // out of machining sizes range
          return checkErrorParams(labelError, error?.field);
        case STATUS_CODE.OUT_OF_MACHINING_RANGE:
          // out of machining range
          return setError('product_thickness', {
            type: 'custom',
            message: t('message.E000047'),
          });
        case STATUS_CODE.OUT_OF_STOCK:
          // not enough product in stock
          return setError('number_product', {
            type: 'custom',
            message: t('message.E000052'),
          });
        case STATUS_CODE.WRONG_RADIUS_SIZES:
          // wrong radius sizes
          return setError('product_thickness', {
            type: 'custom',
            message: t('message.E000050', { 0: error?.params[0] }),
          });
        case STATUS_CODE.OUT_OF_RANGE_ERROR:
          // out of range estimate
          return setError('number_product', {
            type: 'custom',
            message: t('message.E000049'),
          });
        // validate show error messages on UI
        case STATUS_CODE.ESTIMATE_PRICE_ERROR:
          return;
        case STATUS_CODE.ESTIMATE_STOCK_ERROR:
          return;
        case STATUS_CODE.OUT_OF_MACHINING_SIZE_DOMESTIC_RANGE:
          return checkErrorParamsForDomestic(labelError, error);
      }
    },
  });

  // get detail Estimate to refill value to form
  const estimateDetail = useQuery<
    AxiosResponse<Response.EstimatePriceDetail>,
    AxiosError<any>
  >([`${API_KEY.ESTIMATE_DETAIL}/${estimateId}`], quotation.getEstimateDetail, {
    retry: 0,
    cacheTime: 0,
    enabled: !!estimateId,
    onError(err) {
      console.log(err.response);
    },
  });

  const estimatePriceData = estimatePrice.data?.data?.item;

  const queryTaxes = useQuery<
    AxiosResponse<Response.TaxesResponse>,
    AxiosError<any>
  >([`${API_KEY.TAXES}/${estimatePriceData?.webMitrNo}`], quotation.getTaxes, {
    retry: 0,
    cacheTime: 0,
    enabled: !!estimatePriceData?.webMitrNo,
    onError(err) {
      console.log(err.response);
    },
  });

  const queryProductDimension = useQuery<
    AxiosResponse<Response.ProductDimension>,
    AxiosError<any>
  >({
    queryKey: [
      API_KEY.PRODUCT_DETAIL,
      {
        shhnCd: shhnCd,
        hakudoShhnCd: hakudoShhnCd,
        api1ShhnCd: api1ShhnCd,
        api2ShhnCd: api2ShhnCd,
        snpo1: snpo1,
      },
    ],
    queryFn: quotation.getProductDimension,
    retry: 0,
    cacheTime: 0,
    enabled: !!shhnCd || !!hakudoShhnCd || !!api1ShhnCd || !!api2ShhnCd,
    onError(err) {
      console.log(err.response);
    },
  });

  return {
    estimatePrice,
    estimateDetail,
    queryTaxes,
    queryProductDimension,
  };
};

export default useEstimatePrice;
