import React from 'react';

import { Breadcrumb } from 'src/components/custom';
import ProductList from './ProductList';
import Search from './Search';

const CategorySearch: React.FC = () => {
  return (
    <div className="mb-30 mt-30 category">
      <Breadcrumb />
      <Search />
      <ProductList />
    </div>
  );
};

export default CategorySearch;
