import React, { useEffect, useMemo } from 'react';
import { Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { UseFormReturn } from 'react-hook-form';

import { FormValue } from '../useForm';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { RadioGroupField } from 'src/components/form';
import useProductCategory from 'src/queries/useProductCategory';
import { checkChangeLanguage } from 'src/utils/helper';
import { useLocation } from 'react-router-dom';

type Props = {
  formInfo: UseFormReturn<FormValue, any>;
  dataReEstimate?: Response.EstimatePriceDetail['item'];
  dataCategoryPage?: SearchParams.CategoryProductSearch;
  prevLang: string;
  lang: string;
  isProductFinished?: boolean;
};

const CategoriesList: React.FC<Props> = ({
  formInfo,
  dataReEstimate,
  dataCategoryPage,
  prevLang,
  lang,
  isProductFinished = false,
}) => {
  const { control, setValue, watch, getValues } = formInfo;
  const { t } = useTranslation();
  const { categories } = useProductCategory();
  const location = useLocation();
  const params = useQueryUrl<SearchParams.EstimatePriceSearch>();

  const categoriesData = useMemo(
    () =>
      categories &&
      categories.map((item) => {
        return {
          label: item.categoryName,
          value: item.categoryId,
          childrenList: item.childrenList,
        };
      }),
    [categories.length, JSON.stringify(categories)],
  );

  const subCategories = categoriesData
    ?.find((item) => item.value === watch('variety'))
    ?.childrenList.map((item) => {
      return {
        label: item.categoryName,
        value: item.categoryId,
      };
    });

  useEffect(() => {
    if (params.webMitrNo) {
      if (dataReEstimate?.hinshL3Cd) {
        setValue('variety', dataReEstimate?.hinshL3Cd);
      }
      return;
    }
    setValue('variety', _.get(categoriesData, '[0].value'));
  }, [_.get(categoriesData, '[0].value'), params.webMitrNo]);

  useEffect(() => {
    // auto fill value shape when navigate from Category page
    if (dataCategoryPage?.keijoL3Cd && !getValues('shape')) {
      setValue('shape', dataCategoryPage?.keijoL3Cd);
      return;
    }
    // auto fill value shape when re estimate from order history page
    if (dataReEstimate?.keijoL3Cd && !getValues('shape')) {
      setValue('shape', dataReEstimate?.keijoL3Cd);
      return;
    }
    setValue('shape', _.get(subCategories, '[0].value'));
  }, [_.get(subCategories, '[0].value')]);

  useEffect(() => {
    // select first varitety and shape if Change language
    if (checkChangeLanguage(prevLang, lang)) {
      setValue('variety', _.get(categoriesData, '[0].value'));
      setValue('shape', _.get(subCategories, '[0].value'));
    }
  }, [lang]);

  useEffect(() => {
    // select first varitety and shape if click Quotation/Order Tab
    if (!location?.search) {
      setValue('variety', _.get(categoriesData, '[0].value'));
      setValue('shape', _.get(subCategories, '[0].value'));
    }
  }, [location?.search]);

  return (
    <Row>
      <Col xs={24} sm={6} md={5} lg={4} xl={3} className="form_typo">
        <Typography.Text className="form_label_required">
          {t('pricing_page.category_field.name')}
        </Typography.Text>
      </Col>
      <Col xs={24} sm={18} md={19} lg={20} xl={21}>
        <Row className="category_block_infor_row variety_row">
          <Col xs={4} sm={6} md={5} lg={4} xl={2}>
            <Typography.Text className="category_label">
              {t('pricing_page.category_field.category_label')}
            </Typography.Text>
          </Col>
          <Col xs={20} sm={18} md={19} lg={20} xl={22}>
            <RadioGroupField
              className="radio_mt"
              radioGroupProps={{
                options: categoriesData,
              }}
              controller={{
                name: 'variety',
                control,
              }}
            />
          </Col>
        </Row>
        <Row className="category_block_infor_row">
          <Col xs={4} sm={6} md={5} lg={4} xl={2}>
            <Typography.Text className="category_label label_shape">
              {isProductFinished
                ? t('pricing_page.category_field.type')
                : t('pricing_page.category_field.shape_field')}
            </Typography.Text>
          </Col>
          <Col xs={20} sm={18} md={19} lg={20} xl={22}>
            <RadioGroupField
              className="category_shape radio_mt"
              radioGroupProps={{
                options: subCategories,
              }}
              controller={{
                name: 'shape',
                control,
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default CategoriesList;
