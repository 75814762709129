import React from 'react';

import { PATH, PATH_2 } from 'src/pages/productDetails';
import loadable from 'src/utils/loadable';
import { SCREENS } from 'src/constants/screens';
import { IRouter } from 'src/interfaces/route';
import CommonLayout from 'src/components/applications/CommonLayout';

const ProductDetailsRoute: IRouter[] = [
  {
    element: <CommonLayout title={'product_page.title'} />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/productDetails')),
        title: 'product_page.title',
        screenId: SCREENS.R1014,
      },
      {
        path: PATH_2,
        element: loadable(() => import('src/pages/productDetails')),
        title: 'product_page.title',
        screenId: SCREENS.R1014,
      },
    ],
  },
];

export default ProductDetailsRoute;
