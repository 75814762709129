import dayjs, { Dayjs } from 'dayjs';
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs';
import generatePicker from 'antd/es/date-picker/generatePicker';
import LocalStorage from 'src/utils/LocalStorage';
import 'antd/es/date-picker/style/index';
import jaLocale from 'dayjs/locale/ja';
import enLocale from 'dayjs/locale/en-in';
import viLocale from 'dayjs/locale/vi';
import thLocale from 'dayjs/locale/th';
import zhLocale from 'dayjs/locale/zh';
import twLocale from 'dayjs/locale/zh-tw';
import koLocale from 'dayjs/locale/ko';

const locateOptions = {
  ja: jaLocale,
  en: enLocale,
  vi: viLocale,
  th: thLocale,
  zh: zhLocale,
  tw: twLocale,
  ko: koLocale,
};

const weekStart = 1;
const dayjsLocale = {
  ...locateOptions[LocalStorage.lang],
  weekStart,
};
dayjs.locale(dayjsLocale);

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig);

export default DatePicker;
