import { Layout } from 'antd';
import { t } from 'i18next';
import React from 'react';

import ProductDetailsContainer from 'src/containers/ProductDetails';
import useTitle from 'src/hooks/useTitle';
import LocalStorage from 'src/utils/LocalStorage';

const ProductDetailsPage: React.FC = () => {
  useTitle(t('product_page.title'));
  return (
    <Layout className={'layout-container'}>
      <div className={'container-lg'}>
        <ProductDetailsContainer isTrialMode={!LocalStorage.accessToken} />
      </div>
    </Layout>
  );
};

export const PATH = ':origin/product_details' as const;
export const PATH_2 = '/product_details' as const;
export const PATH_3 = '/category' as const;
export default ProductDetailsPage;
