import { SORT } from 'src/constants/app';

export const MACHINING_OPTIONS = [
  { value: '', label: '' },
  { value: '01', label: '＋' },
  { value: '02', label: '－' },
  { value: '03', label: '±' },
];

export const CORNER_OPTIONS: App.OptionsFnc = (t) => [
  { value: '', label: '' },
  { value: '01', label: t('pricing_page.around_chamfer_option.with_chamfer') },
  { value: '02', label: t('pricing_page.around_chamfer_option.no_chamfer') },
  { value: '03', label: t('pricing_page.around_chamfer_option.no_deburring') },
];

export const ROLL_EYES_OPTIONS: App.OptionsFnc = (t) => [
  { value: '', label: t('pricing_page.roll_eyes_option.unspecified') },
  { value: '01', label: t('pricing_page.roll_eyes_option.longitudinal') },
  { value: '02', label: t('pricing_page.roll_eyes_option.short_hand') },
];

export const MILLSHEET_OPTIONS: App.OptionsFnc = (t) => [
  { value: '', label: t('pricing_page.mill_sheet_option.unnecessary') },
  {
    value: '07',
    label: t('pricing_page.mill_sheet_option.required_view_web'),
  },
  // CJPDJSKY-934: On US environment, do not show this radio button
  // {
  //   value: '04',
  //   label: t('pricing_page.mill_sheet_option.required_sent_fax'),
  // },
];

export const UNIT_OPTIONS = [
  { value: '01', label: 'sheet' },
  { value: '02', label: 'book' },
  { value: '03', label: 'book' },
];

export const SHAPE_TYPE = { plate: '01', rod: '02', tube: '03' }; // 3 type 板 棒 管

export const DEFAULT_CONDITION = { show: false, active: false };

export const MACHINING_CODE_CONDITION = ['KP', 'KK'];

export const ROLL_EYES_SPECIAL_CONDITION = {
  variety: '01',
  conditionMachining: ['I', 'H', 'Z0'],
};

export const EXPRESS_CONDITION: App.OptionsFnc = (t) => [
  {
    label: t('quotation_page.show_only_ordered'),
    value: 'CLOSED_ORDER_TYPE',
  },
  {
    label: t('quotation_page.show_expired'),
    value: 'EXPIRED_ORDER_TYPE',
  },
  {
    label: t('quotation_page.show_only_favorite_details'),
    value: 'FAVORITE_ORDER_TYPE',
  },
];

export const SORT_CONDITION: App.OptionsFnc = (t) => [
  {
    label: t('quotation_page.descending_order'),
    value: SORT.DESC,
  },
  {
    label: t('quotation_page.ascending_order'),
    value: SORT.ASC,
  },
];
