import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { REGEX } from 'src/constants/app';
import { allowNaNNumber } from 'src/utils/helper';

export type FormValue = {
  customer_name: string;
  destination: string;
  variety: string;
  shape: string;
  product_machining: string;
  product_thickness: string;
  product_width: string;
  product_length: string;
  converted_product_thickness?: string;
  converted_product_width?: string;
  converted_product_length?: string;
  number_product: string;
  machining_thickness: string;
  machining_width: string;
  machining_length: string;
  machining_outside_diameter: string;
  machining_inside_diameter: string;
  options_size_unspecified: string;
  options_size_c: string;
  corner_option_a: string;
  corner_option_b: string;
  corner_option_c: string;
  corner_option_d: string;
  roll_eyes?: string;
  mill_sheets?: string;
  order_number: string;
  order_notes: string;
  // data optional
  destinationItem?: object;
  productItem?: Response.ProductItem;
  machiningItem?: {
    label: string;
    value: string;
  };
  sizeItem: object;
  chamferItem: object;
  seihnJoho?: string;
  industry?: string;
  region?: string;
};

const useForm = (
  labelError,
  isTrialMode,
  checkIsImportedProductOnlyAndZ0,
  isProductFinished,
) => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    title: Yup.string(),
    destination: isTrialMode
      ? Yup.string()
      : Yup.string().required(
          t('message.E000007', {
            0: t(`label.destination`),
          }),
        ),
    variety: Yup.string().required(),
    shape: Yup.string().required(),
    product_thickness:
      labelError?.head &&
      Yup.string()
        .required(
          t('message.E000007', {
            0: labelError?.head,
          }),
        )
        .matches(
          REGEX.REGEX_PRODUCT_THICKNESS,
          t('message.E000012', {
            0: labelError?.head,
          }),
        ),
    seihnJoho:
      isProductFinished &&
      Yup.string().required(
        t('message.E000007', {
          0: t('pricing_page.type_selection'),
        }),
      ),
    product_width:
      labelError?.size1Label &&
      !checkIsImportedProductOnlyAndZ0 &&
      !isProductFinished &&
      Yup.string().required(
        t('message.E000003', {
          0: labelError?.size1Label,
        }),
      ),
    product_length:
      labelError?.size2Label &&
      !checkIsImportedProductOnlyAndZ0 &&
      !isProductFinished &&
      Yup.string().required(
        t('message.E000003', {
          0: labelError?.size2Label,
        }),
      ),
    number_product: Yup.number()
      .required()
      .min(
        1,
        t('message.E000009', {
          0: t('pricing_page.product_size_field.number_field'),
          1: '1',
        }),
      )
      .transform(allowNaNNumber)
      .nullable(),
    machining_thickness: Yup.string().nullable(),
    machining_width: Yup.string().nullable(),
    machining_length: Yup.string().nullable(),
    machining_outside_diameter: Yup.string().nullable(),
    machining_inside_diameter: Yup.string().nullable(),
    options_size_unspecified: Yup.string().nullable(),
    options_size_c: Yup.number()
      .max(
        99.9,
        t('message.E000053', {
          0: t('pricing_page.around_chamfer.error_C'),
        }),
      )
      .transform(allowNaNNumber)
      .nullable(),
    corner_option_a: Yup.number()
      .max(
        99.9,
        t('message.E000053', {
          0: t('pricing_page.corner_handling_options.error_A'),
        }),
      )
      .transform(allowNaNNumber)
      .nullable(),
    corner_option_b: Yup.number()
      .max(
        99.9,
        t('message.E000053', {
          0: t('pricing_page.corner_handling_options.error_B'),
        }),
      )
      .transform(allowNaNNumber)
      .nullable(),
    corner_option_c: Yup.number()
      .max(
        99.9,
        t('message.E000053', {
          0: t('pricing_page.corner_handling_options.error_C'),
        }),
      )
      .transform(allowNaNNumber)
      .nullable(),
    corner_option_d: Yup.number()
      .max(
        99.9,
        t('message.E000053', {
          0: t('pricing_page.corner_handling_options.error_D'),
        }),
      )
      .transform(allowNaNNumber)
      .nullable(),
  });

  const formInfo = useHookForm<FormValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'onChange',
  });
  return { formInfo };
};

export default useForm;
