import React from 'react';
import color from 'src/styles/themes/color';

type Props = {
  w?: number;
  h?: number;
  fill?: string;
  className?: string;
  onClick?: () => void;
};

const IconUser: React.FC<Props> = ({
  w = 18,
  h = 18,
  fill = color.grey_3,
  className,
  onClick,
}) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M9 3C9.79565 3 10.5587 3.31607 11.1213 3.87868C11.6839 4.44129 12 5.20435 12 6C12 6.79565 11.6839 7.55871 11.1213 8.12132C10.5587 8.68393 9.79565 9 9 9C8.20435 9 7.44129 8.68393 6.87868 8.12132C6.31607 7.55871 6 6.79565 6 6C6 5.20435 6.31607 4.44129 6.87868 3.87868C7.44129 3.31607 8.20435 3 9 3ZM9 10.5C12.315 10.5 15 11.8425 15 13.5V15H3V13.5C3 11.8425 5.685 10.5 9 10.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconUser;
