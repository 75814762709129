import React from 'react';
import { Col, Divider, Row, Typography } from 'antd';

type ItemCounterProps = {
  index: number;
};

const ItemCounter: React.FC<ItemCounterProps> = ({
  index,
}: ItemCounterProps) => {
  return (
    <Row className="item-counter">
      <Typography.Title className="item-counter-index" level={4}>
        {(index + 1).toString().padStart(2, '0')}
      </Typography.Title>
      <Col xs={1} sm={0} className="item_divider-vertical">
        <Divider type="vertical" />
      </Col>
    </Row>
  );
};

export default ItemCounter;
