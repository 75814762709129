import React from 'react';
import color from 'src/styles/themes/color';

type Props = {
  w?: number;
  h?: number;
  fill?: string;
  className?: string;
  onClick?: () => void;
};

const IconCalculator: React.FC<Props> = ({
  w = 18,
  h = 18,
  fill = color.grey_3,
  className,
  onClick,
}) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M2.16896 2C2.05632 2 2 2.07509 2 2.16896V16.831C2 16.9249 2.07509 17 2.16896 17H14.9537C15.0476 17 15.1227 16.9249 15.1227 16.831V2.16896C15.1227 2.05632 15.0476 2 14.9537 2H2.16896ZM3.87735 3.87735H13.2641V7.63204H3.87735V3.87735ZM3.87735 9.50939H5.75469V11.3867H3.87735V9.50939ZM7.63204 9.50939H9.50939V11.3867H7.63204V9.50939ZM11.3867 9.50939H13.2641V15.1414H11.3867V9.50939ZM3.87735 13.2641H5.75469V15.1414H3.87735V13.2641ZM7.63204 13.2641H9.50939V15.1414H7.63204V13.2641Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconCalculator;
