import React, { useState } from 'react';
import qs from 'query-string';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { CaretDownOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import history from 'src/libs/history';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import useProductCategory from 'src/queries/useProductCategory';
import usePdfMenu from 'src/queries/usePdfMenu';
import { PATH as PATH_HOME } from 'src/pages/home';
import { PATH as PATH_PRICING } from 'src/pages/pricing';
import { PATH as PATH_QUOTATION } from 'src/pages/quotation';
import { PATH } from 'src/pages/category';
import { CONTACT_INFO } from 'src/constants/app';
import {
  IconCalculator,
  IconHomeTop,
  IconSearch,
  IconUser,
} from 'src/components/icon';
import { SHAPE_TYPE } from 'src/constants/quotation/optionSelect';
import { getProductName, handleRedirectProductDetail } from 'src/utils/helper';
import { PATH_2 } from 'src/pages/productDetails';
import color from 'src/styles/themes/color';

const useMenu = (type: string) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [dataProductList, setDataProductList] = useState<
    {
      categoryShape: string;
      productList: Response.ProductList['items'];
    }[]
  >([]);

  const handleSuccessProductList = (res, args) => {
    const existingItem = dataProductList.find(
      (item) => item.categoryShape === `${args?.hinshL3Cd}-${args?.keijoL3Cd}`,
    );
    if (!existingItem) {
      setDataProductList((prevDataProductList) => [
        ...prevDataProductList,
        {
          categoryShape: `${args?.hinshL3Cd}-${args?.keijoL3Cd}`,
          productList: res?.data?.items,
        },
      ]);
    }
  };

  const { categories, getProductList } = useProductCategory(
    handleSuccessProductList,
  );
  const { pdfFiles } = usePdfMenu(true);

  const getSelectedKey = () => {
    const key = location.pathname.split('/')[1];
    return [`/${key}`];
  };
  const onSelectShape = (category, child) => {
    history.push({
      pathname: PATH,
      search: qs.stringify({
        hinshL3Cd: category?.categoryId,
        keijoL3Cd: child?.categoryId,
      }),
    });
  };

  const onSelectCategory = (category) => {
    const shape = categories
      ?.find((item) => item === category)
      ?.childrenList.map((item) => {
        return item?.categoryId;
      });

    history.push({
      pathname: PATH,
      search: qs.stringify({
        hinshL3Cd: category?.categoryId,
        keijoL3Cd: shape?.[0] || SHAPE_TYPE.plate,
      }),
    });
  };

  const _renderProductList = (category, shape) => {
    let productList: ItemType[] = [];
    const listData = dataProductList.find(
      (item) =>
        item.categoryShape === `${category?.categoryId}-${shape?.categoryId}`,
    )?.productList;
    listData?.map((product, index) => {
      productList.push({
        key: `prod${category?.categoryId}${shape?.categoryId}${index}`,
        label: getProductName(product, true),
        onClick: () =>
          handleRedirectProductDetail(product, category?.categoryId, PATH_2),
      });
    });
    return productList;
  };

  const _renderSubMenuChild = (item) => {
    let itemSubChild: ItemType[] = [];
    item?.childrenList.map((child) => {
      itemSubChild.push({
        key: `sub${item.categoryId}${child.categoryId}`,
        onTitleClick: () => onSelectShape(item, child),
        label: child.categoryName,
        onMouseEnter: () =>
          getProductList.mutate({
            hinshL3Cd: item?.categoryId,
            keijoL3Cd: child?.categoryId,
          }),
        children: _renderProductList(item, child),
      });
    });
    return itemSubChild;
  };

  const _renderSubMenu = () => {
    let itemSub: ItemType[] = [];
    categories?.map((item) => {
      itemSub.push({
        key: item.categoryId,
        children: _renderSubMenuChild(item),
        onTitleClick: () => onSelectCategory(item),
        label: item.categoryName,
      });
    });
    return itemSub;
  };

  const pdf02 = pdfFiles?.find((item) => item.kbnmCd === '02')?.fileDir;
  const pdf03 = pdfFiles?.find((item) => item.kbnmCd === '03')?.fileDir;

  const checkDisplayPdfCatalog = () => {
    if (pdf03) {
      return {
        key: 'menu5',
        className: 'pdf-2',
        label: (
          <>
            <a
              target={pdf03 ? '_blank' : '_self'}
              rel="noreferrer"
              href={pdf03 || '/#'}
            >
              {t('menu_home.electronic_catalog')}
            </a>
            <span style={{ color: color.grey_3 }}> |</span>
          </>
        ),
      };
    } else {
      return null;
    }
  };

  const menuItems: ItemType[] = [
    {
      key: 'categories',
      className: 'active',
      label:
        type == 'main' ? (
          <span>
            {t('menu_home.product_category')}
            <CaretDownOutlined />
          </span>
        ) : (
          t('menu_home.product_category')
        ),
      children: _renderSubMenu(),
    },
    {
      key: PATH_HOME,
      icon: <IconHomeTop />,
      className: 'header_tab',
      label: <Link to={PATH_HOME}>{t('menu_home.home')}</Link>,
    },
    {
      key: PATH_PRICING,
      icon: <IconCalculator />,
      className: 'header_tab',
      label: <Link to={PATH_PRICING}>{t('menu_home.order')}</Link>,
    },
    {
      key: PATH_QUOTATION,
      icon: <IconSearch />,
      className: 'header_tab',
      label: <Link to={PATH_QUOTATION}>{t('menu_home.order_history')}</Link>,
    },
    {
      key: 'menu4',
      icon: <IconUser />,
      className: 'header_tab',
      label: (
        <a
          target={pdf02 ? '_blank' : '_self'}
          rel="noreferrer"
          href={pdf02 || '/#'}
        >
          {t('menu_home.order_tutorial')}
        </a>
      ),
    },
    checkDisplayPdfCatalog(),
    {
      key: 'menu6',
      className: classNames('url-company', { 'not-catalog': !pdf03 }),
      label: (
        <a target={'_blank'} rel="noreferrer" href={CONTACT_INFO.HOME_PAGE}>
          {t('menu_home.about_company')}
        </a>
      ),
    },
  ];
  return {
    menuItems,
    getSelectedKey,
  };
};

export default useMenu;
