import * as React from 'react';
import qs from 'query-string';

import history from 'src/libs/history';

/**
 * Remount component on history push which has search params: search=true
 * @param Component
 */
export function withRemountOnHistoryPush(Component) {
  const DecoratedComponent = (props) => {
    const [key, setKey] = React.useState(0);
    React.useEffect(() => {
      return history.listen((lister) => {
        const { search } = qs.parse(lister.location?.search || '');
        if (!search || lister.action !== 'PUSH') return;
        setKey((prev) => prev + 1);
      });
    }, [history]);
    return <Component key={key} {...props} />;
  };
  return DecoratedComponent;
}
