import React, { Dispatch, SetStateAction } from 'react';
import { Row } from 'antd';
import { Control } from 'react-hook-form/dist/types/form';

import ChamferItem from '../ChamferItem';
import useQueryChamfer from 'src/queries/useChamfer';
import { FormValue } from '../useForm';

type Props = {
  control: Control<FormValue, any>;
  handleActiveChamfer: Dispatch<
    SetStateAction<Response.ChamferItem | undefined>
  >;
  type: number;
  activeChamfer: Response.ChamferItem | undefined;
  chooseTabId: number;
};

const Sides: React.FC<Props> = (props) => {
  const { chooseTabId } = props;
  const { chamfers } = useQueryChamfer(chooseTabId);
  return (
    <Row className="mt-28 contain-image" gutter={[35, 20]}>
      {chamfers &&
        chamfers.map((chamfer, id) => (
          <ChamferItem key={id} {...props} chamferImage={chamfer} />
        ))}
    </Row>
  );
};

export default Sides;
