import { useForm as useHookForm } from 'react-hook-form';

export type FormValue = {
  chamfer: Response.ChamferItem;
};

const useForm = () => {
  const formInfo = useHookForm<FormValue>({});
  return { ...formInfo };
};

export default useForm;
