import { I18n, t } from 'src/libs/i18n';

import {
  MACHINING_SELECT_CONDITION,
  INPUT_INDEX,
} from 'src/constants/quotation/conditionInput/conditionMachining';
import {
  UNIT_OPTIONS,
  ROLL_EYES_SPECIAL_CONDITION,
  MILLSHEET_OPTIONS,
  SHAPE_TYPE,
} from 'src/constants/quotation/optionSelect';
import { CHAMFER_CONDITION } from 'src/constants/quotation/conditionInput/conditionChamfer';
import { CORNER_CONDITION } from 'src/constants/quotation/conditionInput/conditionCorner';
import { ROLL_EYES_CONDITION } from 'src/constants/quotation/conditionInput/conditionRolleyes';
import { IMAGE_PREVIEW } from 'src/constants/quotation/imagePreview';
import { productFormat } from 'src/utils/product';
import { isAllTrue } from './helper';

type FormatSizesType = {
  thickness: string;
  width?: string;
  length?: string;
};

/**
 * Check condition show machining select box
 * @param shapeType: shape type user selected
 * @param machiningMethod: machining method user selected
 * @param selectName: name of select box want to check show
 * @returns [show, active]
 */
export const getVisiableMachining = (
  shapeType: string,
  machiningMethod: string,
  selectName: string,
) => {
  const shapeCondition = MACHINING_SELECT_CONDITION.filter(
    (item) => item.shapeType == shapeType,
  )[0];
  if (!shapeCondition) return false;
  var machiningCondition = shapeCondition.condition.filter(
    (item) => item.matchiningType == machiningMethod,
  )[0];
  if (!machiningCondition) {
    machiningCondition = shapeCondition.condition.filter(
      (item) => item.matchiningType == 'Others',
    )[0];
  }
  const indexSelect = INPUT_INDEX.filter((item) => item.value == selectName)[0];
  if (!indexSelect) return false;
  return isAllTrue(machiningCondition.condition[indexSelect.index]);
};

/**
 * Check condition show Chamfer select Box
 * @param shapeType: shape type user selected
 * @param machiningMethod: machining method user selected
 * @param indexInput: position of input want to show
 * returns {show, active}
 */
export const getVisiableChamfer = (
  shapeType: string,
  machiningMethod: string,
  indexInput: number,
) => {
  const shapeCondition = CHAMFER_CONDITION.filter(
    (item) => item.shapeType == shapeType,
  )[0];
  if (!shapeCondition) return false;
  var machiningCondition = shapeCondition.condition.filter(
    (item) => item.matchiningType == machiningMethod,
  )[0];
  if (!machiningCondition) {
    machiningCondition = shapeCondition.condition.filter(
      (item) => item.matchiningType == 'Others',
    )[0];
  }
  return isAllTrue(machiningCondition.condition[indexInput]);
};

/**
 * Check condition show Corner Box
 * @param shapeType: shape type user selected
 * @param machiningMethod: machining method user selected
 * returns {show, active}
 */
export const getVisiableCorner = (
  shapeType: string,
  machiningMethod: string,
) => {
  const shapeCondition = CORNER_CONDITION.filter(
    (item) => item.shapeType == shapeType,
  )[0];
  if (!shapeCondition) return false;
  var machiningCondition = shapeCondition.condition.filter(
    (item) => item.matchiningType == machiningMethod,
  )[0];
  if (!machiningCondition) {
    machiningCondition = shapeCondition.condition.filter(
      (item) => item.matchiningType == 'Others',
    )[0];
  }
  return isAllTrue(machiningCondition.condition);
};

/**
 * Check condition show RollEyes select Box
 * @param shapeType: shape type user selected
 * @param varietyType: variety type user selected
 * returns {show, active}
 */
export const getVisiableRollEyes = (
  shapeType: string,
  varietyType: string,
  machiningMethod: string,
  product: string,
) => {
  /**
   * If product have hakudoShhnCd and don't have shhnCd then compare condition
   * Otherwise deactive rolleyes.
   */
  const productSplit = productFormat.splitProductId(product);
  const conditionProduct =
    (!productSplit.hakudoShhnCd && productSplit.shhnCd) ||
    (productSplit.hakudoShhnCd && productSplit.shhnCd);

  if (conditionProduct && shapeType == SHAPE_TYPE.plate) {
    return false;
  }
  // condition special machining method
  const specialCondition = ROLL_EYES_SPECIAL_CONDITION.conditionMachining.some(
    (machinning) => machiningMethod?.includes(machinning),
  );
  if (shapeType == ROLL_EYES_SPECIAL_CONDITION.variety && specialCondition) {
    return false;
  }
  // condition shape and machining method
  const shapeCondition = ROLL_EYES_CONDITION.filter(
    (item) => item.shapeType == shapeType,
  )[0];
  if (!shapeCondition) return false;
  let varietyCondition = shapeCondition.condition.filter(
    (item) => item.varietyType == varietyType,
  )[0];
  if (!varietyCondition) {
    varietyCondition = shapeCondition.condition.filter(
      (item) => item.varietyType == 'Others',
    )[0];
  }
  return isAllTrue(varietyCondition?.condition);
};

/**
 * Get patch image by name
 * @param code: code of shape type
 */
export const getProductUnit = (code: string) => {
  const unit = UNIT_OPTIONS.filter((item) => item.value == code)[0];
  if (!unit) return null;

  return I18n.t(`pricing_page.${unit?.label}`);
};

/**
 * Get patch image by name
 * @param name: name of image want to get path
 */
export const getImagePreviewPath = (name: string) => {
  const imagePreview = IMAGE_PREVIEW.filter((item) => item.name == name)[0];
  if (!imagePreview) return '';
  return imagePreview?.image;
};

/**
 * format sizes to string AxBxC
 */
export const formatSizes = (param: FormatSizesType) => {
  const { thickness, width, length } = param;
  return [thickness, width, length].filter(Boolean).join('x');
};

export const getMillSheet = (optc091) => {
  const optc091Str = MILLSHEET_OPTIONS(t).find(
    (item) => item.value == (optc091 || ''),
  )?.label;
  return optc091Str;
};

/**
 * format details order and matching label
 * @returns string (a/b/c)
 */
export const getDetailOrderString = (
  kyaksChmnNo,
  opt,
  optc091,
  biko,
  isProductFinished,
) => {
  const optc091Str = !isProductFinished && getMillSheet(optc091);
  return [kyaksChmnNo, opt, optc091Str, biko].filter(Boolean).join('/');
};

/**
 * get 3 param from finishingSize to fill to each input re-estimate.
 */
export const getSizeByLabelInput = (manufacturingSizes, finishingSizeArr) => {
  const sizeLength =
    manufacturingSizes.head?.split('(')[0].split('x').length || 0;
  const param1 = finishingSizeArr.slice(0, sizeLength)?.join('x');
  const param2 = finishingSizeArr.slice(sizeLength, sizeLength + 1).join('');
  const param3 = finishingSizeArr
    .slice(sizeLength + 1, sizeLength + 2)
    .join('');
  return {
    param1,
    param2,
    param3,
  };
};
