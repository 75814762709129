import React, { useEffect, useState } from 'react';
import { Button, Row, Col, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { DownSquareFilled } from '@ant-design/icons';
import classNames from 'classnames';
import qs from 'query-string';

import Modal from 'src/components/custom/Modal';
import {
  FullWidthInputField,
  HalfWidthInputField,
  InputField,
  RadioGroupField,
  SelectField,
} from 'src/components/form';
import useForm, { FormValue } from './useForm';
import ProductModal from '../ProductModal';
import { DestinationModal } from 'src/components/common';
import DateRangePickerField from 'src/components/form/DateRangePickerField';
import NumberOnlyRangePicker from 'src/components/form/NumberOnlyRangePicker';
import history from 'src/libs/history';
import useQueryUrl from 'src/hooks/useQueryUrl';
import {
  EXPRESS_CONDITION,
  SORT_CONDITION,
} from 'src/constants/quotation/optionSelect';
import useQuotation from 'src/queries/useQuotation';
import { SORT } from 'src/constants/app';

const Search: React.FC = () => {
  const { t } = useTranslation();
  const { control, setValue, handleSubmit, getValues, setError, reset } =
    useForm();
  const query = useQueryUrl();
  const { dataOrderStatus } = useQuotation({
    setError,
    setRowKeysSelected: undefined,
  });
  const [open, setOpen] = useState<boolean>(true);

  const onSelectDestination = (destination: Response.DestinationItem) => {
    setValue('delivery_name', destination.kokyakuMei);
  };

  const onSelectProduct = (product) => {
    setValue('product', product);
  };

  const handleSearch = (values) => {
    history.push({
      search: qs.stringify(
        {
          ...query,
          ...values,
          registration_date_from: getValues().registration_date_from,
          registration_date_to: getValues().registration_date_to,
          search: true,
          page: 1,
        },
        {
          skipNull: true,
          skipEmptyString: true,
          arrayFormatSeparator: '|',
          arrayFormat: 'bracket-separator',
        },
      ),
    });
  };

  const handleClearParam = () => {
    reset({
      registration_date_from: '',
      registration_date_to: '',
      order_number: '',
      quotation_number_from: '',
      quotation_number_to: '',
      delivery_name: '',
      product: '',
      person_in_charge: '',
      finished_dimensions: '',
      express_condition: '',
      sort_condition: SORT.DESC,
    });

    history.push({
      pathname: '/quotation',
    });
  };

  const onClickCollapse = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (query.status) {
      setValue('status', Number(query.status));
    }
  }, [query.status]);

  return (
    <div className="search">
      <div
        className={classNames('search_collapse', {
          'mb-20': open,
        })}
      >
        <Row>
          <Typography.Title level={1} className="search_collapse_title">
            {t('quotation_page.page_title')}
          </Typography.Title>
        </Row>
        <Row className="search_collapse_action">
          <Typography.Text onClick={onClickCollapse}>
            {open ? t('quotation_page.close') : t('quotation_page.open')}
          </Typography.Text>
          <DownSquareFilled rotate={open ? 180 : 0} onClick={onClickCollapse} />
        </Row>
      </div>
      {open && (
        <React.Fragment>
          <Divider className="search_divider" />
          <Row>
            <Col xs={24} md={12} xl={12}>
              <DateRangePickerField
                label={t('quotation_page.registration_date')}
                controllerFrom={{
                  name: 'registration_date_from',
                  control: control,
                }}
                controllerTo={{
                  name: 'registration_date_to',
                  control: control,
                }}
                datePickerPropsFrom={{
                  placeholder: t('quotation_page.start_date'),
                }}
                datePickerPropsTo={{
                  placeholder: t('quotation_page.end_date'),
                }}
                labelCol={{ xs: 24, md: 6 }}
                wrapperCol={{ xs: 24, md: 18 }}
              />
              <NumberOnlyRangePicker
                isOnlyInteger={true}
                label={t('quotation_page.quotation_number')}
                controllerFrom={{
                  name: 'quotation_number_from',
                  control: control,
                }}
                controllerTo={{
                  name: 'quotation_number_to',
                  control: control,
                }}
                inputPropsFrom={{
                  placeholder: t('quotation_page.quote_number_placeholder'),
                  maxLength: 8,
                }}
                inputPropsTo={{
                  placeholder: t('quotation_page.quote_number_placeholder'),
                  maxLength: 8,
                }}
                labelCol={{ xs: 24, md: 6 }}
                wrapperCol={{ xs: 24, md: 18 }}
                prefix="W"
              />
              <Row className="search_delivery">
                <Col xs={24} md={6} xl={6}>
                  <Typography.Title level={5} className="form_label">
                    {t('quotation_page.delivery_name')}
                  </Typography.Title>
                </Col>
                <Col xs={15} sm={18} md={10} lg={12} xl={13}>
                  <FullWidthInputField<FormValue>
                    controller={{
                      control,
                      name: 'delivery_name',
                    }}
                    inputProps={{
                      placeholder: t('quotation_page.delivery_name'),
                    }}
                  />
                </Col>
                <Col
                  xs={9}
                  sm={6}
                  md={8}
                  lg={6}
                  xl={5}
                  className="search_delivery_button"
                >
                  <Modal
                    modalProps={{ width: 840 }}
                    title={t('pricing_page.destination_modal.title')}
                    content={({ onClose }) => (
                      <DestinationModal
                        onClose={onClose}
                        onSelect={onSelectDestination}
                      />
                    )}
                  >
                    <Button type="primary" className="destination_button">
                      {t('quotation_page.selection')}
                    </Button>
                  </Modal>
                </Col>
              </Row>
              <InputField<FormValue>
                label={t('quotation_page.person_in_charge_name')}
                controller={{
                  control,
                  name: 'person_in_charge',
                }}
                inputProps={{
                  placeholder: t('quotation_page.person_in_charge_name'),
                  maxLength: 30,
                }}
                labelCol={{ xs: 24, md: 6, xl: 6 }}
                wrapperCol={{ xs: 24, md: 18, xl: 18 }}
                className="search_input"
              />
            </Col>
            <Col xs={24} md={12} xl={12} className="search_right">
              <HalfWidthInputField<FormValue>
                label={t('quotation_page.your_order_number')}
                controller={{
                  control,
                  name: 'order_number',
                }}
                inputProps={{
                  placeholder: t('quotation_page.your_order_number'),
                  maxLength: 30,
                }}
                labelCol={{ xs: 24, md: 6, xl: 5 }}
                wrapperCol={{ xs: 24, md: 16, xl: 18 }}
                className="search_input"
              />
              <Row className="search_delivery">
                <Col xs={24} md={6} xl={5}>
                  <Typography.Title level={5} className="form_label">
                    {t('quotation_page.product_name')}
                  </Typography.Title>
                </Col>
                <Col xs={15} sm={18} md={8} lg={10} xl={13}>
                  <FullWidthInputField<FormValue>
                    controller={{
                      control,
                      name: 'product',
                    }}
                    inputProps={{
                      placeholder: t('quotation_page.product_name'),
                      maxLength: 80,
                    }}
                  />
                </Col>
                <Col
                  xs={9}
                  sm={6}
                  md={8}
                  lg={6}
                  xl={5}
                  className="search_delivery_button"
                >
                  <Modal
                    modalProps={{
                      width: '100%',
                      style: { padding: 50, margin: 0 },
                      bodyStyle: { height: 'calc( 100vh - 100px )' },
                      className: 'product_modal',
                    }}
                    title={t('product.product_search')}
                    content={({ onClose }) => (
                      <ProductModal
                        onClose={onClose}
                        onSelectProduct={onSelectProduct}
                      />
                    )}
                  >
                    <Button type="primary">
                      {t('quotation_page.selection')}
                    </Button>
                  </Modal>
                </Col>
              </Row>
              <HalfWidthInputField<FormValue>
                label={t('quotation_page.finished_dimensions')}
                controller={{
                  control,
                  name: 'finished_dimensions',
                }}
                inputProps={{
                  placeholder: t(
                    'quotation_page.finished_dimensions_placeholder',
                  ),
                  maxLength: 150,
                }}
                labelCol={{ xs: 24, md: 6, xl: 5 }}
                wrapperCol={{ xs: 24, md: 16, xl: 18 }}
                className="search_input"
              />
              <SelectField<FormValue>
                controller={{
                  control,
                  name: 'status',
                }}
                selectProps={{
                  allowClear: true,
                  options: dataOrderStatus,
                }}
                labelCol={{ xs: 24, md: 6, xl: 5 }}
                wrapperCol={{ xs: 24, md: 16, xl: 18 }}
                label={t('quotation_page.status_of_ordered_items')}
              />
            </Col>
          </Row>
          <RadioGroupField<FormValue>
            label={t('quotation_page.express_condition')}
            controller={{
              name: 'express_condition',
              control,
            }}
            radioGroupProps={{
              options: EXPRESS_CONDITION(t),
            }}
            labelCol={{ xs: 24, md: 3, xl: 3 }}
            wrapperCol={{ xs: 24, md: 21, xl: 21 }}
            className="search_radio"
          />
          <Row className="mt-20">
            <Col className="search_row" xs={24} md={3} xl={3}>
              <Typography.Title className="label_sort_order" level={5}>
                {t('quotation_page.sort_order')}
              </Typography.Title>
            </Col>
            <Col xs={24} md={21} xl={21}>
              <RadioGroupField<FormValue>
                label={t('quotation_page.quotation_number_label')}
                controller={{
                  name: 'sort_condition',
                  control,
                  defaultValue: SORT.DESC,
                }}
                radioGroupProps={{
                  options: SORT_CONDITION(t),
                }}
                labelCol={{ xs: 9, md: 4, xl: 3 }}
                wrapperCol={{ xs: 15, md: 20, xl: 21 }}
                className="search_radio_quotation"
              />
            </Col>
          </Row>

          <Divider className="search_divider" />
          <div className="search_button">
            <Button type="primary" onClick={handleSubmit(handleSearch)}>
              {t('quotation_page.search')}
            </Button>
            <Button
              type="primary"
              className="ml-10 clear_search"
              onClick={handleClearParam}
            >
              {t('quotation_page.btn_reset')}
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default Search;
