import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import slide from 'src/repositories/slide';

const useSlides = () => {
  const apiPath = '/product/list/slide-show';

  const getSlides = useQuery<
    AxiosResponse<Response.ProductSlideInfo>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiPath],
    queryFn: slide.getSlides,
  });

  return { getSlides };
};

export default useSlides;
