import { useForm as useHookForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { t } from 'src/libs/i18n';
import dayjs from 'dayjs';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { mbStrWidth } from 'src/utils/helper';
import { REGEX } from 'src/constants/app';

export type FormValue = {
  registration_date_from: string;
  registration_date_to: string;
  order_number: string;
  quotation_number_from: string;
  quotation_number_to: string;
  delivery_name: string;
  product: string;
  person_in_charge: string;
  finished_dimensions: string;
  express_condition: string;
  sort_condition: string;
  status?: number;
};

const useForm = () => {
  const query = useQueryUrl();

  const defaultValues: FormValue = {
    registration_date_from: query.registration_date_from,
    registration_date_to: query.registration_date_to,
    order_number: query.order_number,
    quotation_number_from: query.quotation_number_from,
    quotation_number_to: query.quotation_number_to,
    delivery_name: query.delivery_name,
    product: query.product,
    person_in_charge: query.person_in_charge,
    finished_dimensions: query.finished_dimensions,
    express_condition: query.express_condition,
    sort_condition: query?.sort_condition,
  };

  const schema = Yup.object().shape({
    registration_date_from: Yup.date()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr)),
    registration_date_to: Yup.date()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .when('registration_date_from', (from_date, schema) => {
        return schema.test({
          test: function (to_date) {
            if (to_date && from_date) {
              if (dayjs(to_date).isBefore(dayjs(from_date))) {
                return this.createError({
                  message: t('message.E000068'),
                  path: 'registration_date_from',
                });
              }
            }
            return true;
          },
        });
      }),
    order_number: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test({
        test: function (val) {
          if (val) {
            if (mbStrWidth(val) > val.length) {
              return this.createError({
                message: t('message.E000017', {
                  param: t('quotation_page.your_order_number'),
                }),
                path: 'order_number',
              });
            }
          }
          return true;
        },
      }),
    quotation_number_from: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .min(
        8,
        t('message.E000018', {
          0: t('quotation_page.quotation_number_from'),
          1: t('quotation_page.8_digits'),
        }),
      ),
    quotation_number_to: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .min(
        8,
        t('message.E000018', {
          0: t('quotation_page.quotation_number_to'),
          1: t('quotation_page.8_digits'),
        }),
      )
      .when('quotation_number_from', (from_number, schema) => {
        return schema.test({
          test: function (to_number) {
            if (to_number && from_number) {
              if (Number(to_number) < Number(from_number)) {
                return this.createError({
                  message: t('message.E000069'),
                  path: 'quotation_number_from',
                });
              }
            }
            return true;
          },
        });
      }),
    finished_dimensions: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(
        REGEX.REGEX_DIMENSIONS,
        t('message.E000019', {
          0: t('quotation_page.finished_dimensions'),
        }),
      ),
    person_in_charge: Yup.string().test({
      test: function (val) {
        if (val) {
          if (mbStrWidth(val) > val.length && val.length > 15) {
            return this.createError({
              message: t('message.E000020', {
                param1: t('quotation_page.person_name_in_charge_error'),
                param2: '1',
                param3: '15',
                param4: '1',
                param5: '30',
              }),
              path: 'person_in_charge',
            });
          } else if (mbStrWidth(val) === val.length && val.length > 30) {
            return this.createError({
              message: t('message.E000020', {
                param1: t('quotation_page.person_name_in_charge_error'),
                param2: '1',
                param3: '15',
                param4: '1',
                param5: '30',
              }),
              path: 'person_in_charge',
            });
          }
        }
        return true;
      },
    }),
  });
  const formInfo = useHookForm<FormValue>({
    defaultValues,
    resolver: yupResolver(schema),
    shouldFocusError: true,
  });
  return { ...formInfo };
};

export default useForm;
