import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import pdfMenu from 'src/repositories/pdfMenu';

const apiKey = '/common/pdf-urls';

const usePdfMenu = (enabled) => {
  const { data, ...rest } = useQuery<
    AxiosResponse<Response.PDFFiles>,
    AxiosError<any>
  >([apiKey], pdfMenu.getFilesPDFMenu, {
    retry: 0,
    enabled: enabled,
    onError(err) {
      console.log(err.response);
    },
  });
  return {
    pdfFiles: data?.data?.items,
    ...rest,
  };
};

export default usePdfMenu;
