import React from 'react';

import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';
import Faq from 'src/containers/Faq';

const FAQ: React.FC = () => {
  useTitle(t('plugin.faq'));
  return <Faq />;
};

export const PATH = '/faq' as const;
export default FAQ;
