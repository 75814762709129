import React from 'react';
import OrderContainer from 'src/containers/Order';
import useTitle from '../../hooks/useTitle';
import { useTranslation } from 'react-i18next';

const OrderPage: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t('order_page.title'));
  return <OrderContainer />;
};

export const PATH = '/order' as const;
export default OrderPage;
