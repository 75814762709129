import React from 'react';
import { Layout } from 'antd';

import SignInContainer from 'src/containers/SignIn';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const SignInPage: React.FC = () => {
  useTitle(t('sign_in.page_title'));
  return (
    <Layout className="restrict_container">
      <SignInContainer />
    </Layout>
  );
};

export const PATH = '/sign_in' as const;
export default SignInPage;
