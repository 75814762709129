import React from 'react';
import { useTranslation } from 'react-i18next';
import { PicCenterOutlined, FilePdfOutlined } from '@ant-design/icons';

import SectionTitle from 'src/components/layout/SectionTitle';
import usePDF from 'src/queries/usePDF';

const PDFSection: React.FC = () => {
  const { t } = useTranslation();
  const { files } = usePDF();
  const iframeSrc = files?.fileDir
    ? `${files?.fileDir}#toolbar=0&navpanes=0&scrollbar=0&view=FitH&embedded=true`
    : '';

  return (
    <React.Fragment>
      <SectionTitle
        title={t('top_page.pdf.title')}
        icon={<PicCenterOutlined />}
        elmentRight={
          <a target={'_blank'} rel="noreferrer" href={files?.fileDir}>
            <FilePdfOutlined />
            <span>{t('top_page.pdf.pdf_view')}</span>
          </a>
        }
      />
      <iframe src={iframeSrc} height={360}></iframe>
    </React.Fragment>
  );
};

export default PDFSection;
