import history from 'src/libs/history';
import { PATH } from 'src/pages/auth';
import LocalStorage from 'src/utils/LocalStorage';

const logout = () => {
  LocalStorage.removeInfo();
  history.push(PATH.SIGN_IN);
};

export default logout;
