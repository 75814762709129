import React from 'react';
import { Button, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import qs from 'query-string';

import { InputField } from 'src/components/form';
import useForm, { FormValue } from './useForm';
import LocalStorage from 'src/utils/LocalStorage';
import history from 'src/libs/history';
import { PATH } from 'src/pages/category';
import { trim } from 'lodash';

const TopSearch: React.FC = () => {
  const { control, handleSubmit } = useForm();
  const { t } = useTranslation();
  const authInfo = LocalStorage.authInfo;
  const onSearch = (val) => {
    history.push({
      pathname: PATH,
      search: qs.stringify({ hanyomRan: trim(val.keyword) }),
    });
  };

  return (
    <div className="top_search">
      <div className="search-form">
        <Form>
          <InputField<FormValue>
            controller={{ control, name: 'keyword' }}
            inputProps={{
              placeholder: t('top_nav.search_placeholder'),
            }}
            required
          />
        </Form>
        <Button
          type="primary"
          size="small"
          className="w-full"
          onClick={handleSubmit((val) => onSearch(val))}
        >
          <SearchOutlined />
        </Button>
      </div>
      <div className="account-info">
        <p>{authInfo.smei}</p>
        <p>{`${t('top_nav.user_label')}: ${authInfo.userCd}`}</p>
      </div>
    </div>
  );
};

export default TopSearch;
