import { Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';

import { Info } from 'src/components/icon';

type Props = {
  content: string;
  tooltip?: boolean;
  className?: string;
};

const ErrorMessage: React.FC<Props> = ({
  content,
  tooltip = true,
  className,
}) => {
  return (
    <Typography.Text
      className={classNames(
        'ant-form-item-explain ant-form-item-explain-error',
        className,
      )}
      type="danger"
      ellipsis={{ tooltip }}
    >
      <Info />
      {content}
    </Typography.Text>
  );
};

export default ErrorMessage;
