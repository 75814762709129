import React from 'react';
import { Layout } from 'antd';

import MenuNavigation from './MenuNavigation';
import NotificationList from './NotificationList';
import CategoryList from './CategoryList';
import PDFSection from './PDFSection';
import Calendar from './Calendar';
import ProductSlides from './ProductSlides';

const Home: React.FC = () => {
  return (
    <Layout className="content_home">
      <MenuNavigation />
      <ProductSlides />
      <NotificationList />
      <CategoryList />
      <PDFSection />
      <Calendar />
    </Layout>
  );
};

export default Home;
