import { Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  type: 'unexpired' | 'expired' | 'ordered';
  status?: string;
};

const ItemLabel: React.FC<Props> = ({ type, status }) => {
  const { t } = useTranslation();

  return (
    <Row className="item_label">
      <Typography.Title level={5} className="item_label_title">
        {type === 'unexpired'
          ? t('quotation_page.within_the_expiration_date')
          : type === 'expired'
          ? t('quotation_page.expired')
          : `${t('quotation_page.ordered')}${status ? `（${status}）` : ''}`}
      </Typography.Title>
    </Row>
  );
};

export default ItemLabel;
