import { Tag, Typography } from 'antd';
import React from 'react';

type Props = {
  color?: 'default' | 'blue' | 'green';
  label: string;
  value: string;
};

const ItemSubLabel: React.FC<Props> = (props) => {
  const { color = 'blue', label, value } = props;

  return (
    <div className="item_data_label">
      <Tag color={color}>{label}</Tag>
      <Typography.Text>{value}</Typography.Text>
    </div>
  );
};

export default ItemSubLabel;
