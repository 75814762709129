import React from 'react';
import { Layout } from 'antd';

import useTitle from 'src/hooks/useTitle';
import PricingContainer from 'src/containers/Pricing';
import { t } from 'src/libs/i18n';

const PricingTrialPage: React.FC = () => {
  useTitle(t('pricing_page.page_trial_title'));
  return (
    <Layout className={'layout-container'}>
      <div className={'container-lg'}>
        <PricingContainer isTrialMode={true} />
      </div>
    </Layout>
  );
};

export const PATH = '/pricing_trial' as const;
export default PricingTrialPage;
