import React from 'react';

import { PATH } from 'src/pages/home';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';
import { SCREENS } from 'src/constants/screens';

const DashboardRoute: IRouter[] = [
  {
    path: PATH,
    element: <Layout />,
    children: [
      {
        index: true,
        element: loadable(() => import('src/pages/home')),
        screenId: SCREENS.R1002,
      },
    ],
  },
];

export default DashboardRoute;
