import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Row, Col, Button, Divider, Form, Tabs } from 'antd';

import useForm from './useForm';
import Sides from './Sides';
import { SIDE_TYPE } from 'src/constants/app';
import { RowDetail } from 'src/components/common';

type Props = {
  onClose?: any;
  onSelect?: any;
  productName?: string;
  processingMethod?: string;
  screenId?: string;
};

const { TabPane } = Tabs;

const ChamferModal: React.FC<Props> = ({
  onClose,
  onSelect,
  productName,
  processingMethod,
}) => {
  const { t } = useTranslation();
  const [activeChamfer, setActiveChamfer] = useState<Response.ChamferItem>();
  const [chooseTabId, setChooseTabId] = useState<number>(SIDE_TYPE.TWO_SIDES);
  const { handleSubmit, control } = useForm();
  const changeTab = (currentTabId) => {
    setChooseTabId(currentTabId);
  };

  const handleSubmitForm = (val) => {
    onSelect(activeChamfer);
    onClose();
  };

  const handleSelectAll = () => {
    onSelect(null);
    onClose();
  };

  return (
    <React.Fragment>
      <Form onFinish={handleSubmit((val) => handleSubmitForm(val))}>
        <div className="chamfer_modal p-20">
          <RowDetail
            title={t('chamfer_modal.selected_product')}
            value={productName || null}
            layout={{
              titleCol: false,
              valueCol: false,
            }}
          />
          <RowDetail
            title={t('chamfer_modal.processing_being_selected')}
            value={processingMethod || null}
            layout={{
              titleCol: false,
              valueCol: false,
            }}
          />
          <Row className="mt-24">
            <Col xl={24} sm={24} span={24}>
              <Tabs
                onChange={changeTab}
                type="card"
                tabBarGutter={0}
                tabBarExtraContent={{
                  right: (
                    <Button
                      onClick={handleSelectAll}
                      className="button-specified-all"
                      type="default"
                    >
                      {t('chamfer_modal.not_specified')}
                    </Button>
                  ),
                }}
                defaultActiveKey="1"
                tabPosition={'top'}
              >
                <TabPane tab={t('chamfer_modal.tabs.2sides')} key={'2'}>
                  <Sides
                    control={control}
                    type={SIDE_TYPE.TWO_SIDES}
                    activeChamfer={activeChamfer}
                    handleActiveChamfer={setActiveChamfer}
                    chooseTabId={chooseTabId}
                  />
                </TabPane>
                <TabPane tab={t('chamfer_modal.tabs.3sides')} key={'3'}>
                  <Sides
                    control={control}
                    activeChamfer={activeChamfer}
                    handleActiveChamfer={setActiveChamfer}
                    type={SIDE_TYPE.THREE_SIDES}
                    chooseTabId={chooseTabId}
                  />
                </TabPane>
                <TabPane tab={t('chamfer_modal.tabs.4sides')} key={'4'}>
                  <Sides
                    control={control}
                    activeChamfer={activeChamfer}
                    handleActiveChamfer={setActiveChamfer}
                    type={SIDE_TYPE.FOUR_SIDES}
                    chooseTabId={chooseTabId}
                  />
                </TabPane>
                <TabPane tab={t('chamfer_modal.tabs.5sides')} key={'5'}>
                  <Sides
                    control={control}
                    activeChamfer={activeChamfer}
                    handleActiveChamfer={setActiveChamfer}
                    type={SIDE_TYPE.SIX_SIDES}
                    chooseTabId={chooseTabId}
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
          <Row align="middle" justify="center">
            <Divider className="mb-30" />
            <Button
              className="button_submit_notification"
              type="primary"
              onClick={handleSubmit(handleSubmitForm)}
            >
              {t('chamfer_modal.submit')}
            </Button>
          </Row>
        </div>
      </Form>
    </React.Fragment>
  );
};

export default ChamferModal;
