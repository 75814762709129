import { Layout } from 'antd';
import React from 'react';

import PolicyContainer from 'src/containers/Policy';
import useTitle from 'src/hooks/useTitle';
import { t } from 'src/libs/i18n';

const Policy: React.FC = () => {
  useTitle(t('policy_page.title'));
  return (
    <Layout className="restrict_container">
      <PolicyContainer />
    </Layout>
  );
};

export const PATH = '/policy' as const;
export default Policy;
