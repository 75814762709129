import React, { useEffect } from 'react';
import { Button, Row, Col, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { UseFormReturn } from 'react-hook-form';

import { InputField } from 'src/components/form';
import { FormValue } from '../useForm';
import { DestinationModal } from 'src/components/common';
import Modal from 'src/components/custom/Modal';
import { SCREENS } from 'src/constants/screens';
import useAccountSetting from 'src/queries/useAccountSetting';
import { useLocation } from 'react-router-dom';
import { checkChangeLanguage } from 'src/utils/helper';

type Props = {
  formInfo: UseFormReturn<FormValue, any>;
  dataReEstimate?: Response.EstimatePriceDetail['item'];
  estimateId?: string;
  prevLang: string;
  lang: string;
};

const CustomerInfo: React.FC<Props> = ({
  formInfo,
  dataReEstimate,
  estimateId,
  prevLang,
  lang,
}) => {
  const { control, setValue, getValues, clearErrors } = formInfo;
  const { t } = useTranslation();
  const { queryAccount } = useAccountSetting({});
  const location = useLocation();

  useEffect(() => {
    // auto fill value when re estimate from order history page
    if (dataReEstimate?.tantoMei && !getValues('customer_name')) {
      setValue('customer_name', dataReEstimate?.tantoMei);
    }
    if (dataReEstimate?.rmOtokesaki && !getValues('destination')) {
      onChooseDestination(dataReEstimate);
    }
  }, [dataReEstimate]);

  // get default address from api user setting.
  const accountInfo = queryAccount?.data?.data?.item;
  const destinationInfo = {
    rmOtokesaki: {
      otokesakiCd: accountInfo?.otokesakiCd,
      otokesakiNm: accountInfo?.otokesakiNm,
      otodokesakiJusho: accountInfo?.otodokesakiJusho,
    },
  };

  useEffect(() => {
    // get default address if not re-estimate from order history page
    if (!estimateId) {
      getDefaultAddress();
    }
  }, [accountInfo?.otokesakiCd]);

  useEffect(() => {
    // get default address if Change language
    if (checkChangeLanguage(prevLang, lang)) {
      getDefaultAddress();
    }
  }, [lang]);

  useEffect(() => {
    // get default address if click Quotation/Order Tab
    if (!location?.search) {
      getDefaultAddress();
    }
  }, [location?.search]);

  //get default address
  const getDefaultAddress = () => {
    if (accountInfo?.otokesakiCd) {
      onChooseDestination(destinationInfo);
    } else {
      setValue('destination', '');
      setValue('destinationItem', undefined);
      clearErrors('destination');
    }
  };

  const onChooseDestination = (destination) => {
    setValue('destination', getDestinationInputValue(destination));
    setValue('destinationItem', destination);
    clearErrors('destination');
  };

  const getDestinationInputValue = (destination) => {
    return `${destination?.rmOtokesaki?.otokesakiCd} ${destination?.rmOtokesaki?.otokesakiNm} ${destination?.rmOtokesaki?.otodokesakiJusho}`;
  };

  return (
    <React.Fragment>
      <Row>
        <Col xs={24} md={24} xl={24}>
          <InputField<FormValue>
            controller={{ control, name: 'customer_name' }}
            inputProps={{
              placeholder: t('pricing_page.user_name_field'),
              maxLength: 30,
            }}
            label={t('pricing_page.user_name_field')}
            labelCol={{ xs: 6, sm: 24, md: 4, xl: 3 }}
            wrapperCol={{ xs: 18, sm: 24, md: 20, xl: 11 }}
            className="input_tag"
          />
        </Col>
      </Row>
      <Row align="top">
        <Col xs={24} md={4} xl={3}>
          <Typography.Title level={5} className="form_label_required">
            {t('pricing_page.user_destination_field')}
          </Typography.Title>
        </Col>
        <Col xs={16} sm={19} md={16} xl={18} className="user_destination">
          <InputField<FormValue>
            controller={{ control, name: 'destination' }}
            inputProps={{
              placeholder: t('pricing_page.user_destination_field'),
              minLength: 1,
              maxLength: 200,
            }}
            required
            className="user_destination_input"
          />
        </Col>
        <Col xs={8} sm={5} md={4} xl={3} className="user_destination_action">
          <Modal
            modalProps={{ width: 840 }}
            title={t('pricing_page.destination_modal.title')}
            content={({ onClose }) => (
              <DestinationModal
                screenId={SCREENS.R2001}
                onClose={onClose}
                onSelect={onChooseDestination}
              />
            )}
          >
            <Button type="primary" className="destination_button">
              {t('pricing_page.user_destination_button')}
            </Button>
          </Modal>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default CustomerInfo;
