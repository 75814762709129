import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { API_KEY } from 'src/constants/apiKey';

import quotation from 'src/repositories/quotation';

type UseConvertSize = {
  handleSuccess: (
    res: AxiosResponse<Response.ConvertSize, any>,
    args: Payload.ConvertSize,
  ) => void;
};

const useConvertSizeMutation = ({ handleSuccess }: UseConvertSize) => {
  const convertSizeMutation = useMutation<
    AxiosResponse<Response.ConvertSize>,
    AxiosError<Response.Error>,
    Payload.ConvertSize
  >({
    mutationKey: [API_KEY.CONVERT_SIZE],
    mutationFn: (args) => {
      return quotation.convertSize(API_KEY.CONVERT_SIZE, args);
    },
    onSuccess: (res, args) => handleSuccess(res, args),
    onError: (error) => {
      console.log(error.response);
    },
  });

  return { convertSizeMutation };
};

export default useConvertSizeMutation;
