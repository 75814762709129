import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { Navigate, Outlet } from 'react-router-dom';

import { BackToTop } from 'src/components/common';
import AppMenu from 'src/components/layout/Menu';
import AppFooter from 'src/components/layout/Footer';
import TopNav from 'src/components/layout/TopNav';
import LocalStorage from 'src/utils/LocalStorage';
import { PATH } from 'src/pages/auth';
import usePolicy from 'src/queries/usePolicy';
import history from 'src/libs/history';
import { PATH as POLICY_PATH } from 'src/pages/policy';
import { IS_CHECK_POLICY } from 'src/constants/app';
import LoadingSuspense from 'src/components/applications/LoadingSuspense';

type Props = {
  hasHeader?: boolean;
  hasMenu?: boolean;
  headerClass?: string;
  requireAuth?: boolean;
};

const AppLayout: React.FC<Props> = ({
  hasHeader = true,
  hasMenu = true,
  requireAuth = true,
}) => {
  if (requireAuth) {
    const accessToken = LocalStorage.accessToken;
    if (!accessToken) {
      return <Navigate to={PATH.SIGN_IN} replace />;
    }
  }

  const { getCustomerInfo } = usePolicy();
  const customerInfo = getCustomerInfo?.data?.data?.item;

  useEffect(() => {
    customerInfo?.policyCheck === IS_CHECK_POLICY.FALSE &&
      history.push(POLICY_PATH);
  }, [customerInfo]);

  if (getCustomerInfo.isLoading) {
    return <LoadingSuspense />;
  }

  return (
    <Layout>
      {hasHeader && <TopNav />}
      {hasMenu && <AppMenu />}
      <Layout className={'layout-container'}>
        <div className={'container-lg min-h-100'}>
          <Outlet />
        </div>
      </Layout>
      <AppFooter />
      <BackToTop />
    </Layout>
  );
};

export default AppLayout;
