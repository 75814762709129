import React, { Dispatch, SetStateAction } from 'react';
import { Col } from 'antd';
import { Control } from 'react-hook-form/dist/types/form';

import { InputField } from 'src/components/form';
import { FormValue } from '../useForm';

type Props = {
  control: Control<FormValue, any>;
  chamferImage: Response.ChamferItem;
  handleActiveChamfer: Dispatch<
    SetStateAction<Response.ChamferItem | undefined>
  >;
  activeChamfer: Response.ChamferItem | undefined;
};

const ChamferItem: React.FC<Props> = (props) => {
  const { chamferImage, handleActiveChamfer, activeChamfer, control } = props;

  return (
    <Col key={chamferImage.seqno} xs={12} md={6} sm={8} xl={6}>
      <InputField<FormValue>
        inputProps={{
          hidden: true,
        }}
        controller={{
          control,
          name: 'chamfer',
          defaultValue: chamferImage,
        }}
      />
      <div
        onClick={() => handleActiveChamfer(chamferImage)}
        className={`chamfer_modal_container ${
          chamferImage.seqno === activeChamfer?.seqno ? 'active' : ''
        }`}
      >
        <div className="chamfer_modal_image">
          <img src={chamferImage.imageFile} alt="" />
        </div>
        <div className="description">{`${chamferImage?.setdnKakoCd} ${chamferImage?.fraiseKakoPtnMeis}`}</div>
      </div>
    </Col>
  );
};

export default ChamferItem;
