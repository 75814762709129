import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import guest from 'src/repositories/guest';
import { createFileFromString } from 'src/utils/helper';

export const apiKey = '/price/guest/customer-info' as const;
export const apiKeyPDF = '/price/guest/pdf' as const;

const useRegisterGuest = (setDataGuest: any) => {
  const registerGuest = useMutation<
    AxiosResponse<Response.SignInData>,
    AxiosError,
    Payload.TGuest
  >({
    mutationKey: [apiKey],
    mutationFn: (args) => guest.createGuest(apiKey, args),
    retry: 0,
    async onSuccess(res) {
      setDataGuest(res.data);
    },
    onError: (error) => {
      console.log(error.response);
    },
  });

  const downloadPDF = useMutation<
    AxiosResponse<any>,
    AxiosError,
    Payload.TQuotationPDF
  >({
    mutationKey: [apiKeyPDF],
    mutationFn: (args) => guest.dowloadPDFQoutation(apiKeyPDF, args),
    retry: 0,
    async onSuccess(res) {
      createFileFromString(res.data, 'Quotation.pdf');
    },
    onError: (error) => {
      console.log(error.response);
    },
  });

  return {
    registerGuest,
    downloadPDF,
  };
};

export default useRegisterGuest;
