import React from 'react';
import {
  Col,
  Form,
  FormItemProps,
  DatePickerProps,
  Row,
  Typography,
  Button,
  DatePicker,
} from 'antd';
import { useController, UseControllerProps } from 'react-hook-form';
import dayjs from 'dayjs';
import { CloseCircleOutlined, SwapRightOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT } from 'src/constants/app';
import { validateFormat } from 'src/utils/helper';

type Props<T> = {
  datePickerPropsFrom?: DatePickerProps;
  datePickerPropsTo?: DatePickerProps;
  controllerFrom: UseControllerProps<T>;
  controllerTo: UseControllerProps<T>;
  errorCol?: 12 | 24;
} & Omit<FormItemProps, 'children'>;

function DateRangePickerField<T extends Record<string, any>>(props: Props<T>) {
  const {
    datePickerPropsFrom,
    datePickerPropsTo,
    controllerFrom,
    controllerTo,
    errorCol = 24,
    label,
    ...rest
  } = props;

  const { t } = useTranslation();

  const { fieldState: fieldStateFrom, field: fieldFrom } =
    useController<T>(controllerFrom);
  const { fieldState: fieldStateTo, field: fieldTo } =
    useController<T>(controllerTo);
  const { isTouched: isTouchedFrom, error: errorFrom } = fieldStateFrom;
  const { isTouched: isTouchedTo, error: errorTo } = fieldStateTo;

  const format = datePickerPropsFrom?.format?.toString() || DATE_FORMAT;
  const firstDateOfMonth = dayjs().startOf('month').format(format);
  const currentDate = dayjs().format(format);

  const handleBlur = (e, field) => {
    const value = e.currentTarget.value;
    if (value && !validateFormat(value, format)) {
      setTimeout(() => {
        field.onChange(firstDateOfMonth);
      });
    } else {
      field.onChange(value);
    }
  };

  const handleKeyDown = (e, field) => {
    if (e.key === 'Enter') {
      handleBlur(e, field);
    }
  };

  const handleClear = (e, field) => {
    field.onChange(currentDate);
  };

  return (
    <Form.Item
      colon={false}
      label={label}
      labelAlign="left"
      labelCol={label ? { span: 24 } : undefined}
      wrapperCol={label ? { span: 24 } : undefined}
      validateStatus={
        (isTouchedFrom || isTouchedTo) && (errorFrom || errorTo) ? 'error' : ''
      }
      {...rest}
      className="date_range_picker"
    >
      <Row gutter={4}>
        <Col span={errorCol}>
          <Row gutter={8} justify="space-between">
            <Col span={11}>
              <DatePicker
                style={{ width: '100%' }}
                {...datePickerPropsFrom}
                {...fieldFrom}
                format={datePickerPropsFrom?.format || DATE_FORMAT}
                onChange={(e, dateString) => {
                  fieldFrom.onChange(dateString);
                }}
                onBlur={(event) => handleBlur(event, fieldFrom)}
                onKeyDown={(event) => handleKeyDown(event, fieldFrom)}
                value={fieldFrom.value ? dayjs(fieldFrom.value) : undefined}
                clearIcon={
                  <CloseCircleOutlined
                    onClick={(e) => handleClear(e, fieldFrom)}
                  />
                }
                renderExtraFooter={
                  // @ts-ignore
                  datePickerPropsFrom?.showToday === false
                    ? undefined
                    : () => (
                        <Button
                          className="my-custom-footer"
                          type="link"
                          onClick={() => {
                            fieldFrom.onChange(dayjs().format(format));
                          }}
                        >
                          {t('button.today')}
                        </Button>
                      )
                }
                // @ts-ignore
                showToday={false}
              />
            </Col>
            <Col span={2} style={{ margin: 'auto', textAlign: 'center' }}>
              <SwapRightOutlined />
            </Col>
            <Col span={11}>
              <DatePicker
                style={{ width: '100%' }}
                {...datePickerPropsTo}
                {...fieldTo}
                format={datePickerPropsTo?.format || DATE_FORMAT}
                onChange={(e, dateString) => {
                  fieldTo.onChange(dateString);
                }}
                onBlur={(event) => handleBlur(event, fieldTo)}
                onKeyDown={(event) => handleKeyDown(event, fieldTo)}
                value={fieldTo.value ? dayjs(fieldTo.value) : undefined}
                clearIcon={
                  <CloseCircleOutlined
                    onClick={(e) => handleClear(e, fieldTo)}
                  />
                }
                renderExtraFooter={
                  // @ts-ignore
                  datePickerPropsTo?.showToday === false
                    ? undefined
                    : () => (
                        <Button
                          className="my-custom-footer"
                          type="link"
                          onClick={() => {
                            fieldTo.onChange(dayjs().format(format));
                          }}
                        >
                          {t('button.today')}
                        </Button>
                      )
                }
                // @ts-ignore
                showToday={false}
              />
            </Col>
          </Row>
        </Col>
        <Col span={errorCol}>
          {(errorFrom || errorTo) && (
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error"
              type="danger"
              ellipsis={{ tooltip: true }}
            >
              {errorTo && errorFrom
                ? errorFrom.message
                : (errorFrom && errorFrom.message) ||
                  (errorTo && errorTo.message)}
            </Typography.Text>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
}

export default DateRangePickerField;
