import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import order from '../repositories/order';

const GET_WEB_MITR = '/order/estimations';
const REQUEST_ORDER_PATH = '/order';

export const getKey = (webMitrNo: string[]) => {
  const params = new URLSearchParams();
  for (let no in webMitrNo) {
    params.append('webMitrNo', webMitrNo[no]);
  }
  return `${GET_WEB_MITR}?${params.toString()}`;
};

type SuccessResponse = () => void;
type ErrorResponse = (error: AxiosError<Response.Error>) => void;

const useOrder = (
  webMitrNo: string[],
  handleSuccess?: SuccessResponse,
  handleError?: ErrorResponse,
) => {
  const key = getKey(webMitrNo);
  const getWebMitr = useQuery<
    AxiosResponse<Response.GetWebMitrResponse>,
    AxiosError<Response.Error>
  >({
    queryKey: [key],
    queryFn: order.getWebMitr,
  });

  const requestOrder = useMutation<
    AxiosResponse<{}>,
    AxiosError<Response.Error>,
    Payload.OrderReq
  >({
    mutationKey: [REQUEST_ORDER_PATH],
    mutationFn: (args) => {
      return order.requestOrder(REQUEST_ORDER_PATH, args);
    },
    onSuccess: (res) => {
      if (handleSuccess) {
        handleSuccess();
      }
    },
    onError: (error) => {
      if (handleError) {
        handleError(error);
      }
    },
  });

  return {
    getWebMitr,
    requestOrder,
  };
};

export default useOrder;
