import { useMutation } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { API_KEY } from 'src/constants/apiKey';

import quotation from 'src/repositories/quotation';

type UseManufacturingSizeDomestic = {
  handleSuccess: (
    res: AxiosResponse<Response.ManufacturingSize2AndSize3, any>,
    args: Payload.ManufacturingSizeDomestic,
  ) => void;
};

const useManufacturingSizeDomestic = ({
  handleSuccess,
}: UseManufacturingSizeDomestic) => {
  const getManufacturingSizeDomesticMutation = useMutation<
    AxiosResponse<Response.ManufacturingSize2AndSize3>,
    AxiosError<Response.Error>,
    Payload.ManufacturingSizeDomestic
  >({
    mutationKey: [API_KEY.MANUFACTURING_SIZES_Z0],
    mutationFn: (args) => {
      return quotation.getManufacturingSizeDomestic(
        API_KEY.MANUFACTURING_SIZES_Z0,
        args,
      );
    },
    onSuccess: (res, args) => handleSuccess(res, args),
    onError: (error) => {
      console.log(error.response);
    },
  });

  return { getManufacturingSizeDomesticMutation };
};

export default useManufacturingSizeDomestic;
