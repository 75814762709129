import { Button, Card, Col, Row } from 'antd';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import usePDF from 'src/queries/usePDF';
import history from 'src/libs/history';
import usePolicy from 'src/queries/usePolicy';
import logout from 'src/utils/logout';

import { PATH as HOME_PATH } from 'src/pages/home';
import { IS_CHECK_POLICY } from 'src/constants/app';

const PolicyContainer: React.FC = () => {
  const { t } = useTranslation();
  const { files } = usePDF();
  const iframeSrc = files?.fileDir
    ? `${files?.fileDir}#toolbar=0&navpanes=0&scrollbar=0&view=FitH&embedded=true`
    : '';

  const { agreePolicy, getCustomerInfo } = usePolicy();

  const customerInfo = getCustomerInfo?.data?.data?.item;

  const handleDisAgree = () => {
    logout();
  };

  const handleAgree = () => {
    agreePolicy.mutate();
  };

  useEffect(() => {
    customerInfo?.policyCheck === IS_CHECK_POLICY.TRUE &&
      history.push(HOME_PATH);
  }, [customerInfo]);

  return (
    <React.Fragment>
      <div className="policy_page">
        <Card className="policy_page_card">
          <div className="iframe_section">
            <iframe src={iframeSrc}></iframe>
          </div>
          <Row justify="center" gutter={20} className="button_section">
            <Col>
              <Button
                type="primary"
                className="w-full button_agree"
                onClick={handleAgree}
              >
                {t('policy_page.agree')}
              </Button>
            </Col>
            <Col>
              <Button
                type="primary"
                className="w-full button_disagree"
                onClick={handleDisAgree}
              >
                {t('policy_page.disagree')}
              </Button>
            </Col>
          </Row>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default PolicyContainer;
