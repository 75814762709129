import React from 'react';
import { Row, Col, Image, Typography, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { PATH } from 'src/pages/productDetails';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { handleRedirectProductDetail, openPDF } from 'src/utils/helper';

type Props = {
  product: Response.ProductItem;
  isShowDetailProductLink?: boolean;
};

const ProductTooltip: React.FC<Props> = ({
  product,
  isShowDetailProductLink = false,
}) => {
  const { t } = useTranslation();
  const query = useQueryUrl();
  const location = useLocation();

  return (
    <Row>
      {(product?.shhnImageDir || product?.hakudoShhnImageDir) && (
        <Col>
          <Image
            preview={false}
            width={90}
            src={product?.shhnImageDir || product?.hakudoShhnImageDir}
          />
        </Col>
      )}
      <Col>
        {product.catchCopy && (
          <Row className="tooltip_description">
            <Typography.Text className="product_tooltip_des">
              {product.catchCopy}
            </Typography.Text>
          </Row>
        )}
        {isShowDetailProductLink ? (
          <Row>
            <Button
              type="link"
              className="category_link_product"
              onClick={() =>
                handleRedirectProductDetail(
                  product,
                  query?.hinshL3Cd,
                  PATH.replace(':origin', location.pathname),
                )
              }
            >
              {t('category.open_product_info')}
            </Button>
          </Row>
        ) : (
          product.shhnPdfDir && (
            <Row>
              <Button
                type="link"
                className="category_link_product"
                onClick={() =>
                  product.shhnPdfDir && openPDF(product.shhnPdfDir)
                }
              >
                {t('category.open_product_info')}
              </Button>
            </Row>
          )
        )}
      </Col>
    </Row>
  );
};

export default ProductTooltip;
