import { yupResolver } from '@hookform/resolvers/yup';
import { useForm as useHookForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { REGEX } from 'src/constants/app';
import * as Yup from 'yup';

export type FormNonUserValue = {
  mail?: string;
  smei?: string;
};

const useFormNonUser = () => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    mail: Yup.string()
      .nullable(true)
      .transform((curr, orig) => (orig === '' ? null : curr))
      .matches(
        REGEX.REGEX_EMAIL_MATCHES,
        t('message.E000033', { 0: t(`label.email`) }),
      ),
    smei: Yup.string(),
  });

  const form = useHookForm<FormNonUserValue>({
    resolver: yupResolver(schema),
    shouldFocusError: true,
    mode: 'onChange',
  });
  return { form };
};

export default useFormNonUser;
