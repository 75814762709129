import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getProductCategory = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const getProductList = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const getVariety = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const getProductVariety = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

export default {
  getProductCategory,
  getVariety,
  getProductList,
  getProductVariety,
};
