import React from 'react';
import { Button, Col, Form, Row } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import useForm, { FormValue } from './useForm';
import { InputField } from 'src/components/form';
import useInitLogin from 'src/queries/useInitLogin';
import useSignIn from 'src/queries/useSignIn';
import { PATH } from 'src/pages/auth';
import { PATH as PATH_QUOTATION_TRIAL } from 'src/pages/pricingTrial';
import { SignInState } from 'src/interfaces/route';
import { encryptData } from 'src/utils/helper';

const SignIn: React.FC = () => {
  const location = useLocation();
  const state = location?.state as SignInState;
  const { t } = useTranslation();
  const { control, handleSubmit, setError } = useForm();
  const { data } = useInitLogin();
  const mutation = useSignIn(setError, data);

  const handleLogin = ({ email, password }) => {
    const payload = {
      email: email,
      password: encryptData(password),
    };
    mutation.mutate(payload);
  };

  return (
    <React.Fragment>
      <div className="signin_row">
        {data?.rmOsirase?.rmOsiraseContent?.osirase && (
          <Row justify="center" align="middle">
            <div className="message">
              <div
                className="message_content"
                dangerouslySetInnerHTML={{
                  __html: data?.rmOsirase?.rmOsiraseContent?.osirase,
                }}
              ></div>
            </div>
          </Row>
        )}
        <Row justify="center" align="middle">
          <div className="signin_container">
            <Row justify="center">
              <Col span={18} className="text-center">
                {data?.rmSiteKihon?.ecsiteLogoDir && (
                  <Link to={PATH.SIGN_IN}>
                    <img
                      src={data?.rmSiteKihon?.ecsiteLogoDir}
                      alt="logo"
                      className="logo_signin"
                    />
                  </Link>
                )}
              </Col>
            </Row>
            <Row justify="center">
              <Col span={24}>
                {state?.state?.isLock && (
                  <p className="signin_title">{t('sign_in.title_lock')}</p>
                )}
                {state?.state?.isSectionTimeout && (
                  <p className="signin_title">
                    {t('sign_in.title_section_timeout')}
                  </p>
                )}
                {!state?.state && (
                  <p className="signin_title">{t('sign_in.title')}</p>
                )}
              </Col>
            </Row>
            <Form
              layout="vertical"
              onFinish={handleSubmit((val) => handleLogin(val))}
            >
              <InputField<FormValue>
                controller={{ control, name: 'email' }}
                inputProps={{
                  placeholder: t('auth_form.email_placeholder'),
                  maxLength: 80,
                }}
                required
              />
              <InputField<FormValue>
                controller={{ control, name: 'password' }}
                inputProps={{
                  placeholder: t('auth_form.password_placeholder'),
                  type: 'password',
                  maxLength: 88,
                }}
                required
              />
              <Row justify="center">
                <Col span={24}>
                  <Link to={PATH.FORGOT_PASSWORD}>
                    <p className="signin_forgot">
                      {t('sign_in.reset_password_text')}
                    </p>
                  </Link>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={12}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-full"
                    loading={mutation.isLoading}
                  >
                    {t('sign_in.button')}
                  </Button>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={24}>
                  <p className="caulate_trial">
                    {t('sign_in.start_trial_text')}
                    <Link to={PATH_QUOTATION_TRIAL}>
                      <span className="caulate_trial_link">
                        {t('pricing_page.page_trial_title')}
                      </span>
                    </Link>
                  </p>
                </Col>
              </Row>
              <Row justify="center">
                <Col span={24} className="signin_note">
                  <p>{t('sign_in.browser_note')}</p>
                  <p>・{t('sign_in.chrome_text')}</p>
                </Col>
              </Row>
            </Form>
          </div>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default SignIn;
