import { Col, Row, Typography } from 'antd';
import { AxiosResponse } from 'axios';
import _ from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AutoSuggestField,
  NumberOnlyField,
  SelectField,
} from 'src/components/form';
import {
  FLAT_PIPE_PRODUCT,
  MACHINING_METHOD,
  REGEX,
  ROUND,
  SHAPE,
} from 'src/constants/app';
import useConvertSize from 'src/hooks/useConvertSize';
import useManufacturingSize from 'src/queries/useManufacturingSize';
import useManufacturingSizeDomestic from 'src/queries/useManufacturingSizeDomestic';
import useManufacturingImport from 'src/queries/useManufacturingSizeImport';
import useProductMachining from 'src/queries/useProductMachining';
import {
  checkChangeLanguage,
  convertDimensionsToArray,
  convertShiagsnpoHyojiMojiretsToArray,
  formatManufacturingSizes,
  roundSizeForMachiningMethodFromH2ToH5,
  getSizes,
  isDomesticProduct,
  isImportedProduct,
  removeDuplicateObject,
  removeZeroAndDuplicateItem,
  renderToleranceOptions,
  checkHasDomesticData,
} from 'src/utils/helper';
import { productFormat } from 'src/utils/product';
import {
  getImagePreviewPath,
  getProductUnit,
  getSizeByLabelInput,
} from 'src/utils/quotation';
import { FormValue } from '../useForm';
import useSizeInputDisplay from 'src/hooks/useSizeInputDisplay';
import useTolerance from 'src/queries/useTolerance';

type Props = {
  formInfo: UseFormReturn<FormValue, any>;
  product: string;
  machiningMethod: string;
  setLabelError: (e) => void;
  setEstimateErrors: (e) => void;
  setSizes2: (e) => void;
  setSizes3: (e) => void;
  setStockError: (e) => void;
  estimatePriceData?: Response.EstimatePrice['item'];
  dataReEstimate?: Response.EstimatePriceDetail['item'];
  setManufacturingSizes: (e) => void;
  prevLang: string;
  lang: string;
  shape: string;
  dataProductDimension?: Response.ProductDimension['item'];
  isProductFinished: boolean;
  productFinishedInfor: Response.ProductFinishedItem[];
  productWidthData: any;
  productLengthData: any;
  setProductWidthData: (e) => void;
  setProductLengthData: (e) => void;
  setToleranceOptions: (e) => void;
  resetInputData: () => void;
};

const ManuFacturingSize: React.FC<Props> = ({
  formInfo,
  product,
  machiningMethod,
  setLabelError,
  setEstimateErrors,
  setSizes2,
  setSizes3,
  setStockError,
  dataReEstimate,
  estimatePriceData,
  setManufacturingSizes,
  prevLang,
  lang,
  shape,
  dataProductDimension,
  isProductFinished,
  productFinishedInfor,
  productWidthData,
  productLengthData,
  setProductWidthData,
  setProductLengthData,
  setToleranceOptions,
  resetInputData,
}) => {
  const { control, getValues, setValue, clearErrors } = formInfo;
  const { t } = useTranslation();

  const { machiningsData: machinings } = useProductMachining(product);
  const { manufacturingSizesData: manufacturingSizes, isFetching } =
    useManufacturingSize(
      product,
      machiningMethod,
      setEstimateErrors,
      setStockError,
      isProductFinished,
    );

  const [disableProductWidthInput, setDisableProductWidthInput] =
    useState(false);
  const [disableProductLengthInput, setDisableProductLengthInput] =
    useState(false);
  const [clearManufacturingSizes, setClearManufacturingSizes] = useState(false);
  const [reEstimateWidth, setReEstimateWidth] = useState('');
  const [reEstimateLength, setReEstimateLength] = useState('');
  const [hasReEstimateThickness, setHasReEstimateThickness] = useState(false);
  const [isBothTypes, setIsBothTypes] = useState(false);
  const [isToggleOnBlurSize1, setIsToggleOnBlurSize1] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const productWidthDataRef = useRef(productWidthData);
  const productLengthDataRef = useRef(productLengthData);
  const machiningMethodOfZ0AndH2ToH5 = [
    MACHINING_METHOD.Z0,
    ...MACHINING_METHOD.H2TOH5,
  ];
  const listOptionProductFinshed = useMemo(() => {
    const listOptions = productFinishedInfor.map((item) => ({
      label: item.seihnJoho,
      value: item.seihnJoho,
    }));

    return listOptions;
  }, [productFinishedInfor]);

  useSizeInputDisplay({ manufacturingSizes, productFinishedInfor });

  const handleSuccessDomestic = (
    res: AxiosResponse<Response.ManufacturingSize2AndSize3, any>,
    args: Payload.ManufacturingSizeDomestic,
  ) => {
    if (res?.data?.item) {
      //remove zero and duplicate item in array
      const uniqProductWidthData = removeZeroAndDuplicateItem(
        res?.data?.item?.sizes2,
      );
      const uniqProductLengthData = removeZeroAndDuplicateItem(
        res?.data?.item?.sizes3,
      );

      const getSizesWidthData = getSizes(
        uniqProductWidthData,
        false,
        !manufacturingSizes?.size2Label ? ROUND.TO_2_DIGIT : ROUND.TO_4_DIGIT,
      );
      const getSizesLengthData = getSizes(
        uniqProductLengthData,
        false,
        ROUND.TO_2_DIGIT,
      );
      //if product width input is empty
      if (!args?.sizes2) {
        if (machiningMethodOfZ0AndH2ToH5.includes(machiningMethod)) {
          // if isBothTypes === true & productWidthData got from GetManufacturingImportMutation exists
          if (isBothTypes && _.size(productWidthDataRef.current) > 0) {
            const data = getSizesWidthData
              ? removeDuplicateObject([
                  ...productWidthDataRef.current,
                  ...getSizesWidthData,
                ])
              : productWidthDataRef.current;
            if (
              (MACHINING_METHOD.H2TOH5.includes(machiningMethod) &&
                !getSizesWidthData) ||
              _.size(data) > 1
            ) {
              setProductWidthData(data);
              setDisableProductWidthInput(false);
            } else {
              setProductWidthData(undefined);
              setDisableProductWidthInput(true);
              setSizes2(
                productWidthDataRef.current?.[0]?.value || reEstimateWidth,
              );
              setValue(
                'product_width',
                roundSizeForMachiningMethodFromH2ToH5(
                  productWidthDataRef.current?.[0]?.value,
                  machiningMethod,
                ) || reEstimateWidth,
              );
            }
          } else {
            //if uniqProductWidthData's size > 1 => show dropdown list
            if (_.size(uniqProductWidthData) > 1) {
              setProductWidthData(getSizesWidthData);
              setDisableProductWidthInput(false);
            }
            //else => autofill
            else {
              if (_.size(uniqProductWidthData) === 0 && isBothTypes) {
                setProductWidthData(undefined);
                setDisableProductWidthInput(false);
                return;
              }
              setProductWidthData(undefined);
              setDisableProductWidthInput(true);
              setSizes2(uniqProductWidthData?.[0] || reEstimateWidth);
              setValue(
                'product_width',
                roundSizeForMachiningMethodFromH2ToH5(
                  uniqProductWidthData?.[0],
                  machiningMethod,
                ) || reEstimateWidth,
              );
            }
          }
        }
        // if shape === pipe
        else if (shape === SHAPE.PIPE) {
          if (isBothTypes && _.size(productWidthDataRef.current) > 0) {
            setProductWidthData(
              removeDuplicateObject([
                ...productWidthDataRef.current,
                ...getSizesWidthData,
              ]),
            );
          } else {
            setProductWidthData(getSizesWidthData);
          }
          setDisableProductWidthInput(false);
        }
      }
      //if product length label exists
      if (manufacturingSizes?.size2Label && getValues('product_width')) {
        if (machiningMethodOfZ0AndH2ToH5.includes(machiningMethod)) {
          // if isBothTypes === true & productLengthData got from GetManufacturingImportMutation exists
          if (isBothTypes && _.size(productLengthDataRef.current) > 0) {
            const data = getSizesLengthData
              ? removeDuplicateObject([
                  ...productLengthDataRef.current,
                  ...getSizesLengthData,
                ])
              : productLengthDataRef.current;
            if (
              (MACHINING_METHOD.H2TOH5.includes(machiningMethod) &&
                !getSizesLengthData) ||
              _.size(data) > 1
            ) {
              setProductLengthData(data);
              setDisableProductLengthInput(false);
            } else {
              setProductLengthData(undefined);
              setDisableProductLengthInput(true);
              setSizes3(
                productLengthDataRef.current?.[0]?.value || reEstimateLength,
              );
              setValue(
                'product_length',
                roundSizeForMachiningMethodFromH2ToH5(
                  productLengthDataRef.current?.[0]?.value,
                  machiningMethod,
                ) || reEstimateLength,
              );
            }
          } else {
            //if uniqProductLengthData's size > 1 => show dropdown list
            if (_.size(uniqProductLengthData) > 1) {
              setProductLengthData(getSizesLengthData);
              setDisableProductLengthInput(false);
            }
            //else => autofill
            else {
              if (_.size(uniqProductLengthData) === 0 && isBothTypes) {
                setProductLengthData(undefined);
                setDisableProductLengthInput(false);
                return;
              }
              setProductLengthData(undefined);
              setDisableProductLengthInput(true);
              setSizes3(uniqProductLengthData?.[0] || reEstimateLength);
              setValue(
                'product_length',
                roundSizeForMachiningMethodFromH2ToH5(
                  uniqProductLengthData?.[0],
                  machiningMethod,
                ) || reEstimateLength,
              );
            }
          }
        }
        // if shape === pipe & isFlatPipeProduct
        else if (shape === SHAPE.PIPE && isFlatPipeProduct()) {
          // if isBothTypes === true & productLengthData got from GetManufacturingImportMutation exists
          if (isBothTypes && _.size(productLengthDataRef.current) > 0) {
            setProductLengthData(
              removeDuplicateObject([
                ...productLengthDataRef.current,
                ...getSizesLengthData,
              ]),
            );
          } else {
            setProductLengthData(getSizesLengthData);
          }
          setDisableProductLengthInput(false);
        }
      }
    }
  };

  const handleSuccessImport = (
    res: AxiosResponse<Response.ManufacturingSize2AndSize3, any>,
    args: Payload.ManufacturingImport,
  ) => {
    if (res?.data?.item) {
      //remove zero and duplicate item in array
      const uniqProductWidthData = removeZeroAndDuplicateItem(
        res?.data?.item?.sizes2,
      );
      const uniqProductLengthData = removeZeroAndDuplicateItem(
        res?.data?.item?.sizes3,
      );
      //if product width input is empty
      if (!args?.sizes2) {
        if (
          !(
            !machiningMethodOfZ0AndH2ToH5.includes(machiningMethod) &&
            isBothTypes &&
            shape !== SHAPE.PIPE
          )
        ) {
          setProductWidthData(getSizes(uniqProductWidthData, false));
          productWidthDataRef.current = getSizes(uniqProductWidthData, false);
        }
      }
      //if product length label exists
      if (manufacturingSizes?.size2Label && getValues('product_width')) {
        if (
          !(
            !machiningMethodOfZ0AndH2ToH5.includes(machiningMethod) &&
            isBothTypes &&
            shape !== SHAPE.PIPE
          )
        ) {
          setProductLengthData(getSizes(uniqProductLengthData, false));
          productLengthDataRef.current = getSizes(uniqProductLengthData, false);
        }
      }
      //if both types => call api for domestic product
      isBothTypes && handleGetManufacturingSizeDomesticMutation(!!args?.sizes2);
    }
  };

  const handleSuccessTolerance = (
    res: AxiosResponse<Response.Tolerance, any>,
  ) => {
    const data = res?.data?.item;

    const toleranceThicknessOptions = renderToleranceOptions(data?.thickness);
    const toleranceWidthOptions = renderToleranceOptions(data?.width);
    const toleranceLengthOptions = renderToleranceOptions(data?.length);
    const toleranceOutsideDiaOptions = renderToleranceOptions(data?.outsideDia);
    setToleranceOptions({
      thickness: toleranceThicknessOptions,
      width: toleranceWidthOptions,
      length: toleranceLengthOptions,
      outsideDia: toleranceOutsideDiaOptions,
      apiCalled: true,
    });
  };

  const { getManufacturingSizeDomesticMutation } = useManufacturingSizeDomestic(
    {
      handleSuccess: handleSuccessDomestic,
    },
  );
  const { getManufacturingImportMutation } = useManufacturingImport({
    handleSuccess: handleSuccessImport,
  });

  const { getToleranceMutaion } = useTolerance({
    handleSuccess: handleSuccessTolerance,
  });

  useEffect(() => {
    if (product && isDomesticProduct(product) && isImportedProduct(product)) {
      setIsBothTypes(true);
    } else {
      setIsBothTypes(false);
    }
  }, [product]);

  const productSplit = productFormat.splitProductId(product);

  useEffect(() => {
    if (getValues('product_thickness')) {
      getToleranceMutaion.mutate({
        api1ShhnCd: productSplit?.api1ShhnCd || undefined,
        api2ShhnCd: productSplit?.api2ShhnCd || undefined,
        kakoCd: machiningMethod,
        finishingSize:
          getValues('converted_product_thickness') ||
          getValues('product_thickness'),
      });
    }
  }, [getValues('product_thickness')]);

  useEffect(() => {
    setLabelError(manufacturingSizes);
    setValue('sizeItem', manufacturingSizes);
    setManufacturingSizes(manufacturingSizes);
    // auto fill value from estimate data from Order history page or product info from Product Detail Page
    if (dataReEstimate && dataReEstimate?.setdnKakoCd && !isFilled) {
      const inputSunpo = convertDimensionsToArray(dataReEstimate?.inputSunpo);
      const shiagsnpo = convertDimensionsToArray(dataReEstimate?.shiagsnpo);
      const params = getSizeByLabelInput(
        manufacturingSizes,
        dataReEstimate?.inputSunpo ? inputSunpo : shiagsnpo,
      );
      // get params for each dynamic input
      setValue('product_thickness', params.param1);
      handleConvertSize('product_thickness');
      setHasReEstimateThickness(true);
      setReEstimateWidth(params.param2);
      setReEstimateLength(params.param3);
      setValue('number_product', dataReEstimate?.inzu?.toString());
      setValue('machining_thickness', dataReEstimate?.optc001 || '');
      setValue('machining_width', dataReEstimate?.optc002 || '');
      setValue('machining_length', dataReEstimate?.optc003 || '');
      dataReEstimate?.optc004 &&
        setValue('machining_outside_diameter', dataReEstimate?.optc004 || '');
      dataReEstimate?.optc005 &&
        setValue('machining_inside_diameter', dataReEstimate?.optc005 || '');
      dataReEstimate?.optc012 &&
        setValue('options_size_unspecified', dataReEstimate?.optc012 || '');
      dataReEstimate?.optSiteiNaiyo012 &&
        setValue(
          'options_size_c',
          (dataReEstimate?.optSiteiNaiyo012 || '').toString(),
        );
      setValue('corner_option_a', (dataReEstimate?.optc013A || '').toString());
      setValue('corner_option_b', (dataReEstimate?.optc013B || '').toString());
      setValue('corner_option_c', (dataReEstimate?.optc013C || '').toString());
      setValue('corner_option_d', (dataReEstimate?.optc013D || '').toString());
      setValue('roll_eyes', dataReEstimate?.optc025 || '');
      setValue('mill_sheets', dataReEstimate?.optc091 || '');
      dataReEstimate?.kyaksChmnNo &&
        setValue('order_number', dataReEstimate?.kyaksChmnNo);
      dataReEstimate?.biko && setValue('order_notes', dataReEstimate?.biko);
      setIsFilled(true);
      setClearManufacturingSizes(false);
      return;
    }
    if (dataProductDimension?.snpo1 && !isFilled) {
      setValue('product_thickness', dataProductDimension?.snpo1);
      handleConvertSize('product_thickness');
      setHasReEstimateThickness(true);
      setIsFilled(true);
      setClearManufacturingSizes(false);
      return;
    }
    setClearManufacturingSizes(true);
  }, [
    manufacturingSizes?.head,
    manufacturingSizes?.size1Label,
    manufacturingSizes?.size2Label,
  ]);

  useEffect(() => {
    // auto fill value from estimate data from Order history page for productFinished
    if (isProductFinished) {
      if (productFinishedInfor?.length > 0 && dataReEstimate && !isFilled) {
        {
          setValue('number_product', dataReEstimate?.inzu?.toString());
          setValue('seihnJoho', dataReEstimate?.seihnJoho);
          dataReEstimate?.kyaksChmnNo &&
            setValue('order_number', dataReEstimate?.kyaksChmnNo);
          dataReEstimate?.biko && setValue('order_notes', dataReEstimate?.biko);
          setIsFilled(true);
          return;
        }
      }
      setValue('number_product', '');
      setValue('seihnJoho', undefined);
      clearErrors('number_product');
      clearErrors('seihnJoho');
    }
  }, [productFinishedInfor]);

  useEffect(() => {
    //clear data when selecting another machining method
    if (clearManufacturingSizes) {
      setProductWidthData(undefined);
      setProductLengthData(undefined);
      resetInputData();
      setClearManufacturingSizes(false);
    }
  }, [isFetching, clearManufacturingSizes]);

  const handleGetManufacturingSizeDomesticMutation = (hasSize2?: boolean) => {
    getManufacturingSizeDomesticMutation.mutate({
      sizes1: getValues('product_thickness'),
      sizes2:
        hasSize2 && getValues('product_width')
          ? getValues('product_width')
          : undefined,
      sizes3:
        hasSize2 && getValues('product_length')
          ? getValues('product_length')
          : undefined,
      lenghtSize1: manufacturingSizes?.snpoSize || '',
      shhnCd: productSplit?.shhnCd || '',
      kakoCd: machiningMethod,
    });
  };

  const handleGetManufacturingImportMutation = (hasSize2?: boolean) => {
    !hasSize2 && setConversionSize2(undefined);
    setConversionSize3(undefined);
    getManufacturingImportMutation.mutate({
      api1ShhnCd: productSplit?.api1ShhnCd || undefined,
      api2ShhnCd: productSplit?.api2ShhnCd || undefined,
      hakudoShhnCd: productSplit?.hakudoShhnCd || '',
      sizes1:
        getValues('converted_product_thickness') ||
        getValues('product_thickness'),
      sizes2:
        (hasSize2 &&
          (getValues('converted_product_width') ||
            getValues('product_width'))) ||
        undefined,
      lenghtSize1: manufacturingSizes?.snpoSize || '',
    });
  };

  const {
    handleConvertSize,
    conversionSize1,
    conversionSize2,
    conversionSize3,
    setConversionSize2,
    setConversionSize3,
    conversionSize1MultipleRef,
  } = useConvertSize({
    getValues: getValues,
    manufacturingSizes: manufacturingSizes,
    product: product,
    setValue: setValue,
    estimatePriceData: estimatePriceData,
    handleGetManufacturingImportMutation: handleGetManufacturingImportMutation,
    isFetching: isFetching,
    isFilled: isFilled,
  });

  const isFlatPipeProduct = () => {
    return (
      manufacturingSizes?.keijoInfo?.keijoL1Cd ===
        FLAT_PIPE_PRODUCT.KEIJOL1CD &&
      manufacturingSizes?.keijoInfo?.keijoL2Cd ===
        FLAT_PIPE_PRODUCT.KEIJOL2CD &&
      manufacturingSizes?.keijoInfo?.keijoL3Cd === FLAT_PIPE_PRODUCT.KEIJOL3CD
    );
  };

  const handleCallApiSize = (hasSize2?: boolean) => {
    if (isImportedProduct(product)) {
      // if imported product or both types
      handleGetManufacturingImportMutation(hasSize2);
    } else if (isDomesticProduct(product)) {
      // if domestic product
      if (machiningMethodOfZ0AndH2ToH5.includes(machiningMethod)) {
        handleGetManufacturingSizeDomesticMutation(hasSize2);
      } else {
        if (shape === SHAPE.PIPE) {
          handleGetManufacturingSizeDomesticMutation(hasSize2);
        }
      }
    }
  };

  useEffect(() => {
    // clear data when change language
    if (checkChangeLanguage(prevLang, lang)) {
      conversionSize1MultipleRef.current = [];
      setHasReEstimateThickness(false);
      setReEstimateWidth('');
      setReEstimateLength('');
      resetInputData();
    }
  }, [lang]);

  useEffect(() => {
    //if reEstimate => auto handleBlur when getValues('product_thickness') exists
    if (
      getValues('product_thickness') &&
      hasReEstimateThickness &&
      isDomesticProduct(product) &&
      machiningMethodOfZ0AndH2ToH5.includes(machiningMethod)
    ) {
      handleCallApiSize(true);
      setHasReEstimateThickness(false);
    }
  }, [getValues('product_thickness')]);

  useEffect(() => {
    //if reEstimate => set value for product_width and product_length
    if (
      getValues('product_thickness') &&
      !getValues('product_width') &&
      reEstimateWidth
    ) {
      setValue('product_width', reEstimateWidth);
      setReEstimateWidth('');
      handleConvertSize('product_width');
    }
    if (
      getValues('product_width') &&
      !getValues('product_length') &&
      reEstimateLength
    ) {
      setValue('product_length', reEstimateLength);
      setReEstimateLength('');
      handleConvertSize('product_length');
    }
  }, [
    getValues('product_thickness'),
    getValues('product_width'),
    reEstimateWidth,
    reEstimateLength,
  ]);

  //auto call API when sizes2 has only 1 value or have reEstimateWidth
  useEffect(() => {
    if (
      isDomesticProduct(product) &&
      machiningMethodOfZ0AndH2ToH5.includes(machiningMethod)
    ) {
      if (
        (getValues('product_width') && !productWidthData) ||
        reEstimateWidth
      ) {
        handleCallApiSize(true);
      }
    }
  }, [getValues('product_width')]);

  useEffect(() => {
    //disable if product is domestic and machining method is Z0 or (from H2 to H5 and has domestic data)
    if (
      isDomesticProduct(product) &&
      (machiningMethod === MACHINING_METHOD.Z0 ||
        (MACHINING_METHOD.H2TOH5.includes(machiningMethod) &&
          checkHasDomesticData(manufacturingSizes?.sizes))) &&
      !isFetching
    ) {
      setDisableProductWidthInput(true);
      setDisableProductLengthInput(true);
    } else {
      setDisableProductWidthInput(false);
      setDisableProductLengthInput(false);
    }
  }, [product, machiningMethod, isFetching, isToggleOnBlurSize1]);

  useEffect(() => {
    if (
      estimatePriceData?.hasUpdateFinishingSize &&
      manufacturingSizes?.snpoSize
    ) {
      // example: inputSunpo = '0.0394inch x 0.0492fit x 13.12fit' => inputSunpoArray = ['0.0394','0.0492','13.12']
      const inputSunpoArray = convertDimensionsToArray(
        estimatePriceData?.inputSunpo,
      );
      //example: shiagsnpoHyojiMojirets ='1x15x4000(厚さx辺x長さ)' => shiagsnpoHyojiMojiretsArray = ['1','15','4000']
      const shiagsnpoHyojiMojiretsArray = convertShiagsnpoHyojiMojiretsToArray(
        estimatePriceData?.shiagsnpoHyojiMojirets,
      );
      const dimension = estimatePriceData?.inputSunpo
        ? inputSunpoArray
        : shiagsnpoHyojiMojiretsArray;
      const lenghtSize1 = manufacturingSizes?.snpoSize;
      const productThickness = [...dimension].splice(0, lenghtSize1)?.join('x');
      const productWidth = dimension[lenghtSize1];
      const productLength = dimension[lenghtSize1 + 1];
      setValue('product_thickness', productThickness);
      handleConvertSize('product_thickness');
      setValue('product_width', productWidth);
      handleConvertSize('product_width');
      setValue('product_length', productLength);
      handleConvertSize('product_length');
    }
  }, [estimatePriceData]);

  const checkConditionHandleBlurProductThickness = () => {
    // if lenghtSize1 < 6
    if (
      manufacturingSizes?.snpoSize &&
      manufacturingSizes?.snpoSize < 6 &&
      !checkNotGetDataWithImportedProduct()
    ) {
      return true;
    }
    return false;
  };

  const checkConditionHandleBlurProductWidth = () => {
    if (
      // if lenghtSize1 < 5
      manufacturingSizes?.snpoSize &&
      manufacturingSizes?.snpoSize < 5 &&
      !checkNotGetDataWithImportedProduct()
    ) {
      return true;
    }
    return false;
  };

  const checkNotGetDataWithImportedProduct = () => {
    //if isImportedProduct & machining method = KK,KP,FK,FP => not get data from api
    return (
      isImportedProduct(product) &&
      MACHINING_METHOD.EXCLUSION.includes(machiningMethod)
    );
  };

  const renderConversionSize1 = () => {
    if (_.size(conversionSize1MultipleRef.current) > 0) {
      return (
        conversionSize1MultipleRef.current.join('x') +
        manufacturingSizes?.originSize
      );
    } else {
      return renderConversionSizeSingle(conversionSize1);
    }
  };

  const renderConversionSizeSingle = (conversionSize) => {
    return conversionSize && conversionSize + manufacturingSizes?.originSize;
  };

  const handleBlurSizes1 = () => {
    if (checkConditionHandleBlurProductThickness()) {
      setValue('product_width', '');
      setValue('product_length', '');
      handleCallApiSize();
      setIsToggleOnBlurSize1(!isToggleOnBlurSize1);
    }
    handleConvertSize('product_thickness');
    if (isImportedProduct(product)) {
      getToleranceMutaion.mutate({
        api1ShhnCd: productSplit?.api1ShhnCd || undefined,
        api2ShhnCd: productSplit?.api2ShhnCd || undefined,
        kakoCd: machiningMethod,
        finishingSize:
          getValues('converted_product_thickness') ||
          getValues('product_thickness'),
      });
    }
  };

  const handleBlurSizes2 = () => {
    if (checkConditionHandleBlurProductWidth()) {
      setValue('product_length', '');
      handleCallApiSize(true);
    }
    handleConvertSize('product_width');
  };

  return (
    <Row>
      <Col xs={24} sm={6} md={5} lg={4} xl={3} className="form_typo">
        <Typography.Text className="form_label_required">
          {isProductFinished
            ? t('product_page.product_info_2')
            : t('pricing_page.product_size_field.name')}
        </Typography.Text>
      </Col>
      <Col xs={24} sm={18} md={19} lg={20} xl={21}>
        <Row>
          {machinings?.hakudoIllustration && (
            <Col xs={24} sm={24} md={8} lg={8} xl={5}>
              <img src={getImagePreviewPath(machinings?.hakudoIllustration)} />
            </Col>
          )}
          <Col xs={24} sm={24} md={16} lg={16} xl={19}>
            <Row className="input_properties">
              {(manufacturingSizes.head || isProductFinished) && (
                <Col xs={24} md={12} xl={5}>
                  <Row justify={'end'}>
                    <Col xs={21} md={24} xl={24}>
                      {!isProductFinished ? (
                        <AutoSuggestField<FormValue>
                          onBlur={handleBlurSizes1}
                          controller={{ control, name: 'product_thickness' }}
                          autoCompleteProps={{
                            allowClear: true,
                            options: checkNotGetDataWithImportedProduct()
                              ? undefined
                              : manufacturingSizes.sizes,
                            maxTagTextLength: 20,
                            filterOption: (inputValue, option) =>
                              ((option && option.label) || '')
                                .toString()
                                .toUpperCase()
                                .indexOf(inputValue.toUpperCase()) !== -1,
                          }}
                          label={
                            formatManufacturingSizes(manufacturingSizes.head) ||
                            t('pricing_page.product_size_field.thickness_field')
                          }
                          newRegex={REGEX.REGEX_DIMENSIONS_SIZES1}
                          className="size_input"
                        />
                      ) : (
                        <SelectField<FormValue>
                          controller={{ control, name: 'seihnJoho' }}
                          selectProps={{
                            placeholder: t('pricing_page.type_selection'),
                            allowClear: true,
                            options: listOptionProductFinshed,
                          }}
                          label={t('pricing_page.type_selection')}
                          className="size_input"
                        />
                      )}
                    </Col>
                  </Row>
                  <Typography.Text>{renderConversionSize1()}</Typography.Text>
                </Col>
              )}
              {manufacturingSizes.size1Label && !isProductFinished && (
                <>
                  <Col
                    xs={3}
                    sm={3}
                    md={1}
                    lg={1}
                    xl={1}
                    className="after_label"
                  >
                    <Typography.Text className="after_label_text">
                      {t('pricing_page.product_size_field.thickness_after')}
                    </Typography.Text>
                  </Col>
                  <Col xs={21} sm={21} md={12} lg={12} xl={5}>
                    <AutoSuggestField<FormValue>
                      onBlur={handleBlurSizes2}
                      controller={{ control, name: 'product_width' }}
                      autoCompleteProps={{
                        allowClear: true,
                        options: productWidthData,
                        maxTagTextLength: 10,
                        showSearch: true,
                        filterOption: false,
                        showArrow: false,
                        disabled: disableProductWidthInput,
                      }}
                      label={
                        formatManufacturingSizes(
                          manufacturingSizes.size1Label,
                        ) || t('pricing_page.product_size_field.width_field')
                      }
                      newRegex={REGEX.REGEX_DIMENSIONS_SIZES2_SIZES3}
                      className="size_input"
                    />
                    <Typography.Text>
                      {renderConversionSizeSingle(conversionSize2)}
                    </Typography.Text>
                  </Col>
                </>
              )}
              {manufacturingSizes.size2Label && !isProductFinished && (
                <>
                  <Col
                    xs={3}
                    sm={3}
                    md={1}
                    lg={1}
                    xl={1}
                    className="after_label"
                  >
                    <Typography.Text className="after_label_text">
                      {t('pricing_page.product_size_field.width_after')}
                    </Typography.Text>
                  </Col>
                  <Col xs={21} sm={21} md={12} lg={5} xl={5}>
                    <AutoSuggestField<FormValue>
                      onBlur={() => {
                        handleConvertSize('product_length');
                      }}
                      controller={{ control, name: 'product_length' }}
                      autoCompleteProps={{
                        allowClear: true,
                        options: productLengthData,
                        maxTagTextLength: 10,
                        showSearch: true,
                        filterOption: false,
                        disabled: disableProductLengthInput,
                      }}
                      label={
                        formatManufacturingSizes(
                          manufacturingSizes.size2Label,
                        ) || t('pricing_page.product_size_field.length_field')
                      }
                      newRegex={REGEX.REGEX_DIMENSIONS_SIZES2_SIZES3}
                      className="size_input"
                    />
                    <Typography.Text>
                      {renderConversionSizeSingle(conversionSize3)}
                    </Typography.Text>
                  </Col>
                </>
              )}
              {(manufacturingSizes.head || isProductFinished) && (
                <>
                  <Col
                    xs={3}
                    sm={3}
                    md={1}
                    lg={1}
                    xl={1}
                    className="after_label"
                  >
                    <Typography.Text className="after_label_text">
                      {t('pricing_page.product_size_field.length_after')}
                    </Typography.Text>
                  </Col>
                  <Col xs={18} sm={18} md={18} lg={9} xl={4}>
                    <NumberOnlyField<FormValue>
                      controller={{ control, name: 'number_product' }}
                      inputProps={{
                        placeholder: t(
                          'pricing_page.product_size_field.number_field',
                        ),
                        maxLength: 9,
                      }}
                      label={t('pricing_page.product_size_field.number_field')}
                      isOnlyInteger={true}
                      className="size_input"
                    />
                  </Col>
                  <Col
                    xs={3}
                    sm={3}
                    md={3}
                    lg={2}
                    xl={2}
                    className="after_label"
                  >
                    <Typography.Text className="after_label_text">
                      {isProductFinished
                        ? t('pricing_page.sheet')
                        : getProductUnit(getValues('shape'))}
                    </Typography.Text>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ManuFacturingSize;
