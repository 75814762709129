import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import useForm, { FormValue } from './useForm';
import useForgotPass from 'src/queries/useForgotPass';
import { InputField } from 'src/components/form';
import useInitLogin from 'src/queries/useInitLogin';
import { PATH } from 'src/pages/auth';
import LocalStorage from 'src/utils/LocalStorage';

const ForgotPassword: React.FC = () => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [cowndown, setCowndown] = useState(0);
  const { control, handleSubmit, setError } = useForm();
  const { t } = useTranslation();
  const { data } = useInitLogin();

  useEffect(() => {
    timerResend();
  }, [cowndown]);

  const timerResend = () => {
    let timer = setTimeout(() => {
      if (cowndown <= 0) {
        clearInterval(timer);
        setIsSuccess(false);
      } else {
        setCowndown(cowndown - 1);
      }
    }, 1000);
  };

  const onSendMail = (val) => {
    setIsSuccess(false);
    let payload = {
      email: val.email,
      gengoCd: LocalStorage.lang || 'en',
    };
    mutation.mutate(payload);
  };

  const onSuccess = (data) => {
    setIsSuccess(true);
    setCowndown(60);
  };

  const mutation = useForgotPass({ setError, onSuccess });

  return (
    <React.Fragment>
      <Row justify="center" align="middle">
        <div className="forgot_pass">
          <Row justify="center">
            <Col span={18} className="text-center">
              {data?.rmSiteKihon?.ecsiteLogoDir && (
                <Link to={PATH.SIGN_IN}>
                  <img
                    src={data?.rmSiteKihon?.ecsiteLogoDir}
                    alt="logo"
                    className="forgot_pass_logo"
                  />
                </Link>
              )}
            </Col>
          </Row>
          <Form
            layout="vertical"
            className="forgot_pass_form"
            onFinish={handleSubmit((val) => onSendMail(val))}
          >
            <InputField<FormValue>
              controller={{ control, name: 'email' }}
              inputProps={{
                placeholder: t('auth_form.email_placeholder'),
                maxLength: 80,
              }}
              label={t('auth_form.email_label')}
              required
            />
            {isSuccess && <p className="text-center">{t('message.C000004')}</p>}
            <Row justify="center" className="forgot_pass_buttons">
              <Col span={12} className="forgot_pass_button_forgot">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="w-full"
                  disabled={cowndown > 0}
                  loading={mutation.isLoading}
                >
                  {cowndown > 0
                    ? cowndown
                    : t('forgot_password.button_confirm')}
                </Button>
              </Col>
              <Col span={12} className="forgot_pass_button_cancel">
                <Button
                  type="primary"
                  onClick={() => history.back()}
                  className="w-full"
                >
                  {t('forgot_password.button_cancel')}
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default ForgotPassword;
