import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getAccountSetting = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const updateAccountSetting = (
  url: string,
  data: Payload.UpdateAccountSetting,
) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

export default { getAccountSetting, updateAccountSetting };
