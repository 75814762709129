import { Card, Divider, Typography } from 'antd';
import React from 'react';

type Props = {
  title?: string;
  className?: string;
};

const CardCustom: React.FC<Props> = ({ children, title, className }) => {
  return (
    <Card className={className}>
      <Typography.Title level={1} className="mt-20 card_title">
        {title}
      </Typography.Title>
      {title && <Divider className="mt-20 mb-8 card_divider" />}
      {children}
    </Card>
  );
};

export default CardCustom;
