import React from 'react';
import color from 'src/styles/themes/color';

type Props = {
  w?: number;
  h?: number;
  fill?: string;
  className?: string;
  onClick?: () => void;
};

const IconHomeTop: React.FC<Props> = ({
  w = 18,
  h = 18,
  fill = color.grey_3,
  className,
  onClick,
}) => {
  return (
    <svg
      width={w}
      height={h}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      onClick={onClick}
    >
      <path
        d="M7.5 15V10.5H10.5V15H14.25V9H16.5L9 2.25L1.5 9H3.75V15H7.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default IconHomeTop;
