import { useEffect, useState } from 'react';

type windowSizeType = {
  width?: number;
  height?: number;
};

const useSizeInputDisplay = ({ manufacturingSizes, productFinishedInfor }) => {
  const [windowSize, setWindowSize] = useState<windowSizeType>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  useEffect(() => {
    //Apply to width greater than 768px
    if (windowSize?.width && windowSize?.width >= 768) {
      const sizeInputDivs = document.querySelectorAll('.size_input');
      const afterLabelDivs = document.querySelectorAll('.after_label');
      const sizeInputArray = Array.from(sizeInputDivs) as HTMLDivElement[];
      let afterLabelArray = Array.from(afterLabelDivs) as HTMLDivElement[];
      let sizeLabelArray: HTMLDivElement[] = [];
      let maxHeight = 0; // Maximum height of size labels

      const handleUnsetHeight = (sizeLabelArray, afterLabelArray) => {
        sizeLabelArray?.forEach((item) => {
          item.style.height = 'unset';
        });
        afterLabelArray?.forEach((item, index) => {
          item.style.marginTop = sizeLabelArray[index].offsetHeight + 11 + 'px';
        });
      };

      // Get all sizeLabelDiv and store them in the sizeLabelArray
      sizeInputArray.forEach((sizeInput) => {
        const sizeLabelDiv = sizeInput.querySelector<HTMLDivElement>('div');
        if (sizeLabelDiv) {
          sizeLabelArray.push(sizeLabelDiv);
        }
      });

      if (
        sizeLabelArray?.length > 0 &&
        afterLabelArray?.length > 0 &&
        windowSize?.width < 1200
      ) {
        // If sizeLabelArray?.length === 4, remove the first and last labels => handleUnsetHeight
        if (sizeLabelArray?.length === 4) {
          const afterLabelFirstAndLast = [
            afterLabelArray[0],
            afterLabelArray[3],
          ];
          const sizeLabelFirstAndLast = [sizeLabelArray[0], sizeLabelArray[3]];

          handleUnsetHeight(sizeLabelFirstAndLast, afterLabelFirstAndLast);

          sizeLabelArray = sizeLabelArray.slice(1, -1);
          afterLabelArray = afterLabelArray.slice(1, -1);
        }

        // If sizeLabelArray?.length === 3, remove the first labels => handleUnsetHeight
        if (sizeLabelArray?.length === 3) {
          const afterLabelFirst = afterLabelArray[0];
          const sizeLabelFirst = sizeLabelArray[0];

          sizeLabelFirst.style.height = 'unset';
          afterLabelFirst.style.marginTop =
            sizeLabelFirst.offsetHeight + 11 + 'px';

          sizeLabelArray = sizeLabelArray.slice(1, 4);
          afterLabelArray = afterLabelArray.slice(1, 4);
        }
      }

      if (windowSize?.width >= 992) {
        // Calculate the maximum height of the size labels
        sizeLabelArray.forEach((item) => {
          const div = item as HTMLDivElement;
          div.style.height = 'unset';
          const height = div.offsetHeight;
          if (height > maxHeight) {
            maxHeight = height;
          }
        });

        //Set height for all size labels
        sizeLabelArray.forEach((item) => {
          const div = item as HTMLDivElement;
          div.style.height = maxHeight + 'px';
        });

        // Set margin-top for all elements afterLabel
        afterLabelArray.forEach((item) => {
          const div = item as HTMLDivElement;
          div.style.marginTop = maxHeight + 11 + 'px';
        });
      } else {
        handleUnsetHeight(sizeLabelArray, afterLabelArray);
      }
    }
  }, [manufacturingSizes?.head, windowSize?.width, productFinishedInfor]);
};

export default useSizeInputDisplay;
