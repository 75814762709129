import { AxiosError, AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { API_KEY } from 'src/constants/apiKey';

import useQueryUrl from 'src/hooks/useQueryUrl';
import category from 'src/repositories/category';
import { productFormat } from 'src/utils/product';

const useProducts = () => {
  const params = useQueryUrl<SearchParams.CategorySearch>();

  const { data, ...rest } = useQuery<
    AxiosResponse<Response.ProductList>,
    AxiosError<any>
  >([API_KEY.PRODUCTS, params], category.getProductList, {
    retry: 0,
    enabled: !!params.hinshL3Cd && !!params.keijoL3Cd,
    onError(err) {
      console.log(err.response);
    },
  });

  const productMergeId = data?.data?.items.map((item) => {
    return {
      ...item,
      productId: productFormat.mergeProductId(
        item.hakudoShhnCd,
        item.shhnCd,
        item.api1ShhnCd,
        item.api2ShhnCd,
      ),
    };
  });

  return {
    ...rest,
    products: productMergeId,
  };
};

export default useProducts;
