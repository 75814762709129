import { Col, Divider, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type TaxDetailProps = {
  item: Response.QuotationEntity;
};

const TaxDetail: React.FC<TaxDetailProps> = (props: TaxDetailProps) => {
  const { item } = props;
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Row className="item_data_order_tax">
        <Col xs={8} md={24}>
          <Typography.Title level={5}>
            {t('quotation_page.tax_breakdown') + ':'}
          </Typography.Title>
        </Col>
        <Col xs={16} md={24}>
          <Typography.Text>
            {item?.mitsumoriTaxResList?.map(
              (tax) =>
                `${tax?.taxName}: ${tax?.zeikin} (${tax?.sogakuTani})` + '　',
            )}
          </Typography.Text>
        </Col>
      </Row>
      <Divider type="horizontal" className="item_data_divider-bottom" />
    </React.Fragment>
  );
};

export default TaxDetail;
