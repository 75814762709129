import { Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useSiteInfo from 'src/queries/useSiteInfo';

const TrialNote: React.FC = () => {
  const { t } = useTranslation();
  const { queryPublicCompanyInfo } = useSiteInfo(false);

  return (
    <div className="content_pricing_note">
      <Row>
        <Typography.Text className="content_pricing_note_text">
          {t('pricing_page.notes.text1')}
        </Typography.Text>
      </Row>
      <Row>
        <Typography.Text className="content_pricing_note_text">
          {t('pricing_page.notes.text2', {
            0: queryPublicCompanyInfo?.data?.data?.item?.telno,
          })}
        </Typography.Text>
      </Row>
      <Row>
        <Typography.Text className="content_pricing_note_text">
          {t('pricing_page.notes.text3', {
            0: queryPublicCompanyInfo?.data?.data?.item?.email,
          })}
        </Typography.Text>
      </Row>
    </div>
  );
};

export default TrialNote;
