import snpo_img_maru_bou from 'src/assets/images/quotation/snpo_img_maru_bou.png';
import snpo_img_maru_kan from 'src/assets/images/quotation/snpo_img_maru_kan.png';
import snpo_img_angle from 'src/assets/images/quotation/snpo_img_angle.png';
import snpo_img_channel from 'src/assets/images/quotation/snpo_img_channel.png';
import snpo_img_hirakaku_kan from 'src/assets/images/quotation/snpo_img_hirakaku_kan.png';
import snpo_img_hirakaku_bou from 'src/assets/images/quotation/snpo_img_hirakaku_bou.png';
import snpo_img_rokkaku_bou from 'src/assets/images/quotation/snpo_img_rokkaku_bou.png';
import snpo_img_scln_angle from 'src/assets/images/quotation/snpo_img_scln_angle.png';
import snpo_img_shikaku_bou from 'src/assets/images/quotation/snpo_img_shikaku_bou.png';
import snpo_img_shikaku_kan from 'src/assets/images/quotation/snpo_img_shikaku_kan.png';

export const IMAGE_PREVIEW = [
  { name: 'アングル', image: snpo_img_angle },
  { name: 'チャンネル', image: snpo_img_channel },
  { name: '平角棒', image: snpo_img_hirakaku_bou },
  { name: '平角管', image: snpo_img_hirakaku_kan },
  { name: '丸棒', image: snpo_img_maru_bou },
  { name: '丸管', image: snpo_img_maru_kan },
  { name: '六角棒', image: snpo_img_rokkaku_bou },
  { name: '不等辺アングル', image: snpo_img_scln_angle },
  { name: '四角棒', image: snpo_img_shikaku_bou },
  { name: '四角管', image: snpo_img_shikaku_kan },
];
