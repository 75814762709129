import React, { useEffect } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { Layout } from 'antd';
import { I18n } from 'src/libs/i18n';

import LocalStorage from 'src/utils/LocalStorage';
import LangSelect from 'src/components/layout/TopNav/LangSelect';
import AppFooter from 'src/components/layout/Footer';
import useInitLogin from 'src/queries/useInitLogin';
import usePolicy from 'src/queries/usePolicy';
import history from 'src/libs/history';
import { PATH as POLICY_PATH } from 'src/pages/policy';
import { IS_CHECK_POLICY } from 'src/constants/app';
import LoadingSuspense from 'src/components/applications/LoadingSuspense';

type Props = {
  requireNotAuth?: boolean;
  title?: string;
};

const RestrictLayout: React.FC<Props> = ({
  requireNotAuth = true,
  title = '',
}) => {
  const { data } = useInitLogin();

  const { getCustomerInfo } = usePolicy();
  const customerInfo = getCustomerInfo?.data?.data?.item;
  const headerColor = process.env.REACT_APP_HEADER_COLOR;

  useEffect(() => {
    LocalStorage.setInitSiteInfo(data);
  }, [data]);

  useEffect(() => {
    if (
      LocalStorage.accessToken &&
      customerInfo?.policyCheck === IS_CHECK_POLICY.FALSE
    ) {
      history.push(POLICY_PATH);
    }
  }, [customerInfo]);

  if (getCustomerInfo.isLoading) {
    return <LoadingSuspense />;
  }

  return LocalStorage.accessToken && requireNotAuth ? (
    <Navigate to={'/'} replace />
  ) : (
    <Layout className="min-h-100vh" style={{ backgroundColor: headerColor }}>
      <div className="header_restrict">
        <Layout.Header
          className="container-lg header_restrict_container"
          style={{ backgroundColor: headerColor }}
        >
          <span className="header_restrict_site_name">
            {data?.rmSiteKihon?.netSbsName}
          </span>
          <div className="header_restrict_title">
            <span className="login_title">{I18n.t(title)}</span>
          </div>
          <div className="header_restrict_language">
            <LangSelect />
          </div>
        </Layout.Header>
      </div>
      <Outlet />
      <AppFooter hasMenu={false} hasContact={false} />
    </Layout>
  );
};

export default RestrictLayout;
