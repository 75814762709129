import { QueryFunctionContext } from '@tanstack/react-query';
import axios from 'src/libs/axios';

const getSlides = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;

  return axios({
    method: 'get',
    url,
  });
};

export default {
  getSlides,
};
