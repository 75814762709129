import {
  CheckCircleOutlined,
  DeleteOutlined,
  HeartFilled,
  HeartOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Button, Divider, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProductType } from 'src/enums/quotation';
import ItemDetail from '../ItemDetail';

type Props = {
  type: 'unexpired' | 'expired' | 'ordered';
  item: Response.QuotationEntity;
  handleReaction: () => void;
  handleDelete: () => void;
  handleRequote: () => void;
  handleOrder: () => void;
};

const ExpandedRowRender: React.FC<Props> = ({
  type,
  item,
  handleReaction,
  handleDelete,
  handleRequote,
  handleOrder,
}) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div className="row_info">
        <Typography.Text className="row_name">
          {item.hakudoShhnm}
        </Typography.Text>
        {type !== 'expired' && (
          <div>
            <Typography.Text className="row_code">
              {t('quotation_page.product_code', {
                code: item.hakudoShhnCd,
              })}
            </Typography.Text>
            <Typography.Text className="row_sub">
              {item.zaitokKbn === ProductType.BACK_ORDER_PRODUCT &&
                t('quotation_page.in_stock_now')}
            </Typography.Text>
          </div>
        )}
        <Divider className="row_divider" />
        <div className="row_button">
          <Button
            type="primary"
            icon={<CheckCircleOutlined />}
            className="item_button"
            disabled={type !== 'unexpired'}
            onClick={handleOrder}
          />
          <Button
            type="primary"
            icon={<SyncOutlined />}
            className="item_button"
            onClick={handleRequote}
          />
          {type !== 'ordered' && (
            <Button
              type="primary"
              icon={
                item.okinihairyFlg === '1' ? <HeartFilled /> : <HeartOutlined />
              }
              className="item_button"
              onClick={handleReaction}
            />
          )}
          <Button
            type="primary"
            icon={<DeleteOutlined />}
            className="item_button"
            onClick={handleDelete}
          />
        </div>
      </div>
      <ItemDetail type={type} item={item} />
    </React.Fragment>
  );
};

export default ExpandedRowRender;
