import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';

import ConfirmOTP from './ComfirmOTP';
import ResetPass from './ResetPass';
import useInitLogin from 'src/queries/useInitLogin';
import { PATH } from 'src/pages/auth';

const ResetPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [resetToken, setResetToken] = useState(false);
  const { data } = useInitLogin();

  const onConfirmOTP = (data) => {
    setIsVerified(true);
    setEmail(data.email);
    setResetToken(data.resetToken);
  };

  return (
    <React.Fragment>
      <Row justify="center" align="middle">
        <div className="reset_pass">
          <Row justify="center">
            <Col span={18} className="text-center">
              {data?.rmSiteKihon?.ecsiteLogoDir && (
                <Link to={PATH.SIGN_IN}>
                  <img
                    src={data?.rmSiteKihon?.ecsiteLogoDir}
                    alt="logo"
                    className="reset_pass_logo"
                  />
                </Link>
              )}
            </Col>
          </Row>
          {!isVerified ? (
            <ConfirmOTP onConfirmOTP={onConfirmOTP} />
          ) : (
            <ResetPass resetToken={resetToken} email={email} />
          )}
        </div>
      </Row>
    </React.Fragment>
  );
};

export default ResetPassword;
