import React from 'react';

import { PATH } from 'src/pages/pricing';
import Layout from 'src/components/layout';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';
import { SCREENS } from 'src/constants/screens';

const PricingRoute: IRouter[] = [
  {
    element: <Layout />,
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/pricing')),
        title: 'pricing_page.page_title',
        screenId: SCREENS.R1004,
      },
    ],
  },
];

export default PricingRoute;
