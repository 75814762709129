import React from 'react';
import { Menu, Layout, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import logout from 'src/utils/logout';
import { PATH as PATH_ACCOUNT } from 'src/pages/account';
import useMenu from 'src/hooks/useMenu';

const AppMenu: React.FC = () => {
  const { t } = useTranslation();
  const { menuItems, getSelectedKey } = useMenu('main');

  return (
    <Layout className={'main-menu'}>
      <div className={'container-lg menu-container'}>
        <div className={'header-menu'}>
          <Menu
            mode="horizontal"
            items={menuItems}
            defaultSelectedKeys={getSelectedKey()}
            subMenuCloseDelay={0.2} // fix submenu do not close when hover out
            overflowedIndicator={false}
          />
        </div>
        <div className={'account-menu'}>
          <div className={'account-menu-container'}>
            <Row align="middle" justify="center">
              <Col span={12}>
                <Link to={PATH_ACCOUNT}>
                  <div className="account-menu-item">
                    <UserOutlined />
                    <p className="account-menu-item-name">
                      {t('menu_home.account')}
                    </p>
                  </div>
                </Link>
              </Col>
              <Col span={12}>
                <div className="account-menu-item" onClick={() => logout()}>
                  <LogoutOutlined />
                  <p className="account-menu-item-name">
                    {t('menu_home.logout')}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AppMenu;
