import { MachiningCondition } from 'src/interfaces/condition';

export const INPUT_INDEX = [
  { index: 0, value: '板厚' },
  { index: 1, value: '巾' },
  { index: 2, value: '長さ' },
  { index: 3, value: '外径' },
  { index: 4, value: '内径' },
];

// condition [show, active] machining select box
export const MACHINING_SELECT_CONDITION: MachiningCondition = [
  {
    shapeType: '01',
    condition: [
      {
        matchiningType: 'Z0',
        condition: [
          { show: true, active: false },
          { show: true, active: false },
          { show: true, active: false },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'DW',
        condition: [
          { show: true, active: false },
          { show: true, active: true },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'KK',
        condition: [
          { show: true, active: false },
          { show: true, active: true },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'KP',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'GK',
        condition: [
          { show: true, active: false },
          { show: true, active: true },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'GP',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'KU',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'KV',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'GU',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'G6',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'MK',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
          { show: false, active: false },
          { show: true, active: true },
          { show: true, active: false },
        ],
      },
      {
        matchiningType: 'MW',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
          { show: false, active: false },
          { show: true, active: true },
          { show: true, active: false },
        ],
      },
      {
        matchiningType: 'DO',
        condition: [
          { show: true, active: false },
          { show: true, active: true },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'MA',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
          { show: false, active: false },
          { show: true, active: true },
          { show: true, active: false },
        ],
      },
      {
        matchiningType: 'YA',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
          { show: false, active: false },
          { show: true, active: true },
          { show: true, active: true },
        ],
      },
      {
        matchiningType: 'YK',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
          { show: false, active: false },
          { show: true, active: true },
          { show: true, active: true },
        ],
      },
      {
        matchiningType: 'MX',
        condition: [
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
          { show: true, active: true },
          { show: true, active: false },
        ],
      },
      {
        matchiningType: 'YX',
        condition: [
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
          { show: true, active: true },
          { show: true, active: true },
        ],
      },
      {
        matchiningType: 'Others',
        condition: [
          { show: true, active: false },
          { show: true, active: false },
          { show: true, active: false },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
    ],
  },
  {
    shapeType: '02',
    condition: [
      {
        matchiningType: 'Z0',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
          { show: true, active: false },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'Others',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
    ],
  },
  {
    shapeType: '03',
    condition: [
      {
        matchiningType: 'Z0',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
          { show: true, active: false },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'Others',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
          { show: true, active: true },
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
    ],
  },
];
