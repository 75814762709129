import React from 'react';
import { Row, Col, Collapse } from 'antd';
import { useTranslation } from 'react-i18next';
import { NotificationOutlined, CaretRightOutlined } from '@ant-design/icons';

import SectionTitle from 'src/components/layout/SectionTitle';
import useNotification from 'src/queries/useNotification';

const { Panel } = Collapse;

const NotificationList: React.FC = () => {
  const { t } = useTranslation();
  const { notifications } = useNotification();

  const downloadFile = (notification) => {
    window.URL = window.URL || window.webkitURL;
    var xhr = new XMLHttpRequest(),
      a = document.createElement('a'),
      file;
    xhr.open('GET', notification?.rmOsiraseContent?.fileName, true);
    xhr.responseType = 'blob';
    xhr.onload = function () {
      file = new Blob([xhr.response], { type: 'application/octet-stream' });
      a.href = window.URL.createObjectURL(file);
      a.download = notification?.rmOsiraseContent?.dispFileName;
      a.click();
    };
    xhr.send();
  };

  return (
    <React.Fragment>
      <SectionTitle
        title={t('top_page.notification.title')}
        icon={<NotificationOutlined />}
      />
      <Row className="notification_section">
        <Col span={24}>
          <Collapse
            bordered={false}
            accordion={true}
            defaultActiveKey={1}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 180} />
            )}
            expandIconPosition="right"
            className="notification_section_collapse"
          >
            {notifications?.map((notification) => (
              <Panel
                header={`${notification.postedFrom} ${notification?.rmOsiraseContent?.title}`}
                key={notification?.seqno}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: notification?.rmOsiraseContent?.osirase,
                  }}
                ></div>
                <a onClick={() => downloadFile(notification)}>
                  {notification?.rmOsiraseContent?.dispFileName}
                </a>
              </Panel>
            ))}
          </Collapse>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default NotificationList;
