import { Col, Row, Table, Tabs, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { Breadcrumb } from 'src/components/custom';
import useProductDetails from 'src/queries/useProductDetails';
import useTable from './useTable';
import { getProductName } from 'src/utils/helper';
import LoadingPage from 'src/components/applications/LoadingPage';
import classNames from 'classnames';

type Props = {
  isTrialMode?: boolean;
};

const ProductDetails: React.FC<Props> = ({ isTrialMode }) => {
  const { t } = useTranslation();
  const { getBasicInfo } = useProductDetails();
  const { tableProps, detailInfoData } = useTable({ isTrialMode });

  const { TabPane } = Tabs;

  const basicInfoData = getBasicInfo?.data?.data?.item;

  const imageProduct =
    basicInfoData?.shhnImageDir || basicInfoData?.hakudoShhnImageDir;

  const handlePdfUrl = (url) => {
    if (url) {
      return `${url}#toolbar=0`;
    }
  };

  return (
    <React.Fragment>
      {getBasicInfo.isLoading ? (
        <LoadingPage />
      ) : (
        <div
          className={classNames('mb-30 mt-30 product_page', {
            product_page_trial: isTrialMode,
          })}
        >
          {!isTrialMode && <Breadcrumb />}
          <Row className="product_card">
            <Col className="product_card_col">
              <Row className="card_info">
                <Row className="card_info_item">
                  <Col>
                    <Typography.Text className="card_info_title">
                      {`${t('product_page.product_name')}:`}
                    </Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text className="card_info_content">
                      {getProductName(
                        basicInfoData,
                        false,
                        basicInfoData?.shhnnm,
                      ) ||
                        getProductName(
                          basicInfoData,
                          true,
                          basicInfoData?.hakudoShhnnm,
                        )}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row className="card_info_item">
                  <Col>
                    <Typography.Text className="card_info_title">
                      {`${t('product_page.product_code')}:`}
                    </Typography.Text>
                  </Col>
                  <Col>
                    <Typography.Text className="card_info_content">
                      {basicInfoData?.shhnCd || basicInfoData?.hakudoShhnCd}
                    </Typography.Text>
                  </Col>
                </Row>
              </Row>
            </Col>
            {imageProduct && (
              <Col className="product_image">
                <img src={imageProduct} />
              </Col>
            )}
          </Row>
          <Tabs type="card" className="product_tabs">
            <TabPane tab={t('product_page.product_info_1')} key={'1'}>
              {!_.isEmpty(detailInfoData) ? (
                <Table {...tableProps} className="product_table custom_table" />
              ) : (
                <Typography.Title level={5} className="product_table_text">
                  {t('product_page.empty_data_message')}
                </Typography.Title>
              )}
            </TabPane>
            <TabPane
              tab={t('product_page.detail')}
              key={'2'}
              disabled={
                !basicInfoData?.shhnPdfDir && !basicInfoData?.hakudoShhnPdfDir
              }
            >
              <iframe
                src={
                  handlePdfUrl(basicInfoData?.shhnPdfDir) ||
                  handlePdfUrl(basicInfoData?.hakudoShhnPdfDir)
                }
                className="product_pdf"
              />
            </TabPane>
          </Tabs>
        </div>
      )}
    </React.Fragment>
  );
};

export default ProductDetails;
