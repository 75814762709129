import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import common from 'src/repositories/common';

const apiKey = '/common/company-info';
const apiKeyPublic = '/common/guest/company-info';

const useSiteInfo = (enabled) => {
  const queryCompanyInfo = useQuery<
    AxiosResponse<Response.CompanyInfo>,
    AxiosError<any>
  >([apiKey], common.getCompanyInfo, {
    retry: 0,
    enabled: enabled,
    onError(err) {
      console.log(err.response);
    },
  });

  const queryPublicCompanyInfo = useQuery<
    AxiosResponse<Response.PublicCompanyInfo>,
    AxiosError<any>
  >([apiKeyPublic], common.getPublicCompanyInfo, {
    retry: 0,
    onError(err) {
      console.log(err.response);
    },
  });

  return {
    queryCompanyInfo,
    queryPublicCompanyInfo,
  };
};

export default useSiteInfo;
