import { Button, Divider, Popover, Radio, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Card } from 'src/components/custom';
import useCategorySearch from 'src/queries/useProductCategorySearch';
import LoadingPage from 'src/components/applications/LoadingPage';
import { PATH as PATH_PRICING } from 'src/pages/pricing';
import history from 'src/libs/history';
import { productFormat } from 'src/utils/product';
import useQueryUrl from 'src/hooks/useQueryUrl';
import ProductTooltip from 'src/components/common/ProductTooltip';
import { getProductName } from 'src/utils/helper';

const ProductList = (props) => {
  const { t } = useTranslation();
  const params = useQueryUrl<SearchParams.CategorySearch>();

  const { queryProductList } = useCategorySearch();
  const listProduct = queryProductList.data?.data.items;

  const [product, setProduct] = useState('');

  useEffect(() => {
    const productDefault =
      listProduct?.length &&
      productFormat.mergeProductId(
        listProduct[0].hakudoShhnCd,
        listProduct[0].shhnCd,
        listProduct[0].api1ShhnCd,
        listProduct[0].api2ShhnCd,
      );
    setProduct(productDefault || '');
  }, [listProduct?.length]);

  const _onChangeProduct = (e) => {
    setProduct(e.target.value);
  };

  const _onClickEST = () => {
    history.push(PATH_PRICING, {
      state: {
        product: {
          hinshL3Cd: params.hinshL3Cd,
          keijoL3Cd: params.keijoL3Cd,
          productId: product,
        },
      },
    });
  };

  const _renderProductName = () => {
    if (queryProductList.isLoading) {
      return <LoadingPage />;
    }
    if (listProduct?.length) {
      return (
        <>
          <Radio.Group
            className="w-full"
            onChange={_onChangeProduct}
            value={product}
          >
            {listProduct?.map((item, index) => {
              return (
                <Popover
                  placement="bottomLeft"
                  key={index}
                  content={
                    <ProductTooltip
                      product={item}
                      isShowDetailProductLink={true}
                    />
                  }
                  trigger="hover"
                >
                  <Radio
                    className="mr-0 mb-24 category_product_item"
                    value={productFormat.mergeProductId(
                      item.hakudoShhnCd,
                      item.shhnCd,
                      item.api1ShhnCd,
                      item.api2ShhnCd,
                    )}
                  >
                    {getProductName(item, true)}
                  </Radio>
                </Popover>
              );
            })}
          </Radio.Group>
          <Divider className="mg-vertical-20" />
          <Row className="mb-10" align="middle" justify="center">
            <Button className="px-60" type="primary" onClick={_onClickEST}>
              {t('category.proceed_est')}
            </Button>
          </Row>
        </>
      );
    } else {
      return (
        <div className="d-flex justify-center py-30">
          <Typography.Text>{t('message.E000024')}</Typography.Text>
        </div>
      );
    }
  };

  return (
    <Card className="mt-20">
      <Typography.Text className="category_small_title">
        {t('category.product_name')}
      </Typography.Text>
      <Divider className="mg-vertical-16" />
      {_renderProductName()}
    </Card>
  );
};

export default ProductList;
