import React from 'react';
import { Row, Col } from 'antd';

type Props = {
  icon?: any;
  title?: string;
  elmentRight?: any;
};

const SectionTitle: React.FC<Props> = ({ icon, title = '', elmentRight }) => {
  return (
    <Row className="section_title">
      <Col xs={24} md={20} className="title">
        {icon && icon}
        <span className="title_text">{title}</span>
      </Col>
      <Col xs={24} md={4} className="elemen_right">
        {elmentRight && elmentRight}
      </Col>
    </Row>
  );
};

export default SectionTitle;
