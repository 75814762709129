import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import quotation from 'src/repositories/quotation';
import { API_KEY } from 'src/constants/apiKey';
import { productFormat } from 'src/utils/product';
import { STATUS_CODE } from 'src/constants/app';
import { t } from 'src/libs/i18n';
import _ from 'lodash';
import { getSizes, removeZeroAndDuplicateItem } from 'src/utils/helper';

const useManufacturingSize = (
  productCd,
  machiningMethod,
  setEstimateErrors,
  setStockError,
  isProductFinished,
) => {
  const productSplit = productFormat.splitProductId(productCd);

  const { data, ...rest } = useQuery<
    AxiosResponse<Response.ManufacturingSize>,
    AxiosError<any>
  >(
    [
      API_KEY.MANUFACTURING_SIZES,
      {
        hakudoShhnCd: productSplit?.hakudoShhnCd,
        shhnCd: productSplit?.shhnCd,
        kakoCd: machiningMethod,
        api1ShhnCd: productSplit?.api1ShhnCd,
        api2ShhnCd: productSplit?.api2ShhnCd,
      },
    ],
    quotation.getManufacturingSize,
    {
      retry: 0,
      cacheTime: 0,
      enabled: !!machiningMethod && !isProductFinished,
      onSuccess() {
        setEstimateErrors([]);
        setStockError('');
      },
      onError(err) {
        const error = _.get(err, 'response.data');
        if (STATUS_CODE.GET_SIZES_ERROR === error?.code) {
          window.scrollTo({ top: 200, behavior: 'smooth' });
          return setEstimateErrors(
            !isProductFinished ? [t('message.E000081')] : [],
          );
        }
      },
    },
  );

  const sizes = getSizes(
    removeZeroAndDuplicateItem(data?.data?.item?.sizes),
    true,
  );

  return {
    manufacturingSizesData: { ...data?.data?.item, sizes },
    ...rest,
  };
};

export default useManufacturingSize;
