import React, { ReactNode } from 'react';
import {
  Col,
  Form,
  FormItemProps,
  Row,
  Typography,
  Input,
  InputProps,
} from 'antd';
import { useController, UseControllerProps } from 'react-hook-form';
import { SwapRightOutlined } from '@ant-design/icons';
import { REGEX } from 'src/constants/app';

type Props<T> = {
  inputPropsFrom?: InputProps;
  inputPropsTo?: InputProps;
  controllerFrom: UseControllerProps<T>;
  controllerTo: UseControllerProps<T>;
  errorCol?: 12 | 24;
  prefix?: ReactNode;
  isOnlyInteger?: boolean;
} & Omit<FormItemProps, 'children'>;

function NumberOnlyRangePicker<T extends Record<string, any>>(props: Props<T>) {
  const {
    inputPropsFrom,
    inputPropsTo,
    controllerFrom,
    controllerTo,
    errorCol = 24,
    label,
    prefix,
    isOnlyInteger,
    ...rest
  } = props;
  const { fieldState: fieldStateFrom, field: fieldFrom } =
    useController<T>(controllerFrom);
  const { fieldState: fieldStateTo, field: fieldTo } =
    useController<T>(controllerTo);
  const { isTouched: isTouchedFrom, error: errorFrom } = fieldStateFrom;
  const { isTouched: isTouchedTo, error: errorTo } = fieldStateTo;

  const regex = isOnlyInteger
    ? REGEX.REGEX_ONLY_INTEGER_NUMBER
    : REGEX.REGEX_ONLY_NUMBER;

  const onChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: 'from' | 'to',
  ) => {
    const { value } = e.target;
    if ((!isNaN(Number(value)) && regex.test(value)) || value === '') {
      if (type === 'from') {
        fieldFrom.onChange(value);
      } else {
        fieldTo.onChange(value);
      }
    } else {
      if (type === 'from') {
        !fieldFrom.value && fieldFrom.onChange('');
      } else {
        !fieldTo.value && fieldTo.onChange('');
      }
    }
  };

  const onPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    if (!regex.test(e.clipboardData.getData('Text'))) {
      e.preventDefault();
    }
  };

  return (
    <Form.Item
      colon={false}
      label={label}
      labelAlign="left"
      labelCol={label ? { span: 24 } : undefined}
      wrapperCol={label ? { span: 24 } : undefined}
      validateStatus={
        (isTouchedFrom || isTouchedTo) && (errorFrom || errorTo) ? 'error' : ''
      }
      {...rest}
      className="input_range_picker"
    >
      <Row gutter={4}>
        <Col span={errorCol}>
          <Row gutter={8} justify="space-between">
            <Col span={11}>
              <Input
                {...fieldFrom}
                {...inputPropsFrom}
                onChange={(e) => onChangeInput(e, 'from')}
                onPaste={onPaste}
                style={{ width: '100%' }}
                prefix={prefix}
              />
            </Col>
            <Col span={2} style={{ margin: 'auto', textAlign: 'center' }}>
              <SwapRightOutlined />
            </Col>
            <Col span={11}>
              <Input
                {...fieldTo}
                {...inputPropsTo}
                onChange={(e) => onChangeInput(e, 'to')}
                onPaste={onPaste}
                style={{ width: '100%' }}
                prefix={prefix}
              />
            </Col>
          </Row>
        </Col>
        <Col span={errorCol}>
          {(errorFrom || errorTo) && (
            <Typography.Text
              className="ant-form-item-explain ant-form-item-explain-error"
              type="danger"
              ellipsis={{ tooltip: true }}
            >
              {errorFrom && errorFrom.message}
              {errorTo && errorTo.message}
            </Typography.Text>
          )}
        </Col>
      </Row>
    </Form.Item>
  );
}

export default NumberOnlyRangePicker;
