import React from 'react';

import { PATH } from 'src/pages/policy';
import loadable from 'src/utils/loadable';
import { IRouter } from 'src/interfaces/route';
import RestrictLayout from 'src/components/applications/RestrictLayout';
import { SCREENS } from 'src/constants/screens';

const PolicyRoute: IRouter[] = [
  {
    element: (
      <RestrictLayout requireNotAuth={false} title={'policy_page.title'} />
    ),
    children: [
      {
        path: PATH,
        element: loadable(() => import('src/pages/policy')),
        title: 'policy_page.title',
        screenId: SCREENS.R1013,
      },
    ],
  },
];

export default PolicyRoute;
