import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getProductMachining = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const getManufacturingSize = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const postEstimatePrice = (url: string, data: Payload.EstimatePrice) => {
  return axios({
    method: 'post',
    url,
    data,
  });
};

const getEstimateDetail = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const getQuotation = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const reactionHistory = (url: string) => {
  return axios({
    method: 'put',
    url,
  });
};

const deleteHistory = (url: string, data: Payload.DeleteHistory) => {
  return axios({
    method: 'delete',
    url,
    data,
  });
};

const exportPDF = (url: string, data: Payload.ExportPDF) => {
  return axios({
    responseType: 'blob',
    method: 'post',
    url,
    data,
  });
};

const getManufacturingSizeDomestic = (
  url: string,
  params: Payload.ManufacturingSizeDomestic,
) => {
  return axios({
    method: 'get',
    url,
    params,
  });
};

const getCurrentCurrencyUnit = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getCurrencyTransfers = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getManufacturingImport = (
  url: string,
  params: Payload.ManufacturingImport,
) => {
  return axios({
    method: 'get',
    url,
    params,
  });
};

const getNoukiDisplay = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const convertSize = (url: string, params: Payload.ConvertSize) => {
  return axios({
    method: 'get',
    url,
    params,
  });
};

const getTaxes = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const getProductDimension = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

const getProductList = (url: string, params: Payload.ProductList) => {
  return axios({
    method: 'get',
    url,
    params,
  });
};

const exportCSV = (url: string, data: Payload.ExportCSV) => {
  return axios({
    responseType: 'blob',
    method: 'post',
    url,
    data,
  });
};

const getOrderStatus = (args: QueryFunctionContext<any>) => {
  const [url] = args.queryKey;
  return axios({
    method: 'get',
    url,
  });
};

const getTolerance = (url: string, params: Payload.Tolerance) => {
  return axios({
    method: 'get',
    url,
    params,
  });
};

export default {
  getProductMachining,
  getManufacturingSize,
  postEstimatePrice,
  getEstimateDetail,
  getQuotation,
  reactionHistory,
  deleteHistory,
  exportPDF,
  getManufacturingSizeDomestic,
  getCurrentCurrencyUnit,
  getCurrencyTransfers,
  getManufacturingImport,
  getNoukiDisplay,
  convertSize,
  getTaxes,
  getProductDimension,
  getProductList,
  exportCSV,
  getOrderStatus,
  getTolerance,
};
