import React from 'react';
import { useTranslation } from 'react-i18next';
import { Breakpoint } from 'antd/lib/_util/responsiveObserve';
import { ColumnsType } from 'antd/lib/table';
import { LoadingOutlined } from '@ant-design/icons';
import { TableProps } from 'antd';
import useTableCommon from 'src/hooks/useTable';

import ItemInfo from '../Quotation/ItemInfo';
import useOrder from 'src/queries/useOrder';
import ItemCounter from './ItemCounter';
import OrderRow from './OrderRow';
import { BACK_ORDER_PRODUCT } from './useForm';
import { getProductName } from 'src/utils/helper';

const useTable = (control, webMitrNo: string[]) => {
  const { t } = useTranslation();
  const { getWebMitr } = useOrder(webMitrNo);

  const { ...rest } = useTableCommon({
    hasSelect: true,
    displayTotal: false,
    maxPageSize: 500,
  });

  const columns: ColumnsType<Response.WebMitrEntity> = [
    // row responsive mobile
    {
      responsive: ['xs'] as Breakpoint[],
      render: (record, data, index) => <ItemCounter index={index} />,
    },
    // rows responsive desktop
    {
      responsive: ['sm'] as Breakpoint[],
      width: '10%',
      render: (record, data, index) => <ItemCounter index={index} />,
    },
    {
      width: '61%',
      responsive: ['sm'] as Breakpoint[],
      render: (record) => (
        <ItemInfo
          type="unexpired"
          name={getProductName(record?.productName, false, record?.hakudoShhnm)}
          code={record.hakudoShhnCd}
          sub={record.zaitokKbn === BACK_ORDER_PRODUCT}
        />
      ),
    },
  ];

  const tableProps: TableProps<any> = {
    columns: columns,
    expandable: {
      showExpandColumn: false,
      expandedRowKeys: getWebMitr.data?.data?.item.items.map(
        (item) => item.seqno,
      ),
      expandedRowRender: (record, index) => (
        <OrderRow
          item={record}
          control={control}
          index={index}
          data={getWebMitr.data?.data?.item}
          taxType={getWebMitr.data?.data?.item?.kingakuHyoji}
        />
      ),
      defaultExpandAllRows: true,
    },
    dataSource: getWebMitr.data?.data?.item.items,
    loading: {
      spinning: getWebMitr.isLoading,
      indicator: <LoadingOutlined />,
    },
    rowKey: 'seqno',
    showHeader: false,
    size: 'middle',
    rowClassName: 'table-row',
    className: 'custom_table',
    locale: {
      emptyText: t('message.E000024'),
    },
    pagination: false,
  };

  return {
    tableProps,
    ...rest,
  };
};

export default useTable;
