import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import quotation from 'src/repositories/quotation';
import useQueryUrl from 'src/hooks/useQueryUrl';
import { API_KEY } from 'src/constants/apiKey';
import { productFormat } from 'src/utils/product';

const useProductMachining = (product) => {
  const params = useQueryUrl<SearchParams.MachiningMethodSearch>();

  const productSplit = productFormat.splitProductId(product);

  const { data, ...rest } = useQuery<
    AxiosResponse<Response.MachiningMethod>,
    AxiosError<any>
  >(
    [
      API_KEY.MACHINING_METHOD,
      {
        typeCd: params.hinshL3Cd,
        shapeCd: params.keijoL3Cd,
        hakudoShhnCd: productSplit?.hakudoShhnCd,
        shhnCd: productSplit?.shhnCd,
        api1ShhnCd: productSplit?.api1ShhnCd,
        api2ShhnCd: productSplit?.api2ShhnCd,
      },
    ],
    quotation.getProductMachining,
    {
      retry: 0,
      enabled: !!product,
      onError(err) {
        console.log(err.response);
      },
    },
  );
  const methodsData = data?.data?.item?.methods.map((item) => {
    return {
      label: item.name,
      value: item.code,
      description: item.description,
    };
  });
  return {
    machiningsData: { ...data?.data?.item, methods: methodsData },
    ...rest,
  };
};

export default useProductMachining;
