import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import guest from 'src/repositories/guest';

const useIndustryAndRegion = () => {
  const queryClient = useQueryClient();

  const apiOccupationPath = '/guest/occupation';
  const apiLocationPath = '/guest/location';
  const apiInfoPath = '/guest/info';
  const apiUpdatePath = '/guest/occupation-location';

  const queryInfo = useQuery<
    AxiosResponse<Response.GuestInfo>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiInfoPath],
    queryFn: guest.getInfo,
  });

  const queryIndustry = useQuery<
    AxiosResponse<Response.Industry>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiOccupationPath],
    queryFn: guest.getIndustry,
  });

  const queryRegion = useQuery<
    AxiosResponse<Response.Region>,
    AxiosError<Response.Error>
  >({
    queryKey: [apiLocationPath],
    queryFn: guest.getRegion,
  });

  const updateIndustryOrRegion = useMutation<
    AxiosResponse,
    AxiosError,
    Payload.UpdateIndustryOrRegion
  >({
    mutationKey: [apiUpdatePath],
    mutationFn: (args) => guest.updateIndustryOrRegion(apiUpdatePath, args),
    retry: 0,
    onSuccess: () => {
      queryClient.invalidateQueries([apiInfoPath]);
    },
    onError: (error) => {
      console.log(error.response);
    },
  });

  const dataIndustry = queryIndustry?.data?.data?.items.map((item) => {
    return {
      label: item.name,
      value: item.seqno,
    };
  });

  const dataRegion = queryRegion?.data?.data?.items.map((item) => {
    return {
      label: item.shozaichi,
      value: item.seqno,
    };
  });

  const dataInfo = queryInfo?.data?.data?.item;

  return { dataInfo, dataRegion, dataIndustry, updateIndustryOrRegion };
};

export default useIndustryAndRegion;
