import { ChamferCondition } from 'src/interfaces/condition';

// condition [show, active] chamfer select box
export const CHAMFER_CONDITION: ChamferCondition = [
  {
    shapeType: '01',
    condition: [
      {
        matchiningType: 'G6',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
        ],
      },
      {
        matchiningType: 'GK',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
        ],
      },
      {
        matchiningType: 'GP',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
        ],
      },
      {
        matchiningType: 'GU',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
        ],
      },
      {
        matchiningType: 'KK',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
        ],
      },
      {
        matchiningType: 'KP',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
        ],
      },
      {
        matchiningType: 'KU',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
        ],
      },
      {
        matchiningType: 'KV',
        condition: [
          { show: true, active: true },
          { show: true, active: true },
        ],
      },
      {
        matchiningType: 'Others',
        condition: [
          { show: true, active: false },
          { show: true, active: false },
        ],
      },
    ],
  },
  {
    shapeType: '02',
    condition: [
      {
        matchiningType: '',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'Others',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
    ],
  },
  {
    shapeType: '03',
    condition: [
      {
        matchiningType: '',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
      {
        matchiningType: 'Others',
        condition: [
          { show: false, active: false },
          { show: false, active: false },
        ],
      },
    ],
  },
];
