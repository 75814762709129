import { Col, Row, Table } from 'antd';
import React from 'react';
import { Breadcrumb } from 'src/components/custom';
import Search from './Search';
import useTable from './useTable';

const QuotationContainer: React.FC = () => {
  const { tableProps } = useTable();

  return (
    <div className="mb-30 mt-30 quotation">
      <Breadcrumb />
      <Search />
      <Row>
        <Col span={24}>
          <Table {...tableProps} className="quotation_table" />
        </Col>
      </Row>
    </div>
  );
};

export default QuotationContainer;
