import React from 'react';

import DemoContainer from 'src/containers/Demo';

const DemoPage: React.FC = () => {
  return <DemoContainer />;
};

export const PATH = '/demo' as const;
export default DemoPage;
