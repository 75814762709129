import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const LoadingPage: React.FC = () => {
  return (
    <div className="loading">
      <Spin
        spinning
        size={'large'}
        indicator={<LoadingOutlined style={{ fontSize: 50 }} />}
      />
    </div>
  );
};

export default LoadingPage;
