import axios from 'src/libs/axios';
import { QueryFunctionContext } from '@tanstack/react-query';

const getListNotification = (args: QueryFunctionContext<any>) => {
  const [url, params] = args.queryKey;

  return axios({
    method: 'get',
    url,
    params,
  });
};

export default { getListNotification };
